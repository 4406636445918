import * as React from 'react';
import Post from './Post'
import Comment from './Comment';
import {  useState, useEffect, useRef } from 'react';
import GetFunction from '../API/getFunction';
import PostFunction from '../API/postFunction';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Skeleton from '@mui/material/Skeleton';

import BackButton from '../Navigation/BackButton';

import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

// DECLARATIONS REACT ROUTER
import { useParams } from 'react-router-dom'

// DECLARATIONS STORE
import { useSelector, useDispatch } from 'react-redux'


export default function PostWithComments() {

  dayjs.locale('fr')

  const selectToken = state => state.token
  const token = useSelector(selectToken)
     
  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const dispatch = useDispatch()

  const [postState, setPostState] = useState({
    post: null,
    isFetching: true,
  })

  const [newCommentState, setNewCommentState] = useState({
    isFetching : false,
    newCommentText : ""})

  // On récupère le postId passé en paramètre du lien
  let {postId} = useParams()

  // pull-to-refresh : states pour conserver le point de départ + conserver la distance de pull
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Fonction pour forcer le refresh
  const RefreshPost = () => {
    // Récupération du nouveau post
    setIsRefreshing(true)
    GetFunction({fetchTarget : 'getPostWithComments', fetchArgument:postId, token:token})
    .then(response => {
      setStartPoint(0);
      setPullChange(0);
      setIsRefreshing(false)
      if (response.fetchStatus === 'Ok') {
        console.log('PostWithComments.js -> Fin chargement API getPostWithComments')
        console.log('PostWithComments.js -> Mise à jour du statut postState')
        setPostState(prevState => ({...prevState,post:response.data, isFetching:false}))
      } else {
        console.log('PostWithComments.js -> Impossible de charger le contenu de la discussion')
      }
    })
  };

  // 
  // GESTION DU PULL-TO-REFRESH
  //

  // Création des ref et des useEffect de mise à jour des ref quand les states changent
  // Parce que les listeners n'ont pas accès aux valeurs à jour des variables et des states (enclosure)
  const startPointRef = useRef();
  const pullChangeRef = useRef();
  const isRefreshingRef = useRef();

  useEffect(() => {
    startPointRef.current = startPoint
  }, [startPoint]);

  useEffect(() => {
    pullChangeRef.current = pullChange
  }, [pullChange]);

  useEffect(() => {
    isRefreshingRef.current = isRefreshing
  }, [isRefreshing]);

  // Création des 3 fonctions des listeners

  const pullStart = (e) => {
    // Pour empêcher de déclencher les listeners sur tous le séléments en même temps
    e.stopPropagation()
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
  };

  const pull = (e) => {
    e.stopPropagation()
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = (startPointRef.current < screenY ? screenY - startPointRef.current : 0);
    
    const scrollableList = scrollableListRef.current;
    const scrollTop = scrollableList.scrollTop;
    if (scrollTop === 0 && pullLength > 0) {
      setPullChange(pullLength);
    }
  };

  // Fonction pour gérer le endPull
  const endPull = (e) => {
    e.stopPropagation()
    if (pullChangeRef.current > 100) {
      // Plusieurs listeners peuvent être déclenchés en même temps : il faut lancer l'action une seule fois
      if (isRefreshingRef.current === false) {
        RefreshPost()
      }
    } else {
      setStartPoint(0);
      setPullChange(0);
    }
  };

  // Déclaration des listeners

  const scrollableListRef = useRef(null);

  useEffect(() => {
    if (scrollableListRef.current) {
      scrollableListRef.current.removeEventListener("touchstart", pullStart, {passive : true});
      scrollableListRef.current.removeEventListener("touchmove", pull, {passive : true});
      scrollableListRef.current.removeEventListener("touchend", endPull, {passive : true});

      scrollableListRef.current.addEventListener("touchstart", pullStart, {passive : true}); // L'écran est touché
      scrollableListRef.current.addEventListener("touchmove", pull, {passive : true});       // Mouvement du doigt
      scrollableListRef.current.addEventListener("touchend", endPull, {passive : true});     // L'écran est lâché
    }
  }, [scrollableListRef.current]);
  

  // Chargement du contenu du post complet y compris les commentaires, lancé dans ComponentDidMount
  function LoadPostContent() {
    console.log('PostWithComments.js -> Chargement API getPostWithComments')
    GetFunction({fetchTarget : 'getPostWithComments', fetchArgument:postId, token:token})
    .then(response => {
      if (response.fetchStatus === 'Ok') {
        console.log('PostWithComments.js -> Fin chargement API getPostWithComments')
        console.log('PostWithComments.js -> Mise à jour du statut postState')
        setPostState(prevState => ({...prevState, post:response.data, isFetching:false}))
      } else {
        console.log('PostWithComments.js -> Impossible de charger le contenu de la discussion')
        setPostState(prevState => ({...prevState, isFetching:false}))

        let errorMessage = "Impossible de charger le contenu de cette conversation, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function SendComment() {
    if (newCommentState.newCommentText !== '') {
      console.log('PostWithComments.js -> Lancement API sendComment')
      setNewCommentState(prevState => ({...prevState, isFetching : true}))
      let myDate = new Date()  //Utilisé pour que le champs soit présent et avec un bon format, mais cette date sera écrasée par la date système de l'API
      let comment = {
        "commentPost": postState.post[0].pk,
        "commentAuthor": 1, //Utilisé pour que le champs soit présent et avec un bon format, mais cette date sera écrasée par le user id dans l'API
        "commentText": newCommentState.newCommentText,
        "commentTimestamp": myDate
      }
      PostFunction({fetchTarget : 'sendComment', fetchArgument : comment, token : token})
      .then(response => {
        if (response.fetchStatus === 'Ok')  {
          LoadPostContent()

          // Lancement animation des points gagnés
          console.log('PostWithComments -> Déclenchement animation points gagnés comment')
          dispatch({ type : "TOGGLE_SCOREDPOINTS_ANIMATION", payload : 10})

          // On récupère le UserProfile pour mettre à jour les points et le statut
          GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
          .then((response) => {
            if (response.fetchStatus === 'Ok') {
              console.log('PostWithComments.js -> Chargement getUserProfile dans le state Redux')
              dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
            } else {
              console.log('PostWithComments.js -> Réception du profil à jour en échec')
            }
          })
        } else {
          console.log('PostWithComments.js -> Erreur envoi du commentaire')
          let errorMessage = "Erreur lors de l'envoi du commentaire, vérifiez votre connexion"
          dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          }
        setNewCommentState(prevState => ({...prevState, newCommentText : "", isFetching : false}))
      })
    }
  }

  function handleChange(event) {
    setNewCommentState({...newCommentState,[event.target.id]:event.target.value})
  };

  function DisplayCommentEntry() {
    return(
        <Box sx={{display : "flex", flexDirection : "row", alignItems: 'end', position : "fixed", bottom : 5, left : 5, right : 5}}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            id="newCommentText"
            label="Votre commentaire"
            onChange={(event) => handleChange(event)}
            value={newCommentState.newCommentText}
            color="primary"
            sx={{
            '& .MuiOutlinedInput-root' : {
              backgroundColor : "white",
              padding : 2,
            borderRadius : 10
            },
            '& .MuiInputLabel-shrink': {
              borderRadius : 5,
              padding : 0.3,
              paddingLeft : 1,
              paddingRight : 1,
              backgroundColor: 'white' // Fond blanc quand le label flotte
            }
            }}
          />
          <LoadingButton
            variant="contained"
            onClick={SendComment}
            disabled={!newCommentState.newCommentText}
            loading={newCommentState.isFetching}
            sx={{
              borderRadius : 20,
              padding : 2,
              marginLeft : 1
            }}
          >
            <SendIcon />
          </LoadingButton>
        </Box>
    )
  }

  useEffect(() => {
    console.log('Comments.js -> useEffect')
    LoadPostContent()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  )

  return(
    (!postState.isFetching ?
      (postState.post ?
        // Section avec la référence de la zone scrollable
        <Box sx={{display : "flex", position : "absolute", flexDirection : "column", backgroundColor : "#FCE2D4", top : 0, bottom : 0, left : 0, right : 0}} overflow = "auto" ref={scrollableListRef}> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place*/}
          <BackButton/>
          <Box sx={{display : "flex", flexDirection : "column", alignItems : "center", marginTop : {xs : 8, sm : 8, md : 9}, marginBottom : 9, width : "100%"}} overflow = "auto"> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place*/}
            {/* Définition d'un gradient de couleurs*/}
            <svg width={0} height={0}>
              <defs>
                <linearGradient id="my_gradient" x1="00%" y1="00%" x2="00%" y2="100%">
                <stop offset="0%" stopColor="red" />
                  <stop offset="5%" stopColor="orange" />
                  <stop offset="50%" stopColor="yellow" />
                  <stop offset="70%" stopColor="green" />
                  <stop offset="95%" stopColor="blue" />
                  <stop offset="100%" stopColor="purple" />
                </linearGradient>
              </defs>
            </svg>
            <CircularProgress 
              sx={{
                position : "absolute",
                top : 12 + pullChange/5,
                transition : "top 0.2s linear",
                // Le CircularProgress doit être à un zIndex jusyte en dessous de l'élément du haut, mais au dessus des élements stardard*/}
                zIndex : 1000,
                'svg circle': { stroke: 'url(#my_gradient)' },
              }}
            />
            <Box sx={{
              display : "flex",
              flexDirection : "column",
              width : "100%",
              }}
            >
              <Post
                postScreen="PostWithComments"
                post={postState.post[0]}
                refreshFunction={RefreshPost}
              />
            {(postState.post[0].comments ?
              postState.post[0].comments.map(item => {
                return(
                  <Comment
                    key = {item.pk}
                    comment = {item}
                    postpk = {postState.post[0].pk}
                    refreshFunction = {RefreshPost}
                  />
                )
              })
            :
              null
            )}
            </Box>
            </Box>
          {DisplayCommentEntry()}
        </Box>
      :
        null
      )
    :
      <Box sx={{display : "flex", flexDirection : "column"}} width="100%" height="100%"> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place*/}
        <Box sx={{display:"flex", flexDirection:"row", paddingRight : {xs : 0 , sm : 2, md : 5}, alignItems:"center"}}>
          <Box sx={{flex:1}}>
              <Skeleton variant="circular" width={70} height={70} marginleft={1} marginright={1} />
          </Box>
          <Box sx={{flex:12}}>
            <Skeleton variant="text" sx={{ fontSize: '6rem' }} />
          </Box>
          <BackButton/>
        </Box>
        <Box sx={{ display : "flex", flexDirection : "column"}}>
          <Skeleton variant="rounded" width={"90%"} height={100} sx={{padding : 3, margin : 3}}/>
          <Skeleton variant="rounded" width={"90%"} height={100} sx={{padding : 3, margin : 3}}/>
          <Skeleton variant="rounded" width={"90%"} height={100} sx={{padding : 3, margin : 3}}/>
        </Box>
      </Box>
    )
  )
}

