import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import LoadingButton from '@mui/lab/LoadingButton';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import {useState, useContext } from 'react'
import {useDispatch, useSelector } from 'react-redux'

import { useNavigate, useLocation } from 'react-router-dom'

// Pour utiliser le contexte
import { ImageContext } from '../Context/ImageContext';

import Resizer from "react-image-file-resizer";

export default function RegisterUserDescription() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Pour utiliser le Context de l'application, pour stocker la photo (impossible à stocker dans le Store)
  const { setContextImage } = useContext(ImageContext);

  // Récupération du Context pour avoir les images existantes
  const { contextImage } = useContext(ImageContext);
  
  // Récupération du state transmis par Navigate, qui contient "back" si on vient de la page suivante
  const {state} = useLocation();
  let fromPrevious = false
  if (state) {
    if (state.fromPrevious) {
      fromPrevious = true
    }
  }
  
  const selectRegistrationData = state => state.registrationData
  const registrationData = useSelector(selectRegistrationData)

  // On initialise les données du formulaire avec les données déjà saisies stockées dans le store
  const [screenRegistrationData, setScreenRegistrationData] = useState(registrationData);
  
  // On crée un state spécifique pour les images, qu'on doit traiter à part
  const [screenRegistrationImage, setScreenRegistrationImage] = useState({
    newPicFile : null,
    newAvatarFile : null
  });

  const handlePreviousPage = () => {
    dispatch({ type : "UPDATE_REGISTRATION_DATA", payload:screenRegistrationData})

    // Pour l'image, on ne peut pas stocker dans le Store : on stocke dans le Context
    if (screenRegistrationImage.newPicFile) {
      setContextImage(screenRegistrationImage)
    }
    navigate("/RegisterUserName", {state : {fromPrevious : true}})
  };

  const handleNextPage = () => {
    dispatch({ type : "UPDATE_REGISTRATION_DATA", payload:screenRegistrationData})
    
    // Pour l'image, on ne peut pas stocker dans le Store : on stocke dans le Context
    if (screenRegistrationImage.newPicFile) {
      setContextImage(screenRegistrationImage)
    }

    navigate("/RegisterUserBoat")
  };

  const onPicChange = (event) => {
    if (event.target.files.length > 0) { // Pour éviter le cas où l'utilisateur clique sur "Annuler" dans la fenêtre
      setScreenRegistrationData({
        ...screenRegistrationData,
        isResizingPic : true,
        isResizingAvatar : true,
      })
      // Lancement de la compression de l'image de profil
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,    // Largeur max
          300,    // Hauteur max
          "JPEG",  // Format de sortie
          90,      // Qualité (0-100)
          0,       // Rotation
          (uri) => {        // Caalback function (lancée à la fin du traitelent)
            console.log('Register -> Image compressée')
            setScreenRegistrationData(prevState => ({
              ...prevState,
              isResizingPic : false,
            }))
            setScreenRegistrationImage(prevState => ({
              ...prevState,
              newPicFile : uri
            }))
          },
          "file",   // Type de sortie
          0,       // Largeur min
          0        // Hauteur min
        );
      } catch (err) {
        console.log('Register -> Echec compression image')
        let errorMessage = "Votre image n'a pas pu être traitée."
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }

      // Lancement de la compression de l'avatar
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          50,    // Largeur max
          50,    // Hauteur max
          "JPEG",  // Format de sortie
          70,      // Qualité (0-100)
          0,       // Rotation
          (uri) => {        // Caalback function (lancée à la fin du traitelent)
            console.log('Register -> Avatar compressé')
            setScreenRegistrationData(prevState => ({
              ...prevState,
              isResizingAvatar : false,
            }))
            setScreenRegistrationImage(prevState => ({
              ...prevState,
              newAvatarFile : uri
            }))
          },
          "file",   // Type de sortie
          30,       // Largeur min
          30        // Hauteur min
        );
      } catch (err) {
        console.log('Register -> Echec compression avatar')
        let errorMessage = "Votre avatar n'a pas pu être créé."
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    }
  };

  const onPicDelete = () => {
    setScreenRegistrationImage({
      newPicFile : null,
      newAvatarFile : null
    })
};

  const profilePicture = () => {
    if (screenRegistrationImage.newPicFile) {
      return (
        <Avatar
          src={URL.createObjectURL(screenRegistrationImage.newPicFile)}  
          sx={{ width: 150, height: 150 }}
        />
      )
    } else if (contextImage.newPicFile) {
        return (
          <Avatar
            src={URL.createObjectURL(contextImage.newPicFile)}  
            sx={{ width: 150, height: 150 }}
          />
        )
    } else {
      return (
        <AccountCircleIcon color="primary" aria-label="upload picture" sx={{ fontSize: 200 }} />
      )
    }
  };

  const PicAddButton = () => {
    if (((!screenRegistrationData.userPicurl)&&(!screenRegistrationImage.newPicFile))) {
      return (
        <LoadingButton color="primary" aria-label="add picture" component="label"
          type="submit"
          variant="contained"
          loading={(screenRegistrationData.isResizingPic)||(screenRegistrationData.isResizingAvatar)}
        >
          <CameraAltIcon />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicChangeButton = () => {
    if (((!screenRegistrationData.userPicurl)&&(screenRegistrationImage.newPicFile))) {
      return (
        <LoadingButton color="primary" aria-label="add picture" component="label"
          type="submit"
          variant="contained"
          loading={(screenRegistrationData.isResizingPic)||(screenRegistrationData.isResizingAvatar)}
        >
          <CameraAltIcon />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicDeleteButton = () => {
    if (((!screenRegistrationData.userPicurl)&&(screenRegistrationImage.newPicFile))) {
      return (
        <Button color="error" aria-label="remove picture" component="label"
          type="submit"
          variant="contained"
          endIcon={<CancelIcon />}
          onClick={onPicDelete}
        />
      );
    }
  }

  const handleChange = (event) => {
    setScreenRegistrationData({...screenRegistrationData,[event.target.id]:event.target.value})
  };

  function Button1() {
    return(
      <Button
        variant="contained"
        sx={{margin : 2}}
        startIcon={<SkipPreviousIcon />}
        onClick={handlePreviousPage}
        color={"primary"}
      >
        Retour
      </Button>
    )
  }

  function Button2() {
    return(
      <Button
        variant="contained"
        sx={{margin : 2}}
        endIcon={<SkipNextIcon />}
        onClick={handleNextPage}
        color={"primary"}
        >
        Suite
      </Button>
    )
  }

  function ButtonBox() {
    return(
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft:2,
          marginTop : 2
        }}
      >
        {Button1()}
        {Button2()}
      </Box>
    )
  }

  return (
    <Slide in={true} direction={fromPrevious ? "right" : "left"}>
      <Box
        sx={{
          position : "fixed",
          top : 0,
          paddingTop : {xs : 9, sm : 9, md : 10},
          bottom : 0,
          left : 0, 
          right : 0,
          paddingLeft : 2,
          paddingRight : 2,
          backgroundColor : "white",
          overflow : "auto",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" fontWeight = "bold" color="primary">
          Ta description
        </Typography>
        <Typography variant={"body1"}>
          Mets quelques mots, et même une photo si tu veux, pour ceux qui consulteront ta fiche !
        </Typography>
      {profilePicture()}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft:2,
        }}
      >
        {PicAddButton()}
        {PicChangeButton()}
        {PicDeleteButton()}
      </Box>
        <TextField
          fullWidth
          id="userDescription"
          label={"Présentez-vous aux autres utilisateurs"}
          onChange={(event) => handleChange(event)}
          value={screenRegistrationData.userDescription}
          sx={{marginTop : 2, marginBottom : 1}}
          multiline
          rows={4}
        />
      {ButtonBox()}
    </Box>
  </Slide>
);
}