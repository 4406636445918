import { Fragment, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';
import { useDispatch  } from 'react-redux'
import { useTheme } from "@emotion/react";
import { keyframes } from '@emotion/react'

import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import NearMeIcon from '@mui/icons-material/NearMe';
import Zoom from '@mui/material/Zoom';


export default function Tutorial8() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  
  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('NotificationList.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "DISPLAY_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('NotificationList.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])

  //
  // Animations standard
  //

  let tutorialButtonEffect = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }`

  function PreviousNextButtons() {
    return (
      <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", position : "fixed", bottom : 0, right : 1, left : 0, backgroundColor : "white", padding : 1, zIndex : theme.zIndex.modal-1}}>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/Tutorial7", {replace : true})
          }}
          color="primary"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <SkipPreviousIcon/>
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/MapPage", {replace : true})
          }}
          color="error"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <HighlightOffIcon />
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/Tutorial9", {replace : true})
          }}
          color="primary"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <SkipNextIcon/>
        </Fab>
      </Box>
    );
  }

  return(
    <Fragment>
      {/* COUCHE OPAQUE D'INTERCEPTION DES CLICS */}
      <Box sx={{
        display : "flex",
        position : "fixed",
        top : 0,
        bottom : 0,
        right : 0,
        left : 0,
        backgroundColor : "black",
        opacity : 0.4,
        zIndex : theme.zIndex.modal-1, // On se met juste au dessous du niveau des modal (Alert etc...)
      }}/>

      {PreviousNextButtons()}

      {/* BULLES DE TEXTE DU TUTO - FLECHE A GAUCHE*/}
      <Zoom in={true}>

      <Box 
        sx={{
          position: 'fixed',
          top : "20%",
          right : "10%",
          left : "30%",
          display: 'inline-block',
          padding: '16px',
          backgroundColor: 'white',
          borderRadius: '15px',
          boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.7)',
          zIndex : theme.zIndex.modal,
          '&::after': {
            content: '""',
            position: 'absolute',
            top: '10%', // Ajuste la hauteur de la pointe
            left: '-10px', // Place le triangle sur le côté gauche
            width: '0',
            height: '0',
            borderTop: '10px solid transparent',
            borderBottom: '10px solid transparent',
            borderRight: '10px solid white  ', // Pointe du triangle vers la bulle
          },
        }}
      >
        <Typography variant = "h6" color={"primary"} paragraph={true}>
          Quand ce bouton est vert, tu partages ta position avec les autres utilisateurs, et tu peux voir leur position sur la carte.{"\n"}
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Pas de panique, on ne va pas te repérer dans ton canapé : la localisation s'arrête automatiquement si tu n'est plus dans les zones de navigation de NauticTalk.{"\n"}
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Attention, si tu ne partages pas ta localisation, tu ne pourras plus voir la position des autres utilisateurs sur la carte.
        </Typography>
        </Box>
      </Zoom>


      {/* TOP LEFT TUTORIAL FABS */}
      <Box sx={{
        position : "fixed",
        display : "flex",
        flexDirection : "column",
        top : 80,
        left : 10,
        zIndex : theme.zIndex.modal
      }}>

        {/* ZoomPositionFAB */}
        <Fab color="primary"
          sx={{
              marginTop : 0.5, marginBottom : 0.5,
              zIndex : theme.zIndex.modal
            }}
          >
          <GpsFixedIcon sx={{color : "white"}}/>
        </Fab>
        {/* SharePositionFAB */}
        <Box sx={{display : "flex", flexDirection : "column"}}>
          <Fab color="success" 
            onClick={() => navigate ("/Tutorial9", {replace : true})}
            sx={{
              position : "absolute",
              color: 'white', // Couleur du texte et de l'icône
              backgroundColor: "success.main",
              marginTop : 0.5,
              marginBottom : 0.5,
              animation: `${tutorialButtonEffect} 0.5s ease infinite`,
              zIndex : theme.zIndex.modal
            }}
          >
            <NearMeIcon sx={{color : "white"}}/>
          </Fab>
        </Box>
      </Box>
    </Fragment>
 )
}