export default function PostFunction(props) {

  let urlBase = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:8000'
  : 'https://api.nautictalk.com'

  let headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Token ' + props.token,
  }

  let url = ""

  let fetchOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(props.fetchArgument)
  }

  console.log('postFunction '+ props.fetchTarget +' -> Lancement Fetch')
  switch (props.fetchTarget) {
    case 'sendNewSpot':
      url = urlBase + '/newspot/'
      break;
    case 'postSubscription':
      url = urlBase + '/subscription/'
      break;
    case 'sendPostForm':
      url = urlBase + '/post/'
      fetchOptions = {
        method: 'POST',
        headers: { // On supprime le header 'content-type', incompatible avec un fetch sur un FormData 
          'Authorization': 'Token ' + props.token
        },
        body: props.fetchArgument // Pour envoyer un FormData, on ne passe pas par le format JSON
      }
      break;
    case 'sendReportMark':
      url = urlBase + '/reportmark/'
      fetchOptions = {
        method: 'POST',
        headers: { // On supprime le header 'content-type', incompatible avec un fetch sur un FormData 
          'Authorization': 'Token ' + props.token
        },
        body: props.fetchArgument // Pour envoyer un FormData, on ne passe pas par le format JSON
      }
      break;
    case 'sendComment':
      url = urlBase + '/comment/'
      break;
    case 'toggleLike':
      url = urlBase + '/togglelike/'
      break;
    case 'toggleCommentLike':
      url = urlBase + '/togglecommentlike/'
      break;
    case 'sendReport':
      url = urlBase + '/report/'
      break;
    case 'sendChatMessage':
      url = urlBase + '/message/'
      fetchOptions = {
        method: 'POST',
        headers: { // On supprime le header 'content-type', incompatible avec un fetch sur un FormData 
          'Authorization': 'Token ' + props.token
        },
        body: props.fetchArgument // Pour envoyer un FormData, on ne passe pas par le format JSON
      }
      break;
    case 'createChatHeader':
      url = urlBase + '/chatroomsubscription/'
      break;
    case 'resetPassword':
      url = urlBase + '/resetpassword/'
      break;
    case 'userPosition':
      url = urlBase + '/userposition/'
      break;
  
    default:
      console.log('postFunction '+props.fetchTarget+' -> fetchTarget inconnu')
  }

  return fetch(url,fetchOptions)
  .then((response) => {
    if (!response.ok) {
      throw new Error('Response pas Ok')
    } else {
      return response.json()
    }
  })
  .then((response) => {
    console.log('postFunction '+props.fetchTarget+' -> Fetch terminé OK')
    return({fetchStatus:'Ok', data:response})
  })
  .catch((error) => {
    if (error === 'Response pas Ok') {
      console.log('postFunction '+props.fetchTarget+'-> Réponse non attendue du fetch')
    } else {
      console.log('postFunction '+props.fetchTarget+'-> Erreur pendant le Fetch')
    }
    return({fetchStatus:'Error'})
  })
}