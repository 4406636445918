import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import Grow from '@mui/material/Grow';
import { Paper, Typography } from '@mui/material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';

import Resizer from "react-image-file-resizer";

import {useState, useEffect} from 'react'

import CameraAltIcon from '@mui/icons-material/CameraAlt';
  
import { useNavigate, useLocation } from 'react-router-dom'
import PostFunction from '../API/postFunction';
import GetFunction from '../API/getFunction';

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'
       
export default function MapNewPost() {

  // Compression des images
  const MaxWidth = 1500    // Largeur max
  const MaxHeight = 1500    // Hauteur max
  const Quality = 90      // Qualité (0-100)
  const MinWidth = 1500    // Largeur min
  const MinHeight = 1500    // Hauteur min

  const selectProfile = state => state.myProfile
  const profil = useSelector(selectProfile)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('NotificationList.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "HIDE_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('NotificationList.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])
 

  // On récupère la liste de spots passée en state du lien
  const {state} = useLocation();
  const { spots } = state

  const [newPostState, setNewPostState] = useState({
    text : "",
    newPicFile : null,
    isFetching : false,
    isResizingPic : false,
    alertObject : null,
  })

  useEffect(() => {
    console.log('MapNewPost.js -> useEffect : chargemment dans le state de la liste des spots proches')
    if (spots.length >0) {
      setNewPostState(prevState => ({
        ...prevState,
        spotsList : spots
      }))
    }
   },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  const onPicChange = (event) => {
    if (event.target.files.length > 0) { // Pour éviter le cas où l'utilisateur clique sur "Annuler" dans la fenêtre
      setNewPostState(prevState => ({
        ...prevState,
        isResizingPic : true
      }))
      // Lancement de la compression de l'image de profil
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          MaxWidth,    // Largeur max
          MaxHeight,    // Hauteur max
          "JPEG",  // Format de sortie
          Quality,      // Qualité (0-100)
          0,       // Rotation
          (uri) => {        // Caalback function (lancée à la fin du traitelent)
            console.log('MapNewPost -> Image compressée')
            setNewPostState(prevState => ({
              ...prevState,
              isResizingPic : false,
              newPicFile : uri
            }))
          },
          "file",   // Type de sortie
          MinWidth,       // Largeur min
          MinHeight        // Hauteur min
        );
      } catch (err) {
        console.log('MapNewPost -> Echec compression image')
        let errorMessage = "Votre image n'a pas pu être traitée."
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    }
  };

  const onPicDelete = () => {
    setNewPostState(prevState => ({
      ...prevState,
      newPicFile:null
    }))
  };

  const PostPicture = () => {
    if (newPostState.newPicFile) {
      return (
        <Box sx={{display : "flex", flexDirection : "column", position : "relative", marginTop : 2}}>
          <Box sx = {{display : "flex", justifyContent : "flex-end", top : -25, right : 5, position : "absolute", zIndex : 1}}>
            {PicChangeButton()}
            {PicDeleteButton()}
          </Box>
          <Box
            component="img"
            sx={{maxHeight : "80vh",width:"100%"}}
            src={URL.createObjectURL(newPostState.newPicFile)}
          />
        </Box>
      )
    } else return null
  };

  const PicAddButton = () => {
    if (!newPostState.newPicFile) {
      return (
        <LoadingButton color="primary" aria-label="add picture" component="label"
            type="submit"
            loading={newPostState.isResizingPic}
            >
          <CameraAltIcon />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicChangeButton = () => {
    if (newPostState.newPicFile) {
      return (
        <LoadingButton color="primary" aria-label="change picture" component="label"
          type="submit"
          variant="contained"
          loading={newPostState.isResizingPic}
          sx={{margin : 1}}
        >
          <ChangeCircleIcon  />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );  
    } else return null
  }

  const PicDeleteButton = () => {
    if (newPostState.newPicFile) {
      return (
        <Button color="error" aria-label="remove picture" component="label"
          type="submit"
          variant="contained"
          onClick={onPicDelete}
          sx={{margin : 1}}
        >
          <CancelIcon />
        </Button>
      );
    }
  }
    
  function handleSubmitPost() {
    SendNewPost()
  };

  function handleSubmitCancel() {
    navigate(-1)
};

  function SendButton() {
    return (
      <LoadingButton
        color="success"
        onClick={handleSubmitPost}
        disabled={((!newPostState.text)&&(!newPostState.newPicFile))}
        loading={newPostState.isFetching}
      >
        <SendIcon />
      </LoadingButton>
    )
  }

  function SendNewPost() {
    console.log('MapNewPost.js -> Lancement API sendPost')
    setNewPostState(prevState => ({...prevState, isFetching:true}))
    
    const newPostFormData = new FormData();
    if (newPostState.newPicFile) {
    newPostFormData.append("postPicurl", newPostState.newPicFile,newPostState.newPicFile.name)
    } else {
      newPostFormData.append("postPicurl",new File([], ''))
    }
    newPostFormData.append("postAuthor", profil.pk)
    newPostFormData.append("postText", newPostState.text)

    newPostFormData.append("postSpot", 1)
    newPostFormData.append("postType", "Post")

    // Une request d'un serializer ne reussit pas à lire un array.
    // On va donc plutôt lui envoyer une serie de champs avec des keys "postSpotX"
    newPostState.spotsList.forEach((spot,index) => {
      newPostFormData.append("postSpots" + index, spot.pk)
    })

    PostFunction({fetchTarget : 'sendPostForm', fetchArgument : newPostFormData, token : token})
    .then(response => {
      if (response.fetchStatus === 'Ok')  {
        console.log('MapNewPost.js -> Envoi OK')
        setNewPostState(prevState => ({...prevState, isFetching:false}))

        // Lancement animation des points gagnés
        console.log('MapNewPost -> Déclenchement animation points gagnés newPost')
        dispatch({ type : "TOGGLE_SCOREDPOINTS_ANIMATION", payload : 50})

        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapNewPost.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('MapNewPost.js -> Réception du profil à jour en échec')
          }
        })
        navigate("/MapPage", {replace : true})
      } else {
        console.log('Feed.js -> Envoi en échec')
        setNewPostState(prevState => ({...prevState,
          isFetching:false,
          alertObject:<Alert severity="error">Erreur lors de l'envoi de la publication</Alert>
        }))
        let errorMessage = "Erreur lors de l'envoi de la publication, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function handleChange(event) {
    setNewPostState(prevState => ({...prevState,[event.target.id]:event.target.value}))
  };

  const authorGlobalBadge = () => {
    if (profil.userGlobalBadge) {
    return (
      <Avatar
      src={profil.userGlobalBadge}  
      sx={{ width: 20, height: 20, marginRight : 1 }}
    />
      )
    } else {
    return null;
    }
  };

  return (
    <Grow in={true} timeout={200}>
      <Box sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0}}>
        {/* Fond grisé qui renvoie vers la page de la carte*/}
        <Box
          onClick = {() => {navigate("/MapPage")}} 
          sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, backgroundColor : "grey", opacity : 0.5}}
        />
        <Paper sx={{position : "absolute", top : {xs :80, md : 90}, bottom : 20, left : 20, right : 20, backgroundColor : "white", borderRadius : 1}} elevation={24}>
          {/* Croix pour fermer la fenêtre */}
          <Box sx={{position : "absolute", top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {handleSubmitCancel}>
            <IconButton color="secondary">
              <HighlightOffIcon fontSize = "large"/>
            </IconButton>
          </Box>
          <Box sx={{
            display : "flex",
            flexDirection : "column",
            height : "100%",
            width : "100%"
            }}
          >
            <Box sx={{display : "flex", width : "100%", margin : 2}}>
              <Typography variant="h5" fontWeight = "bold" color="primary">
                Créer une publication
              </Typography>
            </Box>
            <Box sx={{display : "flex", flexDirection : "column", width : "100%"}} overflow = "auto">
              <Box sx={{display:"flex", flexDirection:"row", alignItems : "center", margin : 1}}>
                <Avatar src={profil.userAvatarurl} sx={{ width: 30, height: 30, marginRight : 1 }} />
                <Typography variant="body1" color="primary" sx={{ marginRight : 1 }}>
                  {profil.userNickname}
                </Typography>
                {authorGlobalBadge()}
              </Box>

              <Box sx={{display : "flex", width : "100%", flexDirection : "row", alignItems: 'end'}}>
                <TextField
                  multiline
                  label="Que voulez-vous dire ?"
                  id="text"
                  onChange={(event) => handleChange(event)}
                  value={newPostState.text}
                  sx={{display : "flex", flexGrow : 1, margin : 1}}
                  InputProps={{
                    endAdornment : (SendButton()),
                    startAdornment : (PicAddButton())
                  }}
                />
              </Box>
              {PostPicture()}
          </Box>
          {newPostState.alertObject}
          </Box>
        </Paper>
      </Box>
    </Grow>
  );
}