import { useRouteError } from "react-router-dom";
import SailingIcon from '@mui/icons-material/Sailing';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography align="center" variant="h4" >
          Mettez à jour vos cartes...<p/>
          <SailingIcon color="primary" sx={{ fontSize: 250 }} />
          <p/>... vous cherchez une page qui n'existe pas !
        </Typography>
              </Box>
    </Container>
  );
}