import { useEffect } from "react";
import { useDispatch } from 'react-redux'

export default function MapPage() {

  const dispatch = useDispatch()

  // Hook qui affiche la carte à chaque mount
  useEffect(() => {
    console.log('MapPage.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
      // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
},[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('MapPage.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])

  return null
};
