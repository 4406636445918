import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import GetFunction from '../API/getFunction';

import {useState} from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

export default function RegisterPassword() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Récupération du state transmis par Navigate, qui contient "back" si on vient de la page suivante
  const {state} = useLocation();
  let fromPrevious = false
  if (state) {
    if (state.fromPrevious) {
      fromPrevious = true
    }
  }

  const selectRegistrationData = state => state.registrationData
  const registrationData = useSelector(selectRegistrationData)

  // On initialise les données du formulaire avec les données déjà saisies stockées dans le store
  const [screenRegistrationData, setScreenRegistrationData] = useState(registrationData);

  const handlePreviousPage = () => {
    dispatch({ type : "UPDATE_REGISTRATION_DATA", payload:screenRegistrationData})
    navigate("/Register", {state : {fromPrevious : true}})
  };

  const handleNextPage = () => {
    dispatch({ type : "UPDATE_REGISTRATION_DATA", payload:screenRegistrationData})
    navigate("/RegisterUserName")
  };

  function newPasswordError() {
    if (screenRegistrationData.newPassword1 !== screenRegistrationData.newPassword2) {
      return ({error:true,message:"Les deux mots de passe saisis ne sont pas identiques"})
    } else {
      return ({error:false,message:""})
    } 
  }

  const handleChange = (event) => {
    setScreenRegistrationData({...screenRegistrationData,[event.target.id]:event.target.value})
  };

  function Button1() {
    return(
      <Button
        variant="contained"
        sx={{margin : 2}}
        startIcon={<SkipPreviousIcon />}
        onClick={handlePreviousPage}
        color={"primary"}
      >
        Retour
      </Button>
    )
  }

  function Button2() {
    return(
      <Button
        variant="contained"
        sx={{margin : 2}}
        endIcon={<SkipNextIcon />}
        onClick={handleNextPage}
        color={"primary"}
        disabled={newPasswordError().error || !screenRegistrationData.newPassword1}
      >
        Suite
      </Button>
    )
  }

  function ButtonBox() {
    return(
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft:2,
          marginTop : 2
        }}
      >
        {Button1()}
        {Button2()}
      </Box>
    )
  }

  return (
    <Slide in={true} direction={fromPrevious ? "right" : "left"}>
      <Box
        sx={{
          position : "fixed",
          top : 0,
          paddingTop : {xs : 9, sm : 9, md : 10},
          bottom : 0,
          left : 0, 
          right : 0,
          paddingLeft : 2,
          paddingRight : 2,
          backgroundColor : "white",
          overflow : "auto",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" fontWeight = "bold" color="primary">
          Ton mot de passe
        </Typography>
        <Typography variant={"body1"}>
          Choisis le mot de passe que tu préfères. 
        </Typography>
        <TextField
          sx={{marginTop : 3}}
          required
          fullWidth
          id="newPassword1"
          label="Mot de passe"
          error={newPasswordError().error}
          helperText={newPasswordError().message}
          onChange={(event) => handleChange(event)}
          value={screenRegistrationData.newPassword1}
          type="password"
          disabled={screenRegistrationData.isRegistered}
        />
        <TextField
          sx={{marginTop : 3}}
          required
          fullWidth
          id="newPassword2"
          label="Confirmer le mot de passe"
          error={newPasswordError().error}
          helperText={newPasswordError().message}
          onChange={(event) => handleChange(event)}
          value={screenRegistrationData.newPassword2}
          type="password"
          disabled={screenRegistrationData.isRegistered}
        />
        {ButtonBox()}
      </Box>
    </Slide>
  );
}