import React, { createContext, useState } from 'react';

// Création du contexte
export const ImageContext = createContext();

// Création du provider
export const ImageProvider = ({ children }) => {
  const [contextImage, setContextImage] = useState({
    newPicFile : null,
    newAvatarFile : null
  });

  return (
    <ImageContext.Provider value={{ contextImage, setContextImage }}>
      {children}
    </ImageContext.Provider>
  );
};