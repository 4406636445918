import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import {useState, useContext } from 'react'
import {useDispatch, useSelector } from 'react-redux'

import { useNavigate, useLocation } from 'react-router-dom'

// Pour utiliser le contexte
import { ImageContext } from '../Context/ImageContext';

export default function RegisterEnd() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Pour utiliser le Context de l'application, pour stocker la photo (impossible à stocker dans le Store)
  const { setContextImage } = useContext(ImageContext);

  // Récupération du Context pour avoir les images
  const { contextImage } = useContext(ImageContext);

  // Récupération du state transmis par Navigate, qui contient "back" si on vient de la page suivante
  const {state} = useLocation();
  let fromPrevious = false
  if (state) {
    if (state.fromPrevious) {
      fromPrevious = true
    }
  }
  
  const selectRegistrationData = state => state.registrationData
  const registrationData = useSelector(selectRegistrationData)

  // On initialise les données du formulaire avec les données déjà saisies stockées dans le store
  const [screenRegistrationData, setScreenRegistrationData] = useState(registrationData);

  const [activeStep, setActiveStep] = useState(1);
  const [errorStep, setErrorStep] = useState(0);

  const handlePreviousPage = () => {
    dispatch({ type : "UPDATE_REGISTRATION_DATA", payload : screenRegistrationData})
    navigate("/RegisterLocation", {state : {fromPrevious : true}})
  };

  const handleSubmitCancel = () => {
    // On supprime les données saisies
    dispatch({ type : "RESET_REGISTRATION_DATA", payload : null})
    setContextImage({newPicFile : null, newAvatarFile : null})
    navigate("/")
  };

  const handleSubmitRegister = () => {
    if (activeStep !== 4) {
      Register()
    } else {
      Login()
    }
  };

  const handleChangeSwitch = (event) => {
    setScreenRegistrationData({...screenRegistrationData,[event.target.id]:event.target.checked})
  };

  function Button1() {
    return(
      <Button
        variant="contained"
        sx={{margin : 2}}
        startIcon={<SkipPreviousIcon />}
        onClick={handlePreviousPage}
        color={"primary"}
      >
        Retour
      </Button>
    )
  }

  function Button2() {
    return(
      <Button
        variant="contained"
        sx={{margin : 2}}
        endIcon={<CancelIcon />}
        onClick={handleSubmitCancel}
        color={"error"}
      >
        Annuler
      </Button>
    )
  }

  function Button3() {
    return(
      <LoadingButton
        variant="contained"
        sx={{margin : 2}}
        endIcon={<AccountCircleIcon />}
        onClick={handleSubmitRegister}
        loading={screenRegistrationData.isFetching}
        color={"success"}
      >
        S'inscrire
      </LoadingButton>
    )
  }

  function ButtonBox() {
    return(
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft:2,
          marginTop : 2
        }}
      >
        {Button1()}
        {Button2()}
        {Button3()}
      </Box>
    )
  }

  function MyStepper() {
    return (
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>
            Saisie des informations du profil
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            Lancement de l'inscription
          </StepLabel>
        </Step>
        <Step>
          <StepLabel error = {errorStep === 3}>
            {activeStep === 2 ? (errorStep === 3 ? "Echec de l'inscription" : "Inscription en cours...") : "Inscription réussie"}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            Lancement de la connexion
          </StepLabel>
        </Step>
        <Step>
          <StepLabel error = {errorStep === 5}>
            {activeStep === 4 ? (errorStep === 5 ? "Echec de la connexion" : "Connexion en cours...") : "Connexion réussie"}
          </StepLabel>
        </Step>
      </Stepper>
    );
  }

  function Register() {
    setActiveStep(2)
    setErrorStep(0)
    console.log('Register.js -> Chargement API Register')
    setScreenRegistrationData(prevState => ({...prevState, isFetching:true}))

    // On crée une FormData, donnée structurée indispensable pour envoyer un fichier au back-end
    const newProfileFormData = new FormData();
    newProfileFormData.append("username", screenRegistrationData.userEmail)
    newProfileFormData.append("email", screenRegistrationData.userEmail)
    newProfileFormData.append("password", screenRegistrationData.newPassword1)
    newProfileFormData.append("userNickname", screenRegistrationData.userNickname)

    // Dans les champs suivants, on teste si le champs est vide ou contient uniquement des espaces, car ça fait planter le serializer
    if (screenRegistrationData.userDescription && screenRegistrationData.userDescription.trim() !== "") { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userDescription", screenRegistrationData.userDescription)
    } else {
      newProfileFormData.append("userDescription", "")
    }

    if (screenRegistrationData.userBoat && screenRegistrationData.userBoat.trim() !== "") { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userBoat", screenRegistrationData.userBoat)
    } else {
      newProfileFormData.append("userBoat", "")
    }

    newProfileFormData.append("userDisplayBoatType", screenRegistrationData.userDisplayBoatType)
    if (screenRegistrationData.userBoatType) { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userBoatType", screenRegistrationData.userBoatType)
    } else {
      newProfileFormData.append("userBoatType", 0)
    }

    newProfileFormData.append("userDisplayBoatName", screenRegistrationData.userDisplayBoatName)
    if (screenRegistrationData.userBoatName && screenRegistrationData.userBoatName.trim() !== "") { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userBoatName", screenRegistrationData.userBoatName)
    } else {
      newProfileFormData.append("userBoatName", "")
    }

    newProfileFormData.append("userDisplayBoatModel", screenRegistrationData.userDisplayBoatModel)
    if (screenRegistrationData.userBoatModel && screenRegistrationData.userBoatModel.trim() !== "") { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userBoatModel", screenRegistrationData.userBoatModel)
    } else {
      newProfileFormData.append("userBoatModel", "")
    }

    newProfileFormData.append("userDisplayBoatHarbor", screenRegistrationData.userDisplayBoatHarbor)
    if (screenRegistrationData.userBoatHarbor && screenRegistrationData.userBoatHarbor.trim() !== "") { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userBoatHarbor", screenRegistrationData.userBoatHarbor)
    } else {
      newProfileFormData.append("userBoatHarbor", "")
    }

    if (contextImage.newPicFile) {
      newProfileFormData.append("userPicurl", contextImage.newPicFile,contextImage.newPicFile.name)
      newProfileFormData.append("userAvatarurl", contextImage.newAvatarFile,contextImage.newAvatarFile.name)
    } else {
      newProfileFormData.append("userPicurl",new File([], ''))
      newProfileFormData.append("userAvatarurl",new File([], ''))
    }
    newProfileFormData.append("userMailNotification", screenRegistrationData.userMailNotification)
    newProfileFormData.append("userPositionShare", screenRegistrationData.userPositionShare)
    
    // Syntaxe pour afficher le FormData
    // console.log(Array.from(newProfileFormData.entries()))

    let urlBase = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:8000'
    : 'https://api.nautictalk.com'
  
    let url = urlBase + '/register/'

    fetch(url,{
      method: 'POST',
      headers: { // On supprime le header 'content-type', incompatible avec un fetch sur un FormData 
      },
      body: newProfileFormData // Pour envoyer un FormData, on ne passe pas par le format JSON
    })
    .then((response) => {
      if (!response.ok) {
        console.log("Register.js -> Register a renvoyé un code d'erreur")
        setScreenRegistrationData(prevState => ({...prevState,
          isFetching : false
        }))
        setErrorStep(3)
        return response
      } else {
        console.log('Register.js -> Register réussi')
        setActiveStep(3)
        setErrorStep(0)
        // On supprime les données saisies
        dispatch({ type : "RESET_REGISTRATION_DATA", payload : null})
        setContextImage({newPicFile : null, newAvatarFile : null})
        Login()
        return 'OK'
      }
    })
    .catch((error) => {
      console.log('Register.js -> Erreur inconnue')
      console.log (error)
      setScreenRegistrationData(prevState => ({...prevState,
        isFetching : false
      }))
      setErrorStep(3)
    })
  }

  function Login() {
    setActiveStep(4)
    setErrorStep(0)
    console.log('Register.js -> Chargement API login')
    let loginObject = {
      username:screenRegistrationData.userEmail,
      password:screenRegistrationData.newPassword1
    }
    let urlBase = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:8000'
    : 'https://api.nautictalk.com'
  
    let url = urlBase + '/login/'

    let headers = {
      'Content-Type': 'application/json',
    }
      fetch(url,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify(loginObject)
    })
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      console.log('Register.js -> Response reçue')
      if (!response.token) {
        console.log("Register.js -> Login a renvoyé un code d'erreur")
        setScreenRegistrationData(prevState => ({...prevState,
          isFetching : false
        }))
        setErrorStep(5)
      } else {
        setActiveStep(5)
        console.log('Register.js -> Login réussi')
        let token = response.token
        dispatch({ type : "LOAD_TOKEN", payload:token})
        localStorage.setItem('NAUTICTALK_TOKEN', JSON.stringify(token))
        navigate('/TutorialStart')
      }
    })
    .catch((error) => {
      console.log('Register.js -> Erreur inconnue')
      setScreenRegistrationData(prevState => ({...prevState,
        isFetching : false
      }))
      setErrorStep(5)
    })
  }

  return (
    <Slide in={true} direction={fromPrevious ? "right" : "left"}>
      <Box
        sx={{
          position : "fixed",
          top : 0,
          paddingTop : {xs : 9, sm : 9, md : 10},
          bottom : 0,
          left : 0, 
          right : 0,
          paddingLeft : 2,
          paddingRight : 2,
          backgroundColor : "white",
          overflow : "auto",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" fontWeight = "bold" color="primary">
          Notifications par mail
        </Typography>
        <Typography variant={"body1"}>
          Nous vous préviendrons par mail chaque fois qu'un utilisateur vous enverra un message ou réagira à une de vos publications.
          Votre adresse mail ne sera JAMAIS utilisée dans un but commercial, et ne sra JAMAIS communiquée à qui que ce soit.
        </Typography>
        <FormControlLabel
          sx={{marginTop : 1}}
          control={<Switch checked={screenRegistrationData.userMailNotification} onChange={handleChangeSwitch} color="primary" id="userMailNotification"/>}
          label={screenRegistrationData.userMailNotification ?
            <Typography fontSize={"small"} color={"primary"} textAlign={'left'}>
              Vous recevrez un mail pour vous avertir d'une nouvelle notification
            </Typography>
          :
            <Typography fontSize={"small"} color={"error"} textAlign={'left'}>
              Vous ne recevrez pas de mail en cas de notification dans l'application
            </Typography>
          }
        />
        {ButtonBox()}
        {MyStepper()}
      </Box>
    </Slide>
  );
}