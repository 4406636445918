import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import Grow from '@mui/material/Grow';
import {Typography, InputAdornment } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Resizer from "react-image-file-resizer";

import {useState, useEffect, Fragment} from 'react'

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import { useNavigate, useLocation } from 'react-router-dom'
import PostFunction from '../API/postFunction';
import GetFunction from '../API/getFunction';

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'

       
export default function FeedNewPost() {

  // PARAMETRAGE
  // Distance maximale des spots proches suggérés
  const MaxDistance = 10

  // Compression des images
  const MaxWidth = 1500    // Largeur max
  const MaxHeight = 1500    // Hauteur max
  const Quality = 90      // Qualité (0-100)
  const MinWidth = 1500    // Largeur min
  const MinHeight = 1500    // Hauteur min

  const selectProfile = state => state.myProfile
  const profil = useSelector(selectProfile)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // On récupère le spot passés en state du lien
  const {state} = useLocation();
  const { spot } = state

  let markerImgZoom
  
  if (spot.spotType === "Port") {
    markerImgZoom = 15
  } else if (spot.spotType === "Mouillage") {
    markerImgZoom = 12
  }
  
  const [newPostState, setNewPostState] = useState({
    text : "",
    newPicFile : null,
    isFetching : false,
    isResizingPic : false,
    alertObject : null,
  })

  const [displaySpotList, setDisplaySpotList] = useState(false)
  const [publishNearbySpots, setPublishNearbySpots] = useState(true)

  let cloneMarkersArray = [...markersArray] // On clone pour ne plus avoir de lien avec le state et éviter les problème d'immutability
  let nearbyMarkers = cloneMarkersArray.filter(marker => {
    let start = {
      latitude : marker.spotLatitude,
      longitude : marker.spotLongitude
    }
    let end = {
      latitude : spot.spotLatitude,
      longitude : spot.spotLongitude
    }
    let distance = calculateDistance(start, end)       
    return((distance < MaxDistance)) 
  }).sort((a,b)=> {
    let start = {
      latitude : spot.spotLatitude,
      longitude : spot.spotLongitude
    }
    let enda = {
      latitude : a.spotLatitude,
      longitude : a.spotLongitude
    }
    let endb = {
      latitude : b.spotLatitude,
      longitude : b.spotLongitude
    }
    let distancea = calculateDistance(start, enda)       
    let distanceb = calculateDistance(start, endb)   
    if (distancea < distanceb) { return -1}    
    if (distancea > distanceb) { return 1}    
    return 0    
  })
  nearbyMarkers.forEach((item,index) => {
    let start = {
      latitude : spot.spotLatitude,
      longitude : spot.spotLongitude
    }
    let end = {
      latitude : item.spotLatitude,
      longitude : item.spotLongitude
    }
    nearbyMarkers[index] = {...item, distance:calculateDistance(start,end)}
  })


  // Identification des spots proches et mise dans le state

  function calculateDistance(start,end) {
    let lat1 = start.latitude
    let lon1 = start.longitude
    let lat2 = end.latitude
    let lon2 = end.longitude
    let R = 6371  // km
    let dLat = 2*Math.PI*R*(lat2 - lat1)/360 
    let dLon = 2 * Math.PI * R * (lon2 - lon1) / 360 * Math.cos((lat1+lat2)/2/360*2*Math.PI)
    let distance = Math.sqrt(Math.pow(dLat,2)+Math.pow(dLon,2))
    return distance 
  }
  
  useEffect(() => {
    console.log('FeedNewPost.js -> useEffect : chargemment dans le state de la liste des spots proches')
    if (nearbyMarkers.length >0) {
      setNewPostState(prevState => ({
        ...prevState,
        nearbyMarkers : nearbyMarkers,
        selectedNearbyMarkers : nearbyMarkers
      }))
    }
   },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  const handleSwitchChange = (event) => {
    if (event.target.checked) {
      let newSelectedMarker = newPostState.nearbyMarkers.find((marker) => (marker.pk.toString() === event.target.name.toString()))
      let newSelectedNearbyMarkers = newPostState.selectedNearbyMarkers
      newSelectedNearbyMarkers.push(newSelectedMarker)
      setNewPostState(prevState => ({
        ...prevState,
        selectedNearbyMarkers : newSelectedNearbyMarkers
      }))
    } else {
      let newSelectedNearbyMarkers = newPostState.selectedNearbyMarkers.filter(marker => marker.pk.toString() !== event.target.name.toString())
      setNewPostState(prevState => ({
        ...prevState,
        selectedNearbyMarkers : newSelectedNearbyMarkers
      }))
    }
  };

  function NearbySpotsChoice() {
    if (newPostState.nearbyMarkers && displaySpotList) {
      return (
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {newPostState.nearbyMarkers.map((marker) => 
              (marker.pk !== spot.pk?
                <FormControlLabel
                  key={marker.pk}
                  control={
                    <Switch 
                      checked={(newPostState.selectedNearbyMarkers.findIndex(spot => (spot.pk === marker.pk)) !== -1)}
                      onChange={handleSwitchChange}
                      name={marker.pk.toString()}
                      />
                  }
                  label={marker.spotName.concat(" (",Number.parseFloat(marker.distance).toFixed(1)," km)")}
                />
              :
              null
              )
            )}
          </FormGroup>
        </FormControl>
      );
    }
  }

  const onPicChange = (event) => {
    if (event.target.files.length > 0) { // Pour éviter le cas où l'utilisateur clique sur "Annuler" dans la fenêtre
      setNewPostState(prevState => ({
        ...prevState,
        isResizingPic : true
      }))
      // Lancement de la compression de l'image de profil
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          MaxWidth,    // Largeur max
          MaxHeight,    // Hauteur max
          "JPEG",  // Format de sortie
          Quality,      // Qualité (0-100)
          0,       // Rotation
          (uri) => {        // Caalback function (lancée à la fin du traitelent)
            console.log('FeedNewPost -> Image compressée')
            setNewPostState(prevState => ({
              ...prevState,
              isResizingPic : false,
              newPicFile : uri
            }))
          },
          "file",   // Type de sortie
          MinWidth,       // Largeur min
          MinHeight        // Hauteur min
        );
      } catch (err) {
        console.log('FeedNewPost -> Echec compression image')
        let errorMessage = "Votre image n'a pas pu être traitée."
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    }
  };

  const onPicDelete = () => {
    setNewPostState(prevState => ({
      ...prevState,
      newPicFile:null
    }))
  };

  const PostPicture = () => {
    if (newPostState.newPicFile) {
      return (
        <Box sx={{display : "flex", flexDirection : "column", position : "relative", marginTop : 2}}>
          <Box sx = {{display : "flex", justifyContent : "flex-end", top : -25, right : 5, position : "absolute", zIndex : 1}}>
            {PicChangeButton()}
            {PicDeleteButton()}
          </Box>
          <Box
            component="img"
            sx={{maxHeight : "80vh",width:"100%"}}
            src={URL.createObjectURL(newPostState.newPicFile)}
          />
        </Box>
      )
    } else return null
  };

  const PicAddButton = () => {
    if (!newPostState.newPicFile) {
      return (
        <LoadingButton color="primary" aria-label="add picture" component="label"
            type="submit"
            loading={newPostState.isResizingPic}
            >
          <CameraAltIcon />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicChangeButton = () => {
    if (newPostState.newPicFile) {
      return (
        <LoadingButton color="primary" aria-label="change picture" component="label"
          type="submit"
          variant="contained"
            loading={newPostState.isResizingPic
            }
            sx={{margin : 1}}
        >
          <ChangeCircleIcon/>
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicDeleteButton = () => {
    if (newPostState.newPicFile) {
      return (
        <Button color="error" aria-label="remove picture" component="label"
          type="submit"
          variant="contained"
          onClick={onPicDelete}
          sx={{margin : 1}}
        >
          <CancelIcon/>
        </Button>
      );
    }
  }
    
  function handleSubmitPost() {
    SendNewPost()
  };

  function handleSubmitCancel() {
    navigate(-1)
};

  function SendNewPost() {
    console.log('FeedNewPost.js -> Lancement API sendPost')
    setNewPostState(prevState => ({...prevState, isFetching:true}))
    
    const newPostFormData = new FormData();
    if (newPostState.newPicFile) {
    newPostFormData.append("postPicurl", newPostState.newPicFile,newPostState.newPicFile.name)
    } else {
      newPostFormData.append("postPicurl",new File([], ''))
    }
    newPostFormData.append("postAuthor", profil.pk)
    newPostFormData.append("postText", newPostState.text)

    newPostFormData.append("postSpot", 1)
    newPostFormData.append("postType", "Post")

    // Une request d'un serializer ne reussit pas à lire un array.
    // On va donc plutôt lui envoyer une serie de champs avec des keys "postSpotX"
    newPostState.selectedNearbyMarkers.forEach((spot,index) => {
      newPostFormData.append("postSpots" + index, spot.pk)
    })
    PostFunction({fetchTarget : 'sendPostForm', fetchArgument : newPostFormData, token : token})
    .then(response => {
      if (response.fetchStatus === 'Ok')  {
        console.log('FeedNewPost.js -> Envoi OK')
        setNewPostState(prevState => ({...prevState, isFetching:false}))

        // Lancement animation des points gagnés
        console.log('FeedNewPost -> Déclenchement animation points gagnés post')
        dispatch({ type : "TOGGLE_SCOREDPOINTS_ANIMATION", payload : 50})

        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('FeedNewPost.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('FeedNewPost.js -> Réception du profil à jour en échec')
          }
        })
        navigate(-1)
      } else {
        console.log('Feed.js -> Envoi en échec')
        setNewPostState(prevState => ({...prevState,
          isFetching:false,
          alertObject:<Alert severity="error">Erreur lors de l'envoi de la publication</Alert>
        }))
        let errorMessage = "Erreur lors de l'envoi de la publication, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function handleChange(event) {
    setNewPostState(prevState => ({...prevState,[event.target.id]:event.target.value}))
  };

  function handlePublishNearbySpotsSwitchChange() {
    if (!publishNearbySpots) {
      setNewPostState(prevState => ({
        ...prevState,
        selectedNearbyMarkers : nearbyMarkers
      }))
      setPublishNearbySpots(true)
    } else {
      setNewPostState(prevState => ({
        ...prevState,
        selectedNearbyMarkers : nearbyMarkers.slice(0,1)
      }))
      setPublishNearbySpots(false)
    }
    setPublishNearbySpots(!publishNearbySpots)
  }

  const authorGlobalBadge = () => {
    if (profil.userGlobalBadge) {
    return (
      <Avatar
      src={profil.userGlobalBadge}  
      sx={{ width: 20, height: 20,marginRight : 1 }}
    />
      )
    } else {
    return null;
    }
  };

  function SendButton() {
    return (
      <LoadingButton
        color="success"
        onClick={handleSubmitPost}
        disabled={((!newPostState.text)&&(!newPostState.newPicFile))}
        loading={newPostState.isFetching}
      >
        <SendIcon sx={{marginLeft : -2, marginRight : -2}}/>
      </LoadingButton>
    )
  }

  function CancelButton() {
    return(
      <Button
        color="error"
        onClick={handleSubmitCancel}
      >
        <CancelIcon/>
      </Button>
    )
  }

  return (
    <Grow in={true}>
      <Box sx={{display : "flex", position : "relative", flexDirection : "column", backgroundColor : "white"}} width="100%"> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place*/}
        <Box sx={{height : 70}}/>
          <Box sx={{display : "flex", width : "100%", flexDirection : "row", borderBottom : 5, borderColor : "#FCE2D4"}}>
            {CancelButton()}
            <Typography variant = "h6" color={"primary"} >
              Créer une publication
            </Typography>
          </Box>
          <Box sx={{display : "flex", flexDirection : "column"}}>
            <Box sx={{display:"flex", flexDirection:"row", alignItems : "center", margin : 1}}>
              <Avatar src={profil.userAvatarurl} sx={{ width: 30, height: 30, marginRight : 1 }} />
              <Typography variant="body1" color="primary" sx={{ marginRight : 1 }}>
                {profil.userNickname}
              </Typography>
              {authorGlobalBadge()}
            </Box>
            <Box sx={{display : "flex", width : "100%", flexDirection : "row", alignItems: 'end'}}>
              <TextField
                multiline
                focused
                label="Que voulez-vous dire ?"
                id="text"
                onChange={(event) => handleChange(event)}
                value={newPostState.text}
                sx={{display : "flex", flexGrow : 1, margin : 1}}
                InputProps={{
                  endAdornment : (
                    <InputAdornment position="end" sx={{ alignItems : "end" }} // Aligne l'icône en bas
                    >
                      {SendButton()}
                    </InputAdornment>),
                  startAdornment : (PicAddButton())
                }}
              />
            </Box>
            {PostPicture()}
          </Box>
          {newPostState.alertObject}
          <Box sx={{display : "flex", flexDirection : "column", width : "100%", margin : 1}}>
            <Typography variant="body1" color="primary">
              {"Publier aussi dans les spots proches (10km) ?"}
            </Typography>
            {displaySpotList ?
              NearbySpotsChoice()
              :         
              <Fragment>
              <FormControlLabel
                control={
                  <Switch 
                    checked={publishNearbySpots}
                    disabled={displaySpotList}
                    onChange={handlePublishNearbySpotsSwitchChange}
                  />
                }
                label={(publishNearbySpots ? "Oui, publier aussi dans les spots proches (10km)" : "Non, publier dans ce spot uniquement")}
              />
              <Typography variant="body1" color="primary" sx={{ marginRight : 1}}
                display={(publishNearbySpots ? "flex" : "none")}
                onClick={() => {setDisplaySpotList(true)}}
              >
                Voir la liste
              </Typography>
              </Fragment>       
            }
            </Box>
          </Box>
    </Grow>
  );
}