export default function ReportTypesArray() {
    return(
        [
            {
            type : "Dauphin",
            label : "Dauphin",
            icon : "/static/Mark-dauphin.svg",
            hours : 24
            },
            {
            type : "Méduses",
            label : "Méduses",
            icon : "/static/Mark-méduse.svg",
            hours : 24
            },
            {
            type : "Plongeur",
            label : "Plongeur",
            icon : "/static/Mark-plongeur.svg",
            hours : 24
            },
            {
            type : "SOS",
            label : "SOS",
            icon : "/static/Mark-sos.svg",
            hours : 24
            },
            ]
    )
}
