import { useEffect, useState} from 'react';

// DECLARATIONS UI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import MapFixedPage from "../Map/MapFixedPage"

import { styled, lighten, darken } from '@mui/system';

// Keyframes pour les animations
import { keyframes } from '@emotion/react'

// DECLARATIONS REACT-ROUTER
import { Outlet} from 'react-router-dom';
import { useNavigate } from 'react-router-dom'

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'

export default function MyAppBar() {

  //
  // Animations standard
  //

  let buttonEffect = keyframes`
  0% {
    opacity : 0;
    transform: scale(0);
  }
  10% {
    opacity : 1;
    transform: scale(5);
  }
  30% {
    opacity : 1;
    transform: scale(1);
  }
  80% {
    opacity : 1;
    transform: scale(1);
  }
  100% {
    opacity : 0;
    transform: scale(10);
  }`


  // Récupération du token dans le store
  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const selectScoredPoints = state => state.scoredPoints
  const scoredPoints = useSelector(selectScoredPoints)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const markersArrayGrouped = markersArray.map((marker) => {return {type : marker.spotType, ...marker}})
  const markersArrayGroupedSorted = markersArrayGrouped.sort((a, b) => b.type.localeCompare(a.type))
  const [value, setValue] = useState(markersArrayGroupedSorted[0]);


  // Récupération des notifications et calcul des badges d'icônes
  const selectNotifications = state => state.notificationArray.filter(item => (item.notificationRead === false)).length
  const notificationCount = useSelector(selectNotifications)

  const selectNewmessagescount = state => state.chatHeadersArray.reduce((prevValue, currentValue) => prevValue + currentValue.chatroomsubscriptions[0].subscriptionUnreadMessages,0)
  const newMessagesCount = useSelector(selectNewmessagescount)

  // Déclenchement de l'animation des scoredPoints
  const [isAnimating, setIsAnimating] = useState(null)

  useEffect(() => {
    if (scoredPoints) {
      setIsAnimating(scoredPoints)
    }
  }, [scoredPoints])

  const handleAnimationEnd = () => {
    setIsAnimating(null)
    dispatch({ type : "TOGGLE_SCOREDPOINTS_ANIMATION", payload : null})
  }
  
  // Gestion d'ouverture et fermeture des menus déroulants

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [searchFieldOpened, setSearchFieldOpened] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);

  };const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function SearchTextButton() {
    if (!searchFieldOpened) {
      return (
        <Button
          onClick={() => {setSearchFieldOpened(true)}}
          sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex' } }}
          endIcon={<SearchIcon/>}
        >
          Recherche
        </Button>
      )
    }
  }
    
  function SearchIconButton() {
    if (!searchFieldOpened) {
      return(
        <IconButton
          size="large"
          sx={{ color: 'white', display: { xs: 'flex', md: 'none' } }}
          onClick = {() => {setSearchFieldOpened(true)}}>
            <SearchIcon/>
        </IconButton>
      )
    }
  }

  function CloseSearchIconButton() {
    if (searchFieldOpened) {
      return(
        <IconButton
          size="large"
          sx={{ color: 'white' }}
          onClick = {() => {setSearchFieldOpened(false)}}>
            <SearchOffIcon/>
        </IconButton>
      )
    }
  }
  
  function MapTextButton() {
    if (!searchFieldOpened) {
      return (
      <Button
          onClick = {() => {navigate('/MapPage')}}
          sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex'} }}
          endIcon={<LocationOnIcon  sx={{fontSize:"35px"}}/>}
        >
          Explorer
        </Button>
      )
    }
  }
    
  function MapIconButton() {
    if (!searchFieldOpened) {
      return (
        <IconButton
          onClick = {() => {navigate('/MapPage')}}
          sx={{ color: 'white', display: { xs: 'flex', md: 'none'} }}
          size="large"
        >
          <LocationOnIcon sx={{fontSize:"35px"}}/>
        </IconButton>
      )
    }
  }

  function NewsFeedTextButton() {
    if (!searchFieldOpened) {
      return (
        <Button
          onClick={() => {navigate("/NewsFeed")}}
          sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex'}  }}
          endIcon={<FavoriteIcon/>}
        >
          Mes favoris
        </Button>
      )
    }
  }
    
  function NewsFeedIconButton() {
    if (!searchFieldOpened) {
      return (
        <IconButton
          onClick={() => {navigate("/NewsFeed")}}
          sx={{ color: 'white', display: { xs: 'flex', md: 'none'}  }}
          size="large"
        >
          <FavoriteIcon/>
        </IconButton>
      )
    }
  }


  function InboxTextButton() {
    if (!searchFieldOpened) {
      return (
        <Button
          key="InboxButton"
          onClick={() => {navigate("/ChatList")}}
          sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex'} }}
          endIcon={<Badge badgeContent={newMessagesCount} color="error"><MailIcon/></Badge>}
        >
          Inbox
        </Button>
      )
    }
  }

  function InboxIconButton() {
    if (!searchFieldOpened) {
      return (
          <IconButton
            size="large"
            sx={{ color: 'white', display: { xs: 'flex', md: 'none'} }}
            onClick = {() => {navigate('/ChatList')}}
          >
            <Badge badgeContent={newMessagesCount} color="error">
              <MailIcon />
            </Badge>
          </IconButton>
      )
    }
  }

  function NotificationTextButton() {
    if (!searchFieldOpened) {
      return (
          <Button
            onClick={() => {navigate("/NotificationList")}}
            sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex'} }}
            endIcon={<Badge badgeContent={notificationCount} color="error"><NotificationsIcon/></Badge>}
          >
            Notifications
          </Button>
      )
    }
  }

  function NotificationIconButton() {
    if (!searchFieldOpened) {
      return (
          <IconButton
            size="large"
            sx={{ color: 'white', display: { xs: 'flex', md: 'none'} }}
            onClick = {() => {navigate('/NotificationList')}}
          >
            <Badge badgeContent={notificationCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
      )
    }
  }

    // Association d'une navigation aux items du menu déroulant de droite

  function ContactMenuItem() {
    return (
      <MenuItem key="Contact" onClick={() => {setAnchorElNav(null);navigate("/Contact")}}>
        <Typography textAlign="center">Nous contacter</Typography>
      </MenuItem>
    )
  }

  function AboutUsMenuItem() {
    return (
      <MenuItem key="AboutUs" onClick={() => {setAnchorElNav(null);navigate("/AboutUs")}}>
        <Typography textAlign="center">Qui sommes nous ?</Typography>
      </MenuItem>
    )
  }

  function TutorialMenuItem() {
    return (
      <MenuItem key="Tutorial" onClick={() => {setAnchorElNav(null);navigate("/TutorialStart")}}>
        <Typography textAlign="center">Tutorial</Typography>
      </MenuItem>
    )
  }

    // Association d'une navigation aux items du menu déroulant de gauche
  
  function LoginMenuItem() {
    if (token) {
      return null
    } else {
      return (
        <MenuItem key="login" onClick={Login}>
          <Typography textAlign="center">Se connecter</Typography>
        </MenuItem>
      )
    }
  }

  function LogoutMenuItem() {
    if (token) {
      return (
      <MenuItem key="logout" onClick={Logout}>
        <Typography textAlign="center">Se déconnecter</Typography>
      </MenuItem>
      )
    } else {
      return null
    }
  }
  
  function ProfileMenuItem() {
    if (token) {
      return (
        <MenuItem key="profile" onClick={Profile}>
          <Typography textAlign="center">Mon profil</Typography>
        </MenuItem>
      )
    } else {
      return null
    }
  }

  function AccountMenuItem() {
    if (token) {
      return (
        <MenuItem key="account" onClick={Account}>
          <Typography textAlign="center">Mon compte</Typography>
        </MenuItem>
      )
    } else {
      return null
    }
  }

  // Bulle animée d'affichage des scores ajoutés ou supprimés
  // Couleur et signe différents selon quee le score est positif ou négatif
  function PointsScoredAnimatedRound(score) {
    return(
      <Box
        sx={{
          position : 'absolute',
          top : 30,
          right : 10,
          width : 50,
          height : 50,
          opacity : 0,
          border : 5,
          borderColor : "white",
          backgroundColor : score ?
            score >0 ? "#277DA1" : "#f94144"
            :
            "red",
          display : isAnimating ? 'flex' : 'none',
          justifyContent : 'center',
          alignItems : 'center',
          borderRadius : 10,
          animation: isAnimating ?
            `${buttonEffect} 2s`
            :
            'none'
        }}
        onAnimationEnd = {handleAnimationEnd}
      >
        <Typography variant = "h6" color={"white"}>
          {score ?
            score > 0 ? "+" + score : score
            :
            ""
          }
        </Typography>
      </Box>
    )
  }

  function Login() {
    setAnchorElUser(null)
    navigate("/Login")
  }
  
  function Logout() {
    setAnchorElUser(null)
    localStorage.removeItem('NAUTICTALK_TOKEN');
    dispatch({ type : "RESET_TOKEN"})
  }

  function Profile() {
    setAnchorElUser(null)
    navigate("/Profile")
  }
  
  function Account() {
    setAnchorElUser(null)
    navigate("/Account")
  }
  
  function UserAvatarButton(){
    if (!searchFieldOpened) {
      if ((token) && (myProfile.userAvatarurl)) {
        return (
          <IconButton onClick={handleOpenUserMenu}>
            <Avatar
              src={myProfile.userAvatarurl}
              sx={{ width: 30, height: 30 }}
            />
          </IconButton>
        )
      } else {
        return (
          <IconButton onClick={handleOpenUserMenu}>
         <AccountCircleIcon aria-label="No picture" sx={{ fontSize: 30, color:"white"  }} />
          </IconButton>
        )
      }
    }
  } 
  
  function SearchField() {

    const GroupHeader = styled('div')(({ theme }) => ({
      position: 'sticky',
      top: '-8px',
      padding: '4px 10px',
      color: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.light, 0.85),
      fontWeight : 'bold'
    }))

    const GroupItems = styled('ul')({
      padding: 0,
    });
  
    function HandleSelection(event, newValue) {
      setValue(newValue);
      setSearchFieldOpened(false);
      navigate("/MapPage" , {state : {zoomToSearch : newValue}})
    }

    function HandleSearchOpened() {
      setSearchFieldOpened(true);
    }
    if (searchFieldOpened) {
      return(
        <Autocomplete
          value = {value}
          disableClearable
          onOpen={HandleSearchOpened}
          onChange={HandleSelection}
          blurOnSelect={true}
          sx={{ 
            width: "100%",
            bgcolor:"white"
          }}
          options={markersArrayGroupedSorted}
          groupBy={(option) => option.type}
          getOptionLabel = {option => option.spotName}
          isOptionEqualToValue = {(option, value) => option.pk === value.pk}
          renderInput={(params) => <TextField {...params} color = "primary"/>}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />
      )
    }
  }

  return (
    <Box sx={{
      display : "flex",
      flexDirection:"column",
      position: 'fixed',
      top: 0, left: 0, right: 0, bottom:0
    }} >
      <AppBar position="absolute" sx={{borderBottomLeftRadius : 30, borderBottomRightRadius : 30,}} color="secondary">
        <Toolbar variant="dense">
          <Box>
            <IconButton
              size="large"
              sx={{ color: 'white' }}
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {TutorialMenuItem()}
              {ContactMenuItem()}
              {AboutUsMenuItem()}
            </Menu>
          </Box>
          <Typography fontWeight="bold" fontSize={20} color = "white" sx={{display : {xs : "none", sm : "flex"}}}>
            NauticTalk
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {SearchIconButton()}
          {SearchTextButton()}
          {SearchField()}
          {CloseSearchIconButton()}
          {NewsFeedTextButton()}
          {NewsFeedIconButton()}
          {MapTextButton()}
          {MapIconButton()}
          {InboxTextButton()}
          {InboxIconButton()}
          {NotificationTextButton()}
          {NotificationIconButton()}
          <Box sx={{ flexGrow: 0 }}>
            {UserAvatarButton()}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {LoginMenuItem()}
              {ProfileMenuItem()}
              {AccountMenuItem()}
              {LogoutMenuItem()}
            </Menu>
            {PointsScoredAnimatedRound(isAnimating)}
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        overflow = "auto"
        sx={{
          flex : 1,
          backgroundColor: "white",
        }}
      >
        <MapFixedPage/>
        <Outlet/>
      </Box>
    </Box>
  );
}
