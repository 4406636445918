import { Fragment, useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';
import { useDispatch  } from 'react-redux'
import { useTheme } from "@emotion/react";
import { keyframes } from '@emotion/react'

import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import Zoom from '@mui/material/Zoom';

import Avatar from '@mui/material/Avatar';

export default function Tutorial2() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()

    // Hook qui affiche la carte et masque les FABs à chaque mount
    useEffect(() => {
      console.log('NotificationList.js -> Action DISPLAY_MAP dans le store')
      dispatch({ type : "DISPLAY_MAP"})
      dispatch({type : "DISPLAY_FABS"})
      // La ligne suivante supprime un warning normal avec dispatch
      //eslint-disable-next-line
    },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
    );   
  
    // Hook qui masque la carte à chaque unmount
    useEffect(() => {
      return () => {
        console.log('NotificationList.js -> Action HIDE_MAP dans le store')
        dispatch({ type : "HIDE_MAP"})
        dispatch({type : "DISPLAY_FABS"})
      }
      // La ligne suivante supprime un warning normal avec dispatch
      //eslint-disable-next-line
    }, [])
   
  
  //
  // Animations standard
  //

  let bubbleEffect = keyframes`
  0% {
    opacity : 0;
    transform: scale(0);
  }
  50% {
    opacity : 1;
    transform: scale(2);
  }
  100% {
    opacity : 1;
    transform: scale(1);
  }`

  let animation = `${bubbleEffect} 0.5s ease-in-out forwards`

  const [animationState, setAnimationState] = useState({
    step : 1,
    animationBubble1 : animation,
    animationBubble2 : 'none',
    animationBubble3 : 'none',
  })

  function handleAnimationEnd() {
    if (animationState.step === 1) {
      setAnimationState(prevState => ({...prevState, step : 2, animationBubble2 : animation}))
    } else if (animationState.step === 2) {
      setAnimationState(prevState => ({...prevState, step : 3, animationBubble3 : animation}))
    } else if (animationState.step === 3) {
      setAnimationState(prevState => ({...prevState, step : 4}))
    }
  }

  function PreviousNextButtons() {
    return (
      <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", position : "fixed", bottom : 0, right : 1, left : 0, backgroundColor : "white", padding : 1, zIndex : theme.zIndex.modal-1}}>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/Tutorial1", {replace : true})
          }}
          color="primary"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <SkipPreviousIcon/>
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/MapPage", {replace : true})
          }}
          color="error"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <HighlightOffIcon />
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/Tutorial3", {replace : true})
          }}
          color="primary"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <SkipNextIcon/>
        </Fab>
      </Box>
    );
  }

  return (
    <Fragment>
      {/* COUCHE OPAQUE D'INTERCEPTION DES CLICS */}
      <Box sx={{
        display : "flex",
        position : "fixed",
        top : 0,
        bottom : 0,
        right : 0,
        left : 0,
        backgroundColor : "black",
        opacity : 0.4,
        zIndex : theme.zIndex.modal-1, // On se met juste au dessous du niveau des modal (Alert etc...)
      }}/>

      {PreviousNextButtons()}

      {/* BULLES DE TEXTE DU TUTO - FLECHE EN BAS*/}
      <Zoom in={true}>
        <Box 
          sx={{
            position: 'fixed',
            bottom : "20%",
            left : "10%",
            right : "10%",
            display: 'inline-block',
            padding: '16px',
            backgroundColor: 'white',
            borderRadius: '15px',
            boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.7)',
            zIndex : theme.zIndex.modal,
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-10px', // Placement du triangle en bas de la bulle
              left: '50%', // Ajuster pour le décaler par rapport à la bulle
              width: '0',
              height: '0',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '10px solid white',
            },
          }}
        >
          <Typography variant = "h6" color={"primary"} paragraph={true}>
          Envie de parler aux voisins du mouillage sans devoir plonger au milieu des méduses ?
          </Typography>
          <Box sx={{display : "flex", opacity : 0, flexDirection : "row", width : "100%", marginTop : 2, animation : animationState.animationBubble1}} justifyContent="flex-start" onAnimationEnd = {handleAnimationEnd}>
            <Box
              sx={{
                display : "flex",
                flexDirection : "row",
                alignItems : "center"
              }}
            >
              <Avatar src="/static/avatar/1.jpg"/>
              <Box
                sx={{
                  display : "flex",
                  flexGrow : 1,
                  flexDirection : "column",
                  marginLeft : 1,
                  overflow : "wrap"
                }}
              >
                <Typography
                  variant="body2"
                >
                  Qui est dans la calanque et peut me dire s'il y a de la houle ? Je jette l'ancre ce soir !
                </Typography>
                <Typography
                  variant="caption"
                  color="grey"
                >
                  Nicolas, il y a 3 mn
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{display : "flex", opacity : 0, flexDirection : "row", width : "100%", marginTop : 2, animation : animationState.animationBubble2}} justifyContent="flex-end" onAnimationEnd = {handleAnimationEnd}>
            <Box
              sx={{
                display : "flex",
                flexDirection : "row",
                alignItems : "center"
              }}
            >
              <Avatar src="/static/avatar/2.jpg"/>
              <Box
                sx={{
                  display : "flex",
                  flexGrow : 1,
                  flexDirection : "column",
                  marginLeft : 1,
                  overflow : "wrap"
                }}
              >
                <Typography
                  variant="body2"
                >
                  Pas de tire-bouchon à bord ! Qui nous en prêt un ? On vient le chercher en annexe.
                </Typography>
                <Typography
                  variant="caption"
                  color="grey"
                >
                  Jeremy, il y a 3 mn
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{display : "flex", opacity : 0, flexDirection : "row", width : "100%", marginTop : 2, animation : animationState.animationBubble3}} justifyContent="flex-start" onAnimationEnd = {handleAnimationEnd}>
            <Box
              sx={{
                display : "flex",
                flexDirection : "row",
                alignItems : "center"
              }}
            >
              <Avatar src="/static/avatar/3.jpg"/>
              <Box
                sx={{
                  display : "flex",
                  flexGrow : 1,
                  flexDirection : "column",
                  marginLeft : 1,
                  overflow : "wrap"
                }}
              >
                <Typography
                  variant="body2"
                >
                  Salut les voisins, vous avez mis combien de chaîne à l'eau ? Ca va souffler cette nuit
                </Typography>
                <Typography
                  variant="caption"
                  color="grey"
                >
                  Nadia, il y a 3 mn
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Zoom>
    </Fragment>
  );
}