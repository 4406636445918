import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import Grow from '@mui/material/Grow';
import CancelIcon from '@mui/icons-material/Cancel';
import PatchFunction from '../API/patchFunction';
import GetFunction from '../API/getFunction';
import Paper from '@mui/material/Paper';

import BackButton from '../Navigation/BackButton';

import Container from '@mui/material/Container';
import {useState, useEffect} from 'react'

import { useNavigate } from 'react-router-dom'

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'
        
export default function EditAccount() {

  const dispatch = useDispatch()

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const [account, setAccount] = useState({
    email : null,
    id:null,
    newPassword1:"",
    newPassword2:"",
    isEmailUnique:true,
    isFetching : false,
    alertObject : null
  });

  const navigate = useNavigate()

  const handleSubmitChange = () => {
    UpdateMyAccount()
  };

  const handleSubmitCancel = () => {
    navigate("/Account", {replace : true})
  };
  

  function LoadAccount() {
    setAccount(prevState => ({...prevState, isFetching:true}))
    console.log('EditAccount.js -> Chargement API getAccount')

    GetFunction({fetchTarget : 'getAccount', fetchArgument : null, token : token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log(response)
        console.log('EditAccount.js -> Fin chargement API getAccount')
        console.log('EditAccount.js -> Mise à jour du statut getAccount')
        setAccount(prevState => ({...prevState, email:response.data[0].email, initialEmail:response.data[0].email, id:response.data[0].id, isFetching:false}))
      } else {
        console.log('EditAccount.js -> Impossible de charger le compte')
        setAccount(prevState => ({...prevState, isFetching:false}))
        let errorMessage = "Impossible de charger vos identifiants, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
}

  useEffect(() => {
    console.log('EditAccount.js -> useEffect')
    LoadAccount()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  function userEmailError() {
    if (account.email) {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(account.email)) {
        if (account.isEmailUnique) {
          return ({error:false,message:""})
        } else {
          return ({error:true,message:"Adresse mail déjà utilisée"})
        }
      } else {
        return ({error:true,message:"Adresse mail invalide"})
      } 
    } else {
      return ({error:false,message:""})
    }
  }

function newPasswordError() {
  if (account.newPassword1 !== account.newPassword2) {
    return ({error:true,message:"Les deux mots de passe saisis ne sont pas identiques"})
  } else {
    return ({error:false,message:""})
  } 
}

  const handleChange = (event) => {
    setAccount(prevState => ({...prevState,[event.target.id]:event.target.value}))

    // Fetch recherche unicité du email (on vérifie que c'est un email correct avant de lancer l'API !)
    if ((event.target.id === "email")&&(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value))) {
      GetFunction({fetchTarget : 'checkEmail', fetchArgument : event.target.value, token : null})
      .then(response => {
        if(response.fetchStatus === 'Ok') {
          if ((response.data.length > 0) && (event.target.value !== account.initialEmail)){
            setAccount(prevState => ({...prevState, isEmailUnique:false}))
          } else {
            setAccount(prevState => ({...prevState, isEmailUnique:true}))
          }
        }
      })
    }
  };

  function UpdateMyAccount() {
    console.log('EditAccount.js -> Chargement API sendMyAccount')
    setAccount(prevState => ({...prevState, isFetching:true}))
    let myNewAccount = {
      username : account.email,
      email : account.email,
      password : account.newPassword1
    }
    PatchFunction({fetchTarget:'sendMyAccount', fetchObjectId:account.id, fetchArgument:myNewAccount, token:token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('EditAccount.js -> Fin chargement API sendMyProfile')
        navigate(-1)
      } else {
      setAccount(prevState => ({...prevState,
          isFetching:false,
          alertObject:<Alert severity="error">Erreur lors de l'envoi des nouveux identifiants</Alert>
        }))
        console.log('EditAccount.js -> Envoi du nouveau profil en échec')
        let errorMessage = "Impossible d'enregistrer votre nouveau profil, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function EditAccountForm() {
    if (account.id) {
      return (
        <Container component="main" maxWidth="xs">
          <Grow in={true} direction="up">
          <Paper elevation={10}>
            <Box
            sx={{
              marginTop: 5,
              marginRight: 1,
              marginLeft: 1,
              marginBottom : 5,
              paddingTop: 2,
              paddingRight: 2,
              paddingLeft: 2,
              paddingBottom : 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor : "white",
              borderRadius : 3
            }}
          >
            <BackButton/>
            <Box sx={{ mt: 3}}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Votre adresse email sera votre identificant de connexion"
                    error={userEmailError().error}
                    helperText={userEmailError().message}
                    onChange={(event) => handleChange(event)}
                    value={account.email}
                    sx={{marginTop : 2, marginBottom : 1}}
                  />
                  <TextField
                    fullWidth
                    id="newPassword1"
                    label="Nouveau mot de passe"
                    error={newPasswordError().error}
                    helperText={newPasswordError().message}
                    onChange={(event) => handleChange(event)}
                    value={account.newPassword1}
                    type="password"
                    sx={{marginTop : 1, marginBottom : 1}}
                  />
                  <TextField
                    fullWidth
                    id="newPassword2"
                    label="Resaisissez votre nouveau mot de passe"
                    error={newPasswordError().error}
                    helperText={newPasswordError().message}
                    onChange={(event) => handleChange(event)}
                    value={account.newPassword2}
                    type="password"
                    sx={{marginTop : 1, marginBottom : 2}}
                  />
            </Box>
            {account.alertObject}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft:2,
              }}
            >
              <Button
                  variant="contained"
                  sx={{ ml: 2, mr:2 }}
                  endIcon={<CancelIcon />}
                  onClick={handleSubmitCancel}
                  color="error"
                >
                  Annuler
              </Button>
              <LoadingButton
                variant="contained"
                sx={{ ml: 2, mr:2 }}
                endIcon={<SendIcon />}
                onClick={handleSubmitChange}
                disabled={userEmailError().error || newPasswordError().error || !account.email || !account.newPassword1}
                loading={account.isFetching}
              >
                Valider
              </LoadingButton>
              </Box>
          </Box>
          </Paper>
          </Grow>
        </Container>
      );
    } else {
      return null
    }
  }

  return (
    EditAccountForm()
  );
}