export default function PatchFunction (props) {

  let urlBase = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:8000'
  : 'https://api.nautictalk.com'

  let headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Token ' + props.token,
  }

  let url = ""

  let fetchOptions = {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(props.fetchArgument)
  }

  console.log('patchFunction '+ props.fetchTarget +' -> Lancement Fetch')
  switch (props.fetchTarget) {
    case 'sendMyProfileForm':
      url = urlBase + '/userprofile/' + props.fetchObjectId + '/'
      fetchOptions = {
        method: 'PATCH',
        headers: { // On supprime le header 'content-type', incompatible avec un fetch sur un FormData 
          'Authorization': 'Token ' + props.token, 
        },
        body: props.fetchArgument  // Pour envoyer un FormData, on ne passe pas par le format JSON
      }
      break;
    case 'sendMyAccount':
      url = urlBase + '/account/' + props.fetchObjectId + '/'
      break;
    case 'notificationsviewed':
      url = urlBase + '/notification/' + props.fetchObjectId + '/'
      break;
    case 'chatroomsubscriptionread':
      url = urlBase + '/chatroomsubscriptionread/' + props.fetchObjectId + '/'
      break;
    default:
      console.log('patchFunction '+props.fetchTarget+' -> fetchTarget inconnu')
  }
  return fetch(url,fetchOptions)
  .then((response) => {
    if (!response.ok) {
      throw new Error('Response pas Ok')
    } else {
      console.log('patchFunction '+props.fetchTarget+'-> Fin du fetch OK')
      return({fetchStatus:'Ok'})
    }
  })
  .then((response) => {
    console.log('postFunction '+props.fetchTarget+' -> Fetch terminé OK')
    return({fetchStatus:'Ok', data:response})
  })
  .catch((error) => {
    if (error === 'Response pas Ok ') {
      console.log('patchFunction '+props.fetchTarget+'-> Réponse non attendue du fetch')
    } else {
      console.log('patchFunction '+props.fetchTarget+'-> Erreur pendant le Fetch')
    }
    return({fetchStatus:'Error'})
  })
}
