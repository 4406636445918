import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SkipNextIcon from '@mui/icons-material/SkipNext';

import GetFunction from '../API/getFunction';

import {useState, useContext} from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

// Pour utiliser le contexte
import { ImageContext } from '../Context/ImageContext';


export default function Register() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Pour utiliser le Context de l'application, pour stocker la photo (impossible à stocker dans le Store)
  const { setContextImage } = useContext(ImageContext);

  // Récupération du state transmis par Navigate, qui contient "back" si on vient de la page suivante
  const {state} = useLocation();
  let fromPrevious = false
  if (state) {
    if (state.fromPrevious) {
      fromPrevious = true
    }
  }

  const selectRegistrationData = state => state.registrationData
  const registrationData = useSelector(selectRegistrationData)

  // On initialise les données du formulaire avec les données déjà saisies stockées dans le store
  const [screenRegistrationData, setScreenRegistrationData] = useState(registrationData);

  const handleSubmitCancel = () => {
    // On supprime les données saisies
    dispatch({ type : "RESET_REGISTRATION_DATA", payload : null})
    setContextImage({newPicFile : null, newAvatarFile : null})

    navigate("/")
  };

  const handleNextPage = () => {
    dispatch({ type : "UPDATE_REGISTRATION_DATA", payload:screenRegistrationData})
    navigate("/RegisterPassword")
  };

  function userEmailError() {
    if (screenRegistrationData.userEmail) {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(screenRegistrationData.userEmail)) {
        if (screenRegistrationData.isEmailUnique) {
          return ({error:false,message:""})
        } else {
          return ({error:true,message:"Adresse mail déjà utilisée"})
        }
      } else {
        return ({error:true,message:"Adresse mail invalide"})
      } 
    } else {
      return ({error:false,message:""})
    }
  }

  const handleChange = (event) => {
    setScreenRegistrationData({...screenRegistrationData,[event.target.id]:event.target.value})
    // Fetch recherche unicité du email (on vérifie que c'est un email correct avant de lancer l'API !)
    if ((event.target.id === "userEmail")&&(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value))) {
      GetFunction({fetchTarget : 'checkEmail', fetchArgument : event.target.value, token : null})
      .then(response => {
        if(response.fetchStatus === 'Ok') {
          if (response.data.length > 0) {
            setScreenRegistrationData(prevState => ({...prevState, isEmailUnique:false}))
          } else {
            setScreenRegistrationData(prevState => ({...prevState, isEmailUnique:true}))
          }
        }
      })
    }
  };

  function Button1() {
    return(
      <Button
        variant="contained"
        sx={{margin : 2}}
        endIcon={<CancelIcon />}
        onClick={handleSubmitCancel}
        color={"error"}
      >
        Annuler
      </Button>
    )
  }

  function Button2() {
    return(
      <Button
        variant="contained"
        sx={{margin : 2}}
        endIcon={<SkipNextIcon />}
        onClick={handleNextPage}
        color={"primary"}
        disabled={userEmailError().error || !screenRegistrationData.userEmail}
      >
        Suite
      </Button>
    )
  }

  function ButtonBox() {
    return(
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft:2,
          marginTop : 2
        }}
      >
        {Button1()}
        {Button2()}
      </Box>
    )
  }

  return (
    <Slide in={true} direction={fromPrevious ? "right" : "left"}>
      <Box
        sx={{
          position : "fixed",
          top : 0,
          paddingTop : {xs : 9, sm : 9, md : 10},
          bottom : 0,
          left : 0, 
          right : 0,
          paddingLeft : 2,
          paddingRight : 2,
          backgroundColor : "white",
          overflow : "auto",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" fontWeight = "bold" color="primary">
          Ton email
        </Typography>
        <Typography variant={"body1"}>
          Cette adresse sera utilisée uniquement pour t'envoyer des notifications et des infomations sur l'utilisation de NauticTalk. Promis, nous ne l'utiliserons jamais à des fins publicitaires, et cette adresse ne sera jamais communiquée à un tiers ou à qui que ce soit sans ton accord..
        </Typography>
        <TextField
          sx={{marginTop : 3}}
          required
          fullWidth
          id="userEmail"
          label="Adresse mail"
          error={userEmailError().error}
          helperText={userEmailError().message}
          onChange={(event) => handleChange(event)}
          value={screenRegistrationData.userEmail}
          disabled={screenRegistrationData.isRegistered}
        />
        {ButtonBox()}
      </Box>
    </Slide>
  );
}