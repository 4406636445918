import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

import BackButton from '../Navigation/BackButton';

import Container from '@mui/material/Container';
import {useState,} from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import PostFunction from '../API/postFunction';

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'

       
export default function Report() {

  const selectProfile = state => state.myProfile
  const profil = useSelector(selectProfile)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // On récupère le postId et le commentId passés en paramètre du lien
  let {postId, commentId} = useParams()

  const [newReportState, setNewReportState] = useState({
    text : "",
    isFetching : false,
    alertObject : null
  })

  let newReport = null
    
  function handleSubmitReport() {
    SendNewReport()
  };

  function handleSubmitCancel() {
    navigate(-1)
  };

  function SendNewReport() {
    console.log('Report.js -> Lancement API sendReport')
    setNewReportState(prevState => ({...prevState, isFetching:true}))
    
    if (commentId === "null") {
      newReport = {
        reportPost : postId,
        reportComment : "",
        reportAuthor : profil.pk,
        reportText : newReportState.text
          }
    } else {
      newReport = {
        reportPost : postId,
        reportComment : commentId,
        reportAuthor : profil.pk,
        reportText : newReportState.text
      }
    }

    
    PostFunction({fetchTarget : 'sendReport', fetchArgument : newReport, token : token})
    .then(response => {
      if (response.fetchStatus === 'Ok')  {
        console.log('Report.js -> Envoi OK')
        setNewReportState(prevState => ({...prevState, isFetching:false}))
        navigate(-1)
      } else {
        console.log('Report.js -> Envoi en échec')
        setNewReportState(prevState => ({...prevState,
          isFetching:false,
          alertObject:<Alert severity="error">Erreur lors de l'envoi du signalement</Alert>
        }))
        let errorMessage = "Erreur lors de l'envoi du signalement, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function handleChange(event) {
    setNewReportState({...newReportState,[event.target.id]:event.target.value})
  };

  return (
    <Container component="main" maxWidth="xs">
      <BackButton/>
      <Grow in={true} direction="up">
        <Paper elevation={10}>
          <Box
            sx={{
              marginTop: 5,
              marginRight: 1,
              marginLeft: 1,
              marginBottom : 5,
              paddingTop: 2,
              paddingRight: 2,
              paddingLeft: 2,
              paddingBottom : 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor : "white",
              borderRadius : 3
            }}
          >
            <Box sx={{ mt: 3}}>
              <Typography color={"primary"} paragraph={true}>
                Votre signalement va nous être envoyé.
                </Typography>
              <Typography color={"primary"} paragraph={true}>
                Vous pouvez nous donner des informations supplémentaires sur la raison de votre signalement.
              </Typography>
              <TextField
                fullWidth
                multiline
                id="text"
                label="Votre signalement"
                onChange={(event) => handleChange(event)}
                value={newReportState.text}
                sx={{marginTop : 2, marginBottom : 1}}
                rows={3}
              />
            </Box>
            {newReportState.alertObject}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft:2,
              }}
            >
              <Button
                  variant="contained"
                  sx={{ ml: 2, mr:2 }}
                  onClick={handleSubmitCancel}
                  color="error"
                >
                  <CancelIcon/>
              </Button>
              <LoadingButton
                variant="contained"
                sx={{ ml: 2, mr:2 }}
                onClick={handleSubmitReport}
                loading={newReportState.isFetching}
                color="success"
                >
                <SendIcon/>
              </LoadingButton>
            </Box>
          </Box>
        </Paper>
      </Grow>
    </Container>
  );
}