export default function BoatTypes() {
    return(
        [
            "Voilier - Monocoque",
            "Voilier - Catamaran",
            "Voilier - Trimaran",
            "Bateau à moteur",
            "Bateau à rames"
        ]
    )
}
