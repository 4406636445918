import * as React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

//Sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Redux
import { Provider } from 'react-redux'
import store from './components/Store/Store'

// ThemeProvider permet d'appliquer des personnalisations au thème par défaut de MaterialUI
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme' 
 
// Utilisation du Context (qui sert à échanger des images entre les somponents de Register)
import {ImageProvider} from "./components/Context/ImageContext"

// Si en production : redirection des flux HTTP en HTTPS + lancement Sentry
if (process.env.NODE_ENV === "production") {
    if (window.location.protocol === 'http:') {
        console.log("index.js -> Lancement redirection HTTPS")
        window.location.href = window.location.href.replace('http:', 'https:')
    } else {
        if (window.location.protocol === "https:") {
            console.log("index.js -> Redirection HTTPS terminée")
        }
    }
    console.log ("index.jsx -> Lancement Sentry")
    Sentry.init({
        dsn: "https://e5b7814a3add43b9b80e198c09695d76@o4504340807745536.ingest.sentry.io/4504355818569728",
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
    
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>   StrictMode ajouté à un moment mais supprimé car crée un double render de l'app           
    <ImageProvider>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <App/>
            </Provider>
        </ThemeProvider>
    </ImageProvider>
    // </React.StrictMode>
);