import { Fragment, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';
import { useDispatch  } from 'react-redux'
import { useTheme } from "@emotion/react";
import { keyframes } from '@emotion/react'

import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import Zoom from '@mui/material/Zoom';


export default function Tutorial5() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()

  
  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('NotificationList.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "DISPLAY_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('NotificationList.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])

  //
  // Animations standard
  //

  let buttonEffect = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }`

  let tutorialButtonEffect = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }`

  function PreviousNextButtons() {
    return (
      <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", position : "fixed", bottom : 0, right : 1, left : 0, backgroundColor : "white", padding : 1, zIndex : theme.zIndex.modal-1}}>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/Tutorial4", {replace : true})
          }}
          color="primary"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <SkipPreviousIcon/>
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/MapPage", {replace : true})
          }}
          color="error"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <HighlightOffIcon />
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/Tutorial6", {replace : true})
          }}
          color="primary"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <SkipNextIcon/>
        </Fab>
      </Box>
    );
  }

  return(
    <Fragment>
      {/* COUCHE OPAQUE D'INTERCEPTION DES CLICS */}
      <Box sx={{
        display : "flex",
        position : "fixed",
        top : 0,
        bottom : 0,
        right : 0,
        left : 0,
        backgroundColor : "black",
        opacity : 0.4,
        zIndex : theme.zIndex.modal-1, // On se met juste au dessous du niveau des modal (Alert etc...)
      }}/>

      {PreviousNextButtons()}

      <Box
        sx={{
          display : "flex",
          flexDirection : "row-reverse",
          alignItems : "center",
          justifyContent : "space-evenly",
          position : "fixed",
          top : "25%",
          left : 0,
          right : 0,
          zIndex : theme.zIndex.modal
        }}
      >

        {/* BULLES DE TEXTE DU TUTO - FLECHE A GAUCHE*/}
        <Zoom in={true}>

        <Box 
          sx={{
            position : "relative",
            display: 'inline-block',
            padding: '16px',
            backgroundColor: 'white',
            borderRadius: '15px',
            boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.7)',
            maxWidth: '200px',
            zIndex : theme.zIndex.modal,
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '50%', // Ajuste la hauteur de la pointe
              left: '-10px', // Place le triangle sur le côté gauche
              width: '0',
              height: '0',
              borderTop: '10px solid transparent',
              borderBottom: '10px solid transparent',
              borderRight: '10px solid white  ', // Pointe du triangle vers la bulle
            },
          }}
        >
          <Typography variant = "h6" color={"primary"} paragraph={true}>
            Ca, c'est un signalement.
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            Quelqu'un a signalé un évènement ici. Clique dessus pour voir les détails et la conversation de l'évènement.
          </Typography>
          {PreviousNextButtons()}
          </Box>
        </Zoom>

        <Box
          component="img"
          sx={{
            height: 60,
            width: 60,
            animation: `${buttonEffect} 0.5s ease infinite`,
            zIndex : theme.zIndex.modal
          }}
          onClick = {() => navigate('/Tutorial6', {replace : true})}
          src={"/static/Mark-dauphin.svg"}
        />
      </Box>

      <Box
        sx={{
          display : "flex",
          flexDirection : "row",
          alignItems : "center",
          justifyContent : "space-evenly",
          position : "fixed",
          bottom : "20%",
          left : 0,
          right : 0,
          zIndex : theme.zIndex.modal
        }}
      >

        {/* BULLES DE TEXTE DU TUTO - FLECHE A DROITE*/}
        <Zoom in={true}>

        <Box 
          sx={{
            position : "relative",
            display: 'inline-block',
            padding: '16px',
            backgroundColor: 'white',
            borderRadius: '15px',
            boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.7)',
            maxWidth: '200px',
            zIndex : theme.zIndex.modal,
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '50%', // Ajuste la hauteur de la pointe
              right: '-10px', // Place le triangle sur le côté gauche
              width: '0',
              height: '0',
              borderTop: '10px solid transparent',
              borderBottom: '10px solid transparent',
              borderLeft: '10px solid white  ', // Pointe du triangle vers la bulle
            },
          }}
        >
          <Typography variant = "h6" color={"primary"} paragraph={true}>
            Ca, c'est la position d'un autre utilisateur.
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            Clique pour voir son profil et discuter avec lui
          </Typography>
        </Box>
        </Zoom>

        <Box
          component="img"
          sx={{
            height: 60,
            width: 60,
            animation: `${buttonEffect} 0.5s ease infinite`,
            zIndex : theme.zIndex.modal
          }}
          onClick = {() => navigate('/Tutorial6', {replace : true})}
          src={"/static/Mark.svg"}
        />
      </Box>

    </Fragment>
 )
}