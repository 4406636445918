import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';

import { useEffect, Fragment } from "react";

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

import {StatusNames, StatusUpgradePoints} from './StatusList'
import GetFunction from "../API/getFunction";

// Keyframes pour les animations
import { keyframes } from '@emotion/react'

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'

import BoatTypes from '../Profile/BoatTypes'

export default function Profile() {

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Types de bateaux et de statuts
  const boatTypes = BoatTypes()
  const status = StatusNames()
  const upgradePoints = StatusUpgradePoints()

  //
  // Animation du badge
  //

  
  const boxSx = {margin : 1, padding : 2,border : 3, borderRadius : 5, borderColor : "#f3722c", display : "flex", flexDirection : "column", alignItems : "center", justifyContent : "space-around"}

  const noBoxSx = {margin : 1, padding : 2, border : 1, borderRadius : 5, borderColor : "#000100",display : "flex", flexDirection : "column", alignItems : "center", justifyContent : "space-around"}

  let buttonEffect = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }`

  const notAnimatedSx = { width: 50, height: 50}
  const animatedSx = { width: 50, height: 50, animation: `${buttonEffect} 1s ease infinite`}

  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('Profile.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "HIDE_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('Profile.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])
  
  const profilePicture = () => {
    if (myProfile.userPicurl) {
    return (
      <Avatar
      src={myProfile.userPicurl}  
      sx={{ width: 70, height: 70,margin:1 }}
    />
      )
    } else {
    return (
      <AccountCircleIcon color="primary" aria-label="upload picture" sx={{ fontSize: 100 }} />
    );
    }
  };
  
  // Chargement du profil le plus récent
  useEffect(() => {
    GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('Profile.js -> Chargement userProfile dans le state Redux')
        dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
      } else {
        console.log('Profile.js -> Erreur dans le fetch userProfile')
      }
    })
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[])

  const myBoatType = () => {
    return(boatTypes[myProfile.userBoatType-1])
  }

  const StatusBoxes = () => {
    return(
      <Box width = "100%" sx={{display : "flex", flexDirection : "row"}}>
        <Box flex={1}/>
        <Box display="flex" flexDirection="column" flex={1}>
          <Typography variant="body1"  color={"secondary"} textAlign={'center'} fontWeight="bold">
            Votre statut  
          </Typography>
          <Typography variant="body2"  color={"secondary"} textAlign={'center'} fontWeight="bold">
            {myProfile.userGlobalPoints + " Nautiks"}  
          </Typography>
          <Box sx={boxSx}>
            <Typography variant="body2" color="secondary" sx ={{fontWeight : "bold"}} textAlign ='center'>
              {status[myProfile.userGlobalStatus]}
            </Typography>
            <Box sx={{display : "flex", flexDirection : "row", alignItems : "center"}}>
              <Avatar
                src={"/static/status/status" + (myProfile.userGlobalStatus + 1) + ".svg"}  
                sx={animatedSx}
              />
              <Avatar
                src={"/static/usermapicon/usermapicon" + (myProfile.userGlobalStatus + 1) + ".svg"}  
                sx={animatedSx}
              />
              </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex={1}>
          <Typography variant="body1"  color={"primary"} textAlign={'center'} fontWeight="bold">
            Prochain statut  
          </Typography>
          <Typography variant="body2"  color={"primary"} textAlign={'center'} fontWeight="regular">
            {upgradePoints[myProfile.userGlobalStatus+1] + " Nautiks"}  
          </Typography>
          <Box sx={noBoxSx}>
            <Typography variant="body2" color="primary" sx ={{fontWeight : "regular"}} textAlign ='center'>
              {status[myProfile.userGlobalStatus+1]}
            </Typography>
            <Box sx={{display : "flex", flexDirection : "row", alignItems : "center"}}>
              <Avatar
                src={"/static/status/status" + (myProfile.userGlobalStatus + 2) + ".svg"}  
                sx={notAnimatedSx}
              />
              <Avatar
                src={"/static/usermapicon/usermapicon" + (myProfile.userGlobalStatus + 2) + ".svg"}  
                sx={notAnimatedSx}
              />
            </Box>
          </Box>
        </Box>
        <Box flex={1}/>
    </Box>
    )
  }

  return (
    <Fade in={true} timeout={200}>
      <Box sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0}}>
        {/* Fond grisé qui renvoie vers la page de la carte*/}
        <Box
          onClick = {() => {navigate("/MapPage")}} 
          sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, backgroundColor : "grey", opacity : 0.5}}
        />
        <Paper sx={{position : "absolute", top : {xs :80, md : 90}, bottom : 20, left : 20, right : 20, backgroundColor : "white", borderRadius : 1}} elevation={24} >
          {/* Croix pour fermer la fenêtre */}
          <Box sx={{position : "absolute", top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => {navigate("/MapPage")}}>
            <IconButton color="secondary">
              <HighlightOffIcon fontSize = "large"/>
            </IconButton>
          </Box>
          <Box sx={{
            display : "flex",
            flexDirection : "column",
            height : "100%",
            width : "100%",
            alignItems : "center"
            }}
            overflow = "auto"
          >
            {profilePicture()}
            <Typography fontSize={"x-large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
              {myProfile.userNickname}
            </Typography>
            {(myProfile.userIsHarbor ?
              <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                Profil Capitainerie
              </Typography>
              :
              null
            )}
            <p/>
            <Box sx={{display : "flex", flexDirection : "column"}}>
              {StatusBoxes()}
              <Typography variant="body2" color={"primary"} textAlign={'center'}>
                Pour gagner des nautiks et acquérir des badges, soyez actifs en faisant vivre notre communauté : publiez des posts, commentez et likez ceux des autres utilisateurs !
              </Typography>
              <p/>
            </Box>
            <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
              {(myProfile.userIsHarbor ? "Le mot de la capitainerie": "Quelques mots sur moi")}
            </Typography>
            <Typography variant="body2" color={"primary"} textAlign={'left'}>
              {(myProfile.userDescription?myProfile.userDescription:"Vous n'avez pas rempli ce champ du formulaire")}
            </Typography>
            <p/>
            {(myProfile.userIsHarbor ?
              null
              :
              <Fragment>
                <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                  Quelques mots sur mon bateau
                </Typography>
                <Typography variant="body2" color={"primary"} textAlign={'left'}>
                  {(myProfile.userBoat?myProfile.userBoat:"Vous n'avez pas rempli ce champ du formulaire")}
                </Typography>
                <p/>
                {myProfile.userDisplayBoatType ?
                  <Fragment>
                    <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                      Mon type de bateau
                    </Typography>
                    <Typography variant="body2" color={"primary"} textAlign={'left'}>
                      {myBoatType()}
                    </Typography>
                    <p/>
                  </Fragment>
                  :
                  null
                }
                {myProfile.userDisplayBoatName ?
                  <Fragment>
                    <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                      Le nom de mon bateau
                    </Typography>
                    <Typography variant="body2" color={"primary"} textAlign={'left'}>
                      {myProfile.userBoatName}
                    </Typography>
                    <p/>
                  </Fragment>
                    :
                  null
                }
                {myProfile.userDisplayBoatModel ?
                  <Fragment>
                    <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                      Le modèle de mon bateau
                    </Typography>
                    <Typography variant="body2" color={"primary"} textAlign={'left'}>
                      {myProfile.userBoatModel}
                    </Typography>
                    <p/>
                  </Fragment>
                    :
                  null
                }
                {myProfile.userDisplayBoatHarbor ?
                  <Fragment>
                    <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                      Mon port d'attache
                    </Typography>
                    <Typography variant="body2" color={"primary"} textAlign={'left'}>
                      {myProfile.userBoatHarbor}
                    </Typography>
                    <p/>
                  </Fragment>
                    :
                  null
                }
              </Fragment>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography fontSize={"large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
                Notifications par mail
              </Typography>
              {myProfile.userMailNotification ?
                  <Typography fontSize={"small"} color={"primary"} textAlign={'left'}>
                    Vous recevrez un mail pour vous avertir d'une nouvelle notification
                  </Typography>
                :
                  <Typography fontSize={"small"} color={"error"} textAlign={'left'}>
                    Vous ne recevrez pas de mail en cas de notification dans l'application
                  </Typography>
              }
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft:2,
                marginTop : 2
              }}
            >
              <Button
                onClick={() => navigate("/EditProfile")}
                variant="contained"
                endIcon={<EditIcon />}
                sx={{margin : 2}}
                >
                Modifier
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fade>
 );
}