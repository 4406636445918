import { Fragment, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';
import { useDispatch  } from 'react-redux'
import { useTheme } from "@emotion/react";
import { keyframes } from '@emotion/react'

import Button from '@mui/material/Button';

import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import Zoom from '@mui/material/Zoom';

export default function Tutorial9(props) {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()

  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('NotificationList.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "DISPLAY_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('NotificationList.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])

  //
  // Animations standard
  //

  let appBarButtonEffect = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.30);
  }
  100% {
    transform: scale(1);
  }`

  let tutorialButtonEffect = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }`

  function SearchTextButton() {
    return (
      <Button
        sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex' },
      }}
        endIcon={<SearchIcon
        />}
        >
        Recherche
      </Button>
    )
}
  
function SearchIconButton() {
    return(
      <IconButton
        size="large"
        sx={{ color: 'white', display: { xs: 'flex', md: 'none' } }}
        >
          <SearchIcon
        />
      </IconButton>
    )
}

function MapTextButton() {
    return (
    <Button
        sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex'} }}
        endIcon={<LocationOnIcon
          sx={{fontSize:"35px",
          }}/>}
          >
        Explorer
      </Button>
    )
}
  
function MapIconButton() {
    return (
      <IconButton
        sx={{ color: 'white', display: { xs: 'flex', md: 'none'} }}
        size="large"
      >
        <LocationOnIcon sx={{fontSize:"35px",
         }}
         />
      </IconButton>
    )
}

function NewsFeedTextButton() {
    return (
      <Button
        sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex'}  }}
        endIcon={<FavoriteIcon
          sx={{animation: `${appBarButtonEffect} 0.5s ease infinite`}}
          />}
          onClick = {() => navigate('/TutorialEnd', {replace : true})}
          >
        Mes favoris
      </Button>
    )
}
  
function NewsFeedIconButton() {
    return (
      // Box permettant de centrer la bulle sur le bouton, quelles que soient les dimensions de l'écran
      <Box sx={{ position: 'relative', display: 'inline-block' }}>

        <IconButton
          sx={{ color: 'white', display: { xs: 'flex', md: 'none'}  }}
          size="large"
          onClick = {() => navigate('/TutorialEnd', {replace : true})}
        >
          <FavoriteIcon
          sx={{animation: `${appBarButtonEffect} 0.5s ease infinite`}}
          />
        </IconButton>

        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, +50px)',
          }}
        >
          {Bubble()}
        </Box>
      </Box>
    )
}


function InboxTextButton() {
    return (
      <Button
        key="InboxButton"
        sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex'} }}
        endIcon={<Badge badgeContent={1} color="error"><MailIcon
        /></Badge>}
      >
        Inbox
      </Button>
    )
}

function InboxIconButton() {
    return (
        <IconButton
          size="large"
          sx={{ color: 'white', display: { xs: 'flex', md: 'none'} }}
          >
          <Badge badgeContent={1} color="error">
            <MailIcon
        />
          </Badge>
        </IconButton>
    )
}

function NotificationTextButton() {
    return (
        <Button
          sx={{ my: 2, color: 'white', display: { xs: 'none', md: 'flex'} }}
          endIcon={<Badge badgeContent={2} color="error"><NotificationsIcon
          /></Badge>}
          >
          Notifications
        </Button>
    )
}

function NotificationIconButton() {
    return (
        <IconButton
          size="large"
          sx={{ color: 'white', display: { xs: 'flex', md: 'none'} }}
          >
          <Badge badgeContent={2} color="error">
            <NotificationsIcon
        />
          </Badge>
        </IconButton>
    )
}

function UserAvatarButton(){
      return (
        <IconButton
        >
        <AccountCircleIcon aria-label="No picture" sx={{ fontSize: 30, color:"white",
          }} />
       </IconButton>
      )
} 

function PreviousNextButtons() {
  return (
    <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", position : "fixed", bottom : 0, right : 1, left : 0, backgroundColor : "white", padding : 1, zIndex : theme.zIndex.modal-1}}>
      <Fab
        size="small"
        onClick={() => {
          navigate ("/Tutorial8", {replace : true})
        }}
        color="primary"
        sx={{
          ml: 2, mr: 2,
          zIndex : theme.zIndex.modal
        }}
      >
        <SkipPreviousIcon/>
      </Fab>
      <Fab
        size="small"
        onClick={() => {
          navigate ("/MapPage", {replace : true})
        }}
        color="error"
        sx={{
          ml: 2, mr: 2,
          zIndex : theme.zIndex.modal
        }}
      >
        <HighlightOffIcon />
      </Fab>
      <Fab
        size="small"
        onClick={() => {
          navigate ("/TutorialEnd", {replace : true})
        }}
        color="primary"
        sx={{
          ml: 2, mr: 2,
          zIndex : theme.zIndex.modal
        }}
      >
        <SkipNextIcon/>
      </Fab>
    </Box>
  );
}

function Bubble() {
  return(
    // BULLES DE TEXTE DU TUTO - FLECHE EN HAUT
    <Zoom in={true}>
      <Box 
        sx={{
          display: 'inline-block',
          padding: '16px',
          backgroundColor: 'white',
          borderRadius: '15px',
          boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.7)',
          zIndex : theme.zIndex.modal,
          maxWidth : 300,
          '&::after': {
            content: '""',
            position: 'absolute',
            top: '-10px', // Placement du triangle en bas de la bulle
            left: '40%', // Ajuster pour le décaler par rapport à la bulle
            width: '0',
            height: '0',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid white',
          },
        }}
      >
        <Typography variant = "h6" color={"primary"} paragraph={true}>
          Retrouve ici toutes les publications de tes spots favoris.
        </Typography>
      </Box>
    </Zoom>
  )
}

  return (
    <Fragment>
      {/* COUCHE OPAQUE D'INTERCEPTION DES CLICS */}
      <Box sx={{
        display : "flex",
        position : "fixed",
        top : 0,
        bottom : 0,
        right : 0,
        left : 0,
        backgroundColor : "black",
        opacity : 0.4,
        zIndex : theme.zIndex.modal-1, // On se met juste au dessous du niveau des modal (Alert etc...)
      }}/>

      {PreviousNextButtons()}


      <Box sx={{
        display : "flex",
        flexDirection:"column",
        position: 'fixed',
        top: 0, left: 0, right: 0,
        zIndex : theme.zIndex.modal
      }}>
      <AppBar position="absolute" sx={{borderBottomLeftRadius : 30, borderBottomRightRadius : 30
        }} color="secondary">
        <Toolbar variant="dense" sx={{zIndex : theme.zIndex.modal}}>
          <Box>
            <IconButton
              size="large"
              sx={{ color: 'white'}}
            >
              <MenuIcon/>
            </IconButton>
          </Box>
          <Typography fontWeight="bold" fontSize={20} color = "white" sx={{display : {xs : "none", sm : "flex"}, zIndex : theme.zIndex.modal}}>
            NauticTalk
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {SearchIconButton()}
          {SearchTextButton()}
          {NewsFeedTextButton()}
          {NewsFeedIconButton()}
          {MapTextButton()}
          {MapIconButton()}
          {InboxTextButton()}
          {InboxIconButton()}
          {NotificationTextButton()}
          {NotificationIconButton()}
          <Box sx={{ flexGrow: 0 }}>
            {UserAvatarButton()}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
    </Fragment>
  );
}