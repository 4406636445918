import { createTheme } from '@mui/material/styles';

// Le theme permet de modifier tous les attributs du theme de base de Material UI

const theme = createTheme({
  palette: {                // Palette permet de définir la palette de couleurs
    primary: {              // Primary : les paramètres utilisés par défaut
      main: '#277DA1',      // Main : la couleur principale. On peut aussi préciser dark, light et contrastText,
    },                      // sinon ils sont calculés automatiquement à partir de Main
    secondary: {            // Secondary : autre palette pouvant être appelée en props d'un composant
      main: "#f3722C",
    },
    success : {
      main : "#43AA8B"
    },
    error : {
      main : "#f94144"
    }
  }
});

export default theme;