import { useSelector, useDispatch  } from 'react-redux'
import {useState, useEffect, useRef} from 'react'

import GetFunction from '../API/getFunction';
import PatchFunction from '../API/patchFunction';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Paper from '@mui/material/Paper';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Slide from '@mui/material/Slide';

import dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

import { useNavigate } from 'react-router-dom'

export default function NotificationList() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectNotificationArray = state => state.notificationArray
  const notificationArray = useSelector(selectNotificationArray)
  
  // state du contenu des notification
  const [notificationList, setNotificationList] = useState()

  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('NotificationList.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({ type : "HIDE_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('NotificationList.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])
 
  
  // Hook qui contruit le contenu de la liste à chaque fois que le contenu des notifications change
  // (améliore les performances : évite de recalculer le contenu de la liste à chaque render,
  // car il y a bcp de render avec le PullToRefresh)
  useEffect(() => {
    if (notificationArray) {
      setNotificationList(
        <List dense>
          {notificationArray.map((item, index) =>
            <ListItemButton onClick={() => OnNotificationClick(item)} divider alignItems="flex-start" key={item.id.toString()} sx={{backgroundColor : (index % 2 === 1 ? "white" : "#fdf0e9")}}>
              <ListItemAvatar>
                {NotificationAvatar(item)}
              </ListItemAvatar>
              <ListItemText
                primary={NotificationText(item)}
                secondary={DateFromNow(item)}
              />
            </ListItemButton>
          )}
        </List>
      )
    } else {
      setNotificationList(
        <Typography fontSize={"x-large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
          Pas de notification
        </Typography>
      )
    }
  }, [notificationArray])

  // pull-to-refresh : states pour conserver le point de départ + conserver la distance de pull
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Fonction pour forcer le refresh
  const RefreshNotificationList = () => {
    // Récupération du nouveau chatHeadersArray
    setIsRefreshing(true)
    GetFunction({fetchTarget : 'notificationArray', fetchArgument : null, token : token})
    .then(response => {
      setStartPoint(0);
      setPullChange(0);
      setIsRefreshing(false)
      if (response.fetchStatus === 'Ok') {
        console.log('NotificationList.js -> Fin chargement API mise à jour notificationArray')
        console.log('NotificationList.js -> Chargement notificationArray dans le state Redux')
        dispatch({ type : "LOAD_NOTIFICATION_ARRAY", payload : response.data})
      } else {
        console.log('NotificationList.js -> Erreur du fetch : on ne fait rien')
      }
    })
  }

  
  // 
  // GESTION DU PULL-TO-REFRESH
  //

  // Création des ref et des useEffect de mise à jour des ref quand les states changent
  // Parce que les listeners n'ont pas accès aux valeurs à jour des variables et des states (enclosure)
  const startPointRef = useRef();
  const pullChangeRef = useRef();
  const isRefreshingRef = useRef();

  useEffect(() => {
    startPointRef.current = startPoint
  }, [startPoint]);

  useEffect(() => {
    pullChangeRef.current = pullChange
  }, [pullChange]);

  useEffect(() => {
    isRefreshingRef.current = isRefreshing
  }, [isRefreshing]);

  // Création des 3 fonctions des listeners

  const pullStart = (e) => {
    // Pour empêcher de déclencher les listeners sur tous le séléments en même temps
    e.stopPropagation()
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
  };

  const pull = (e) => {
    e.stopPropagation()
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = (startPointRef.current < screenY ? screenY - startPointRef.current : 0);
    
    const scrollableList = listRef.current;
    const scrollTop = scrollableList.scrollTop;
    if (scrollTop === 0 && pullLength > 0) {
      setPullChange(pullLength);
    }
  };

  // Fonction pour gérer le endPull
  const endPull = (e) => {
    e.stopPropagation()
    if (pullChangeRef.current > 100) {
      // Plusieurs listeners peuvent être déclenchés en même temps : il faut lancer l'action une seule fois
      if (isRefreshingRef.current === false) {
        RefreshNotificationList()
      }
    } else {
      setStartPoint(0);
      setPullChange(0);
    }
  };
  
  // Déclaration des listeners

  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.removeEventListener("touchstart", pullStart, {passive : true});
      listRef.current.removeEventListener("touchmove", pull, {passive : true});
      listRef.current.removeEventListener("touchend", endPull, {passive : true});

      listRef.current.addEventListener("touchstart", pullStart, {passive : true}); // L'écran est touché
      listRef.current.addEventListener("touchmove", pull, {passive : true});       // Mouvement du doigt
      listRef.current.addEventListener("touchend", endPull, {passive : true});     // L'écran est lâché
    }
  }, [listRef.current]);
  
 
  function OnNotificationClick(item) {
    let notificationUpdate = {
      notificationRead: true,
    }
    PatchFunction({fetchTarget : 'notificationsviewed', fetchObjectId:item.id, fetchArgument : notificationUpdate, token : token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('NotificationList.js -> Fin chargement API notificationsviewed')
        console.log('NotificationList.js -> Mise à jour du statut Redux')
        GetFunction({fetchTarget : 'notificationArray', fetchArgument : null, token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('NotificationList.js -> Chargement notificationsArray dans le state Redux')
            dispatch({ type : "LOAD_NOTIFICATION_ARRAY", payload : response.data})
          } else {
            console.log('NotificationList.js -> Impossible de mettre à jour les pastilles de notification')
            let errorMessage = "Impossible de mettre à jour la liste de vos messages, vérifiez votre connexion"
            dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          }
        })
      } else {
        console.log('NotificationList.js -> Impossible de mettre à jour les pastilles de notification')
        let errorMessage = "Impossible de mettre à jour la liste de vos messages, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
    let nextPage = "/Comments/" + item.notificationPost 
    navigate (nextPage)
  }

  function DateFromNow(item) {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    return(dayjs(item.notificationTimestamp,"YYYY-MM-DD HH:mm:ss").fromNow())
  }

  function NotificationText(item) {
    let notificationText = ""
    if (item.notificationType === "PostInSubscribedSpot") {
      notificationText =item.authorNickname + ' a publié dans un lieu que vous suivez'
    } else if (item.notificationType === "CommentOnMyPost") {
      notificationText = item.authorNickname + ' a commenté une de vos publications.'
    } else if (item.notificationType === "LikeOnMyPost") {
      notificationText = item.authorNickname + ' a aimé une de vos publications.'
    } else if (item.notificationType === "LikeOnMyComment") {
      notificationText = item.authorNickname + ' a aimé un de vos commentaires.'
    } else if (item.notificationType === "CommentOnMyComment") {
      notificationText = item.authorNickname + ' a commenté un post que vous avez commenté.'
    } else if (item.notificationType === "NearbyMark") {
      notificationText = item.authorNickname + ' a signalé un évènement proche de vous.'
    } else if (item.notificationType === "NearbyPost") {
      notificationText = item.authorNickname + ' a publié dans un lieu proche de vous.'
    }
    
    if (item.notificationRead === false) {
      return(
        <Typography fontWeight={'bold'}>
          {notificationText}
        </Typography>
      )
    } else {
      return(
        <Typography>
          {notificationText}
        </Typography>
      )
    }
  }

  function NotificationAvatar(item) {
    if (item.authorAvatarurl) {
      return (
        <Avatar
          src={item.authorAvatarurl}  
          sx={{ width: 50, height: 50 }}
        />
      )
    } else {
      return (
        <AccountCircleIcon color="primary" aria-label="upload picture" sx={{ fontSize: 50 }} />
      );
    }
  };
  
  return (
    <Slide direction="up"  in={true} timeout={200}>
      <Box sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, zIndex : 1100}}>
        {/* Fond grisé qui renvoie vers la page de la carte*/}
        <Box
          onClick = {() => {navigate("/MapPage")}} 
          sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, backgroundColor : "grey", opacity : 0.5}}
        />
        <Paper sx={{position : "absolute", top : {xs :80, md : 90}, bottom : 20, left : 20, right : 20, backgroundColor : "white", borderRadius : 1}} elevation={24}>
          {/* Croix pour fermer la fenêtre */}
          <Box sx={{position : "absolute", top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => {navigate("/MapPage")}}>
            <IconButton color="secondary">
              <HighlightOffIcon fontSize = "large"/>
            </IconButton>
          </Box>
          <Box sx={{
            display : "flex",
            flexDirection : "column",
            height : "100%",
            width : "100%"
            }}
          >
            {/* La boite du dessus du CircularProgress doit être à un zIndex supérieur*/}
            <Box sx={{display : "flex", width : "100%", padding : 2, zIndex : 1001}}>
              <Typography variant="h5" fontWeight = "bold" color="primary">
                Mes notifications
              </Typography>
            </Box>
            {/* Section avec la référence de la zone scrollable */}
            <Box sx={{display : "flex", position : "relative", flex : 1, flexDirection : "column", width : "100%"}} overflow = "auto" ref={listRef}>
              {/* Définition d'un gradient de couleurs*/}
              <svg width={0} height={0}>
                <defs>
                  <linearGradient id="my_gradient" x1="00%" y1="00%" x2="00%" y2="100%">
                  <stop offset="0%" stopColor="red" />
                   <stop offset="5%" stopColor="orange" />
                    <stop offset="50%" stopColor="yellow" />
                    <stop offset="70%" stopColor="green" />
                    <stop offset="95%" stopColor="blue" />
                    <stop offset="100%" stopColor="purple" />
                  </linearGradient>
                </defs>
              </svg>
              <CircularProgress 
                sx={{
                  position : "absolute",
                  top : -50 + pullChange/5,
                  transition : "top 0.2s linear",
                  // Le CircularProgress doit être à un zIndex jusyte en dessous de l'élément du haut, mais au dessus des élements stardard*/}
                  zIndex : 1000,
                  'svg circle': { stroke: 'url(#my_gradient)' },
                }}
              />
              {notificationList}
            </Box>
          </Box>
        </Paper> 
      </Box>
    </Slide>
  )
}