///////////////////////////////
// DECLARATION DES IMPORTS ////
///////////////////////////////

// React
import { useEffect, useState, useRef, Fragment } from "react";

// dayjs permet de transformer les formats de date et de faire des calculs
import * as dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

// Constantes stockées dans des fichiers externes
import ReportTypesArray from "../Mark/ReportTypesArray";
import BoatTypes from '../Profile/BoatTypes'

// Components des écrans de preview
import VisiblePostPreview from "./VisiblePostPreview"
import SpotPostPreview from "./SpotPostPreview"

// Déclarations Material UI
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import IconButton from '@mui/material/IconButton';
import FaceIcon from '@mui/icons-material/Face';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Paper from '@mui/material/Paper';
import ForumIcon from '@mui/icons-material/Forum';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NearMeIcon from '@mui/icons-material/NearMe';
import NearMeDisabledIcon from '@mui/icons-material/NearMeDisabled';
import CreateIcon from '@mui/icons-material/Create';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip'
import SendIcon from '@mui/icons-material/Send';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import Fab from '@mui/material/Fab';

// Keyframes pour les animations
import { keyframes } from '@emotion/react'

// Fonctions de fetch
import PostFunction from "../API/postFunction";
import GetFunction from "../API/getFunction";
import DeleteFunction from "../API/deleteFunction";
import PatchFunction from '../API/patchFunction';

// Redux et Router
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'


////////////////////////////////
// DECLARATION DES CONSTANTES //
////////////////////////////////

// Récupération des constantes  importées
const boatTypes = BoatTypes()
const reportTypesArray = ReportTypesArray()
  
// Options de la map
const myMapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      { color: '#66b8da' }
    ]
  },{
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      { color: '#ff8500' }
    ]
  },{
    featureType: 'administrative',
    elementType: 'labels.text.stroke',
    stylers: [
      { color: '#ffffff' },
      { weight: 6 }
    ]
  },{
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      { lightness: 100 }
    ]
  },{
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' },
    ]
  }
];

// Icones et couleurs des pin
const iconMouillageURL = "/static/Mouillage.svg"
const iconPortURL = "/static/Port.svg"
const normalPinColor = "#577590"
const normalPinBorderColor = "#577590"
const subscribedPinColor = "#F8961E"
const subscribedPinBorderColor = "#F8961E"
const multipostPinColor = "#43AA8B"
const multipostPinBorderColor = "#43AA8B"

// Ayant vocation à disparaitre
const pinWidth = 45
const pinHeight = 45
const userPinWidth = 25
const userPinHeight = 25

// Taille des pin (coefficient de scale)
const spotPinScale = 2
const userPinScale = 1.2

// Caractéristiques des polygones
const polygonOptionsNormal = {
  /* Cas des polygones visibles par défaut 
  clickable : true,
  strokeOpacity: 0.3,
  fillOpacity: 0.2,
  */

  // Cas des poygones invisibles par défaut
  clickable : false,
  strokeOpacity: 0,
  fillOpacity: 0,
  
  // PAramètres communs
  strokeColor: "#277DA1",
  strokeWeight: 2,
  fillColor: "#277DA1",
  zIndex : 990
}

const polygonOptionsSelected = {
  clickable : true,
  strokeColor: "#277DA1",
  strokeOpacity: 0.6,
  strokeWeight: 3,
  fillColor: "#277DA1",
  fillOpacity: 0.4,
  zIndex : 990
}
const polygonOptionsMultipost = {
  clickable : true,
  strokeColor: "#43AA8B",
  strokeOpacity: 0.6,
  strokeWeight: 3,
  fillColor: "#43AA8B",
  fillOpacity: 0.4,
  zIndex : 990
}
const polygonOptionsDesactivated = {
  clickable : false,
  strokeColor: "grey",
  strokeOpacity: 0.1,
  strokeWeight: 1,
  fillColor: "grey",
  fillOpacity: 0.1,
  zIndex : 990
}

// Tailles possibles de la box visibleSpotFeed
const visibleSpotsFeedBoxProps = {
  openNormalHeight : window.innerHeight/2,
  closedNormalHeight : 50,
  minimumHeight : 10
}

// Tailles possibles de la box markerDetail
const markerDetailBoxProps = {
  minimumHeight : 10,
  spot : {
    small : {
      height : 100,
      width : window.innerWidth*0.9
    },
    big : {
      height : window.innerHeight*0.5,
      width : window.innerWidth*0.9,
    }
  },
  reportMark : {
    small : {
      height : 100,
      width : window.innerWidth*0.9
    },
    big : {
      height : window.innerHeight*0.5,
      width : window.innerWidth*0.9,
    }
  },
  userPosition : {
    small : {
      height : 100,
      width : window.innerWidth*0.9
    },
    big : {
      height : window.innerHeight*0.5,
      width : window.innerWidth*0.9,
    }
  }
}

// Animations standard
const buttonEffect = keyframes`
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.05);
}
100% {
  transform: scale(1);
}`

// Animation Bounce des markers (syntaxe pour intégration dans le styleSheet CSS)
const bounceStyle = document.createElement('style');
// On rajoute le scale car sinon il est écrasé par l'animation
bounceStyle.textContent = `
  @keyframes bounce {
    0% {
      transform: translateY(0) scale(` + spotPinScale + `) ;
    }
    50% {
      transform: translateY(-10px) scale(` + spotPinScale + `) ;
    }
    100% {
      transform: translateY(0) scale(` + spotPinScale + `) ;
    }
  }

  .bounce {
    animation: bounce 0.6s ease-in-out infinite;
  }
`;
document.head.appendChild(bounceStyle);

// Animation Drop des markers (syntaxe pour intégration dans le styleSheet CSS)
const dropStyle = document.createElement('style');
dropStyle.textContent = `
  @keyframes drop {
    0% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .drop {
    animation: drop 0.3s;
  }
`;
document.head.appendChild(dropStyle);

///////////////////////////////////////////////////////
// FONCTIONS DE MANIPULATION DES ELEMENT DES MARKERS //
///////////////////////////////////////////////////////

// Cette syntaxe modifie directement l'élément du DOM : évite de devoir remettre à jour le markerIDsArray pour que les changements soient pris en compte
const addBounceAnimation = (element) => {
  element.content.style.animation = 'bounce 0.5s infinite'
};

const addDropAnimation = (element) => {
  element.content.style.animation = 'drop 0.3s';
};

const removeAnimation = (element) => {
  element.content.style.animation = ''; // Supprimer l'animation
};

const changeMarkerImgSrc = (element, source) => {
  element.content.src = source
};

// Nb maximum de spots où on peut faire un multiPost
const multipostMaxSpots = 10

///////////////////////////////
// DECLARATION DES VARIABLES //
///////////////////////////////
// A toujours laisser avant le export default de début de la fonction du component (sinon, bugs car les variables sont réinitialisées à chaque render)

// Position du centre de la carte et zoom par défaut
let center = {
  lat: 43.3318,
  lng: 5.0550
}
let zoom = 15

let mapOptions = {
  center: center,
  zoom: zoom,
  styles : myMapStyles,
  disableDefaultUI : true,
  clickableIcons : false,
  zIndex : 1000,
  mapId : "NauticTalkMap"
}

// Variables utilisées pour créer les markers
let markerIcon 
let markerImgZoom = 12

// Variables pour la création de polygones
let currentPolyline = null
let currentPolygon = null
let currentPolygonArray = []
let newPolygonIsClosed = false;
let newPolygonMarkersArray = []

// Variable pour le zoomToSearch
let zoomToSearch = null
let newsFeedMultiplePost = false

export default function MapFixedPage() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  dayjs.locale('fr')

  // Récupération du state transmis par Navigate, qui contient un spot si on vient de SearchField ou de SubscribedSpots, ou un paramètre spécial si on fait un post  depuis le newsfeed
  const {state} = useLocation()
  if (state) {
    if (state.zoomToSearch) {
      zoomToSearch = state.zoomToSearch
    }
    if (state.newsFeedMultiplePost) {
      newsFeedMultiplePost = true
    }
  }

  ///////////////////////////////////
  // CONNEXION DES STATES DU STORE //
  ///////////////////////////////////

  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const selectUserPositionsArray = state => state.userPositionsArray
  const userPositionsArray = useSelector(selectUserPositionsArray)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectSubscriptionsArray = state => state.subscriptionsArray
  const subscriptionsArray = useSelector(selectSubscriptionsArray)

  const selectReportMarksArray = state => state.reportMarksArray
  const reportMarksArray = useSelector(selectReportMarksArray)

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const selectChatHeadersArray = state => state.chatHeadersArray
  const chatHeadersArray = useSelector(selectChatHeadersArray)

  const selectDisplayMap = state => state.displayMap
  const displayMap = useSelector(selectDisplayMap)

  const selectDisplayFABs = state => state.displayFABs
  const displayFABs = useSelector(selectDisplayFABs)

  ////////////////////////////
  // DECLARATION DES STATES //
  ////////////////////////////
  
  // Déroulement de l'initialisation
  const [mapInitialisationProgress, setMapInitialisationProgress] = useState({
    mapCreated : false,
    markerLibraryLoaded : false,
    markersCreated : false,
    subscriptionsAdded : false,
    reportMarksCreated : false,
    userPositionsCreated : false
  })

  // Stockage d'une instance de la carte pour pouvoir l'utiliser comme objet
  const [map, setMap] = useState(null)

  // States modifiés par les listeners de la map, et qui servent à déclencher des actions via des useEffect
  const [idleMapState, setIdleMapState] = useState(false)
  const [mapClickedState, setMapClickedState] = useState(false)
  const [dragMapState, setDragMapState] = useState(false)

  // Marker cliqué, sélectionné et précédent sélectionné
  const [clickedMarker, setClickedMarker] = useState(null)
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [previousSelectedMarker, setPreviousSelectedMarker] = useState(null);

  // Stockage des références des markers de la map
  const [markerIDsArray, setMarkerIDsArray] = useState([])
  const [markerPinElementIDsArray, setMarkerPinElementIDsArray] = useState([])
  const [polygonIDsArray, setPolygonIDsArray] = useState([])
  const [userPositionIDsArray, setUserPositionIDsArray] = useState([])
  const [reportMarkIDsArray, setReportMarkIDsArray] = useState([])
  const [newReportMarkID, setNewReportMarkID] = useState(null)
  const [newSpotMarkID, setNewSpotMarkID] = useState(null)

  // State permettant d'afficher l'icône correspondant à l'état de subscription (vrai ou faux)
  const [selectedMarkerSubscribed, setSelectedMarkerSubscribed] = useState(false)

  // State permettant de gérer les subscription en cours de fetch
  const [isSubscribing, setIsSubscribing] = useState(null)

  // Gestion des fonctions accessibles sur la map (création messsage pour spots multiples, création marker, création spot / polygone par l'admin)
  const [newContentState, setNewContentState] = useState({
    isSelectingSpotsForMultipost : false,
    selectedSpots : [],
    isSettingNewReportMark : false,
    newReportMark : null,
    isSettingNewSpot : false,
    newSpotPolygonJSON :null,
    newSpotMarker : null,
    isSharingPosition : null
  })

  // Gestion du visibleSpotsFeed
  const [visibleSpotsState, setVisibleSpotsState] = useState({
    spotsIDsString : "",
    spotsIDsArray : [],
    postsArray : [],
    previousPageLink : null,
    nextPageLink : null,
    isFetchingFeed : false,
    isFetchingNextFeed : false,
    displayFeed : false,
    feed:null
  })
  
  // Gestion du preview du spot
  const [spotFeedPreview, setSpotFeedPreview] = useState({
    isFetching : false,
    feedPreviewContent : null,
    feed : null
  })

  // Position courante
  const[userPositionID, setUserPositionID] = useState(null)

  // Gestion de l'affichage de la snackbar
  const [snackBar, setSnackBar] = useState({
    open : false,
    message : "",
    type : ""
  })

  // Gestion zoomToSearch
  const [zoomToSearchDone, setZoomToSearchDone] = useState(false)

  ////////////////////////////////////////////////
  // RECUPERATION DES DONNEES STOCKEES EN LOCAL //
  ////////////////////////////////////////////////

  // On récupère le center de la carte dans les variables locales
  let centerLocalData = localStorage.getItem('NAUTICTALK_CENTER')
  if (typeof centerLocalData !== 'undefined') {
    let localCenter = JSON.parse(centerLocalData);
    if (localCenter) {
      if (typeof(localCenter) == "object" && typeof(localCenter.lat) == "number" && typeof(localCenter.lng) == "number") {
        center = localCenter
      }
    } 
  }

  ////////////////////////
  // CREATION DE LA MAP //
  ////////////////////////

  // Hook de gestion de la progression de l'initialisation
  useEffect(() => {
    if ((mapInitialisationProgress.mapCreated === false) && (map)) {
      setMapInitialisationProgress(prevState => ({...prevState, mapCreated : true}))
      console.log('MapFixedPage -> NOUVELLE ETAPE FRANCHIE : map créée')
    }
    if ((mapInitialisationProgress.markerLibraryLoaded === false) && (window.google.maps.marker?.AdvancedMarkerElement)) {
      setMapInitialisationProgress(prevState => ({...prevState, markerLibraryLoaded : true}))
      console.log('MapFixedPage -> NOUVELLE ETAPE FRANCHIE : marker library chargée')
    }
  },[mapInitialisationProgress.mapCreated,mapInitialisationProgress.markerLibraryLoaded,map,window.google.maps.marker])



  // On récupère le zoom de la carte dans les variables locales
  let zoomLocalData = localStorage.getItem('NAUTICTALK_ZOOM')
  if (typeof zoomLocalData !== 'undefined') {
    let localZoom = JSON.parse(zoomLocalData);
    if (localZoom) {
      if (typeof(localZoom) == "number") {
        zoom = localZoom
      } 
    }
  }

  // Fonction lancée pour créer la map
  async function initMap() {
    // Création de la map et stockage dans le state
    const { Map } = await window.google.maps.importLibrary("maps");
    let map = new Map(window.document.getElementById("map"), mapOptions);
    // Stockage d'une instance de la map dans le state map
    setMap(map)

    // Chargement du constructeur AdvancedMarkerElement
    window.google.maps.importLibrary("marker");

    // Création des listeners de détection du click, du idle et du drag
    // Attention : les variables dans les listeners ne changent pas de valeur (enclosure lors de la création du listener) :
    // Toujours leur faire modifier un state, et lancer des actions dans un hook qui surveille ce state
    map.addListener("click", (event) => {
      setMapClickedState(event)
    });
    map.addListener("idle", () => {
      setIdleMapState(true)
    });
    map.addListener("dragstart", () => {
      setDragMapState(true)
    });

    // On crée une polyline, qui deviendra le polygone quand elle sera bouclée
    currentPolyline = new window.google.maps.Polyline({ map: map, path: [], strokeColor: "#FF0000", strokeOpacity: 1.0, strokeWeight: 2 });
  }

  // Hook qui lance la création de la map à chaque nouveau mount
  // (il y a nouveau mount au login et au logout)
  useEffect(() => {
    initMap()
  },[])

  // Hook déclenché par le listener "click" de la map
  useEffect(()=> {
    if (mapClickedState) {
      HandleMapClick(mapClickedState)
      setMapClickedState(false)
    }
  },[mapClickedState])
  
  // Hook déclenché par le listener "idle" de la map
  useEffect(()=> {
    if ((mapInitialisationProgress.markersCreated === true) && (idleMapState)) {
      IdleMap()
      setIdleMapState(false)
    }
  },[mapInitialisationProgress.markersCreated, idleMapState])

  // Hook déclenché par le listener "dragstart" de la map
  useEffect(()=> {
    if (dragMapState) {
      HandleMapDrag(dragMapState)
      setDragMapState(false)
    }
  },[dragMapState])
  
  // Hook + state qui importent le contenu du fichier d'icône format SVG pour en faire du SVG en ligne
  const [SVGtextMouillage, setSVGtextMouillage] = useState("")
  const [SVGtextPort, setSVGtextPort] = useState("")

  useEffect(()=> {
    fetch("/static/Mouillage.svg")
    .then((response) => {
      return response.text();
    })
    .then((text) => {
      setSVGtextMouillage(text)
    })
    fetch("/static/Port.svg")
    .then((response) => {
      return response.text();
    })
    .then((text) => {
      setSVGtextPort(text)
    })
  }, []);

  // Fonction qui crée un PinElement
  function CreatePinElement(props) {
    let newSVGText
    let newSVGColor
    let newSVGBorderColor
    // Extraction des paramètres
    const {
      spotType,
      subscribed      
    } = props
    // Définition de l'icône et des couleurs selon les paramètres
    if (spotType == "Mouillage") {
      newSVGText = SVGtextMouillage
    } else {
      newSVGText = SVGtextPort
    }
    if (subscribed === true) {
      newSVGColor = subscribedPinColor
      newSVGBorderColor = subscribedPinBorderColor
    } else {
      newSVGColor = normalPinColor
      newSVGBorderColor = normalPinBorderColor
    }
    // Création du PinElement
    newSVGText = newSVGText.replace(/fill:#577590/gi, "fill:" + newSVGColor).replace("5b5b5b", newSVGColor)
    const div = document.createElement('div');
    div.innerHTML = newSVGText
    const svgElement = div.firstElementChild
    svgElement.style.transform = "scale(1.5) translateY(0px)"
    svgElement.style.transformOrigin = "center"

    return new window.google.maps.marker.PinElement({
      glyph: svgElement,
      background: newSVGColor, // Couleur de fond du Pin
      borderColor: newSVGBorderColor 
    });
  }

  // Hook qui crée les markers quand la map existe, la librairie marker est chargée, les icônes sont chargés et quand le markersArray change
  useEffect(() => {
    if ((mapInitialisationProgress.mapCreated === true) && (mapInitialisationProgress.markerLibraryLoaded === true) && (markersArray) && (SVGtextPort !== "") && (SVGtextMouillage !== "")) {
      // On supprime les markers et polygones éventuellement existants de la carte
      markerIDsArray.forEach(marker => {
        marker.setMap(null)
      })
      polygonIDsArray.forEach(polygon => {
        polygon.setMap(null)
      })
      let newMarkersIDsArray = []
      let newMarkersPinElementIDsArray = []
      let newPolygonsIDsArray = []
      markersArray.forEach((marker, index) => {
        let newPinElement = CreatePinElement({
          spotType : marker.spotType,
          subscribed : ((token) && (subscriptionsArray.findIndex(id => (marker.pk === id.subscriptionSpot)) !== -1))
        })
        newPinElement.element.style.transform = "scale(" + spotPinScale + ")"; // Augmente la taille de 50%
        newPinElement.element.style.transformOrigin = "center bottom";
        let markerOptions = {
          map: map,
          position: {lat:marker.spotLatitude, lng:marker.spotLongitude},
          content : newPinElement.element,
          title : marker.spotName,
          zIndex : 991
        };
        newMarkersIDsArray[index] = new window.google.maps.marker.AdvancedMarkerElement(markerOptions);
        newMarkersPinElementIDsArray[index] = newPinElement
        // Ajout du listener de click
        newMarkersIDsArray[index].addListener("click", () => {
          OnMarkerClicked({marker : marker})
        })
        // Création du polygone clair et stockage de sa référence dans le tableau
        newPolygonsIDsArray[index] = new window.google.maps.Polygon({...polygonOptionsNormal, map : map, paths: JSON.parse(marker.spotPolygon)})
        // Ajout du listener de click
        newPolygonsIDsArray[index].addListener("click", () => {
          OnMarkerClicked({marker : marker})
        })
      })
      // Stockage des références des markers et des polygons dans un state
      setMarkerIDsArray(newMarkersIDsArray)
      setMarkerPinElementIDsArray(newMarkersPinElementIDsArray)
      setPolygonIDsArray(newPolygonsIDsArray)
      // Avancement de l'initialisation
      setMapInitialisationProgress(prevState => ({...prevState, markersCreated : true}))
      console.log('MapFixedPage -> NOUVELLE ETAPE FRANCHIE : markers créés')
    }
  }, [mapInitialisationProgress.mapCreated,mapInitialisationProgress.markerLibraryLoaded,markersArray,SVGtextPort, SVGtextMouillage])

  // Fonction qui renvoie si la date + la durée d'expiration est passée ou pas
  function IsNotExpired(props) {
    var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
    dayjs.extend(isSameOrAfter)
    let expiration = dayjs(props.timestamp).add(props.duration,'h')
    return(dayjs(expiration).isSameOrAfter(dayjs()))
  }
  
  // Hook qui crée les reportMarks quand la map existe, la librairie marker est chargée, et quand le reportMarkersArray change
  useEffect(() => {
    if ((mapInitialisationProgress.mapCreated === true) && (mapInitialisationProgress.markerLibraryLoaded === true) && (reportMarksArray)) {
      // Suppression des reportMarks déjà affichés
      reportMarkIDsArray.forEach(marker => {
        marker.setMap(null)
      })
      let newReportMarksIDsArray = []
      reportMarksArray.forEach((marker, index) => {
        let markerTypeObject = reportTypesArray.find(item => (item.type === marker.reportMarkType))
        // On n'affiche que les reportMarks qui ne sont pas expirés
        let markerDuration = markerTypeObject.hours
        if (IsNotExpired({timestamp : marker.reportMarkTimestamp, duration : markerDuration})) {
          let markerIcon = markerTypeObject.icon
          // Nouvelle syntaxe pour mettre une image en tant que glyphe dans un marker
          let markerImg = document.createElement('img');
          markerImg.src = markerIcon.url
          markerImg.style.width = '60px';
          markerImg.style.height = '60px';
          let markerOptions = {
            map: map,
            position: {lat:marker.reportMarkLatitude, lng:marker.reportMarkLongitude},
            content : markerImg,
            title : markerTypeObject.type,
            zIndex : 991
          };
          newReportMarksIDsArray[index] = new window.google.maps.marker.AdvancedMarkerElement(markerOptions);
          // Ajout du listener de click
          newReportMarksIDsArray[index].addListener("click", () => {
            OnMarkerClicked({marker : marker})
          })
        }
      })
      // Stockage des références des reportMark dans un state
      setReportMarkIDsArray(newReportMarksIDsArray)

      // Avancement de l'initialisation
      setMapInitialisationProgress(prevState => ({...prevState, reportMarksCreated : true}))
      console.log('MapFixedPage -> NOUVELLE ETAPE FRANCHIE : reportMarks créés')
    }
  }, [mapInitialisationProgress.mapCreated,mapInitialisationProgress.markerLibraryLoaded,reportMarksArray])

  // Hook qui crée les userPositions quand la map existe, la librairie marker est chargée, et quand le userPositionsArray change
  // Prend aussi en compte le paramètre userPosition Share
  useEffect(() => {
    if ((mapInitialisationProgress.mapCreated === true) && (mapInitialisationProgress.markerLibraryLoaded === true) && (userPositionsArray)) {
      // On supprime les markers éventuellement existants de la carte
      userPositionIDsArray.forEach(marker => {
        marker.setMap(null)
      })
      // Si userPositionShare est activé : on affiche les positions sur la carte
      if (myProfile.userPositionShare) {
        let newUserPositionIDsArray = []
        userPositionsArray.forEach((marker, index) => {
          if (marker.userPositionUser === myProfile.pk) {
            markerIcon = {url : marker.userPositionMapIcon.substr(0,marker.userPositionMapIcon.length-4)+"-me.svg", scaledSize : {width:userPinWidth, height:userPinHeight}, anchor : new window.google.maps.Point(userPinWidth/2,userPinHeight/2)}
          } else {
            markerIcon = {url : marker.userPositionMapIcon, scaledSize : {width:userPinWidth, height:userPinHeight}, anchor : new window.google.maps.Point(userPinWidth/2,userPinHeight/2)}
          }
          // Nouvelle syntaxe pour mettre une image en tant que glyphe dans un marker
          let markerImg = document.createElement('img');
          markerImg.src = markerIcon.url
          markerImg.style.width = '60px';
          markerImg.style.height = '60px';
          let markerOptions = {
            map: map,
            position: {lat:marker.userPositionLatitude, lng:marker.userPositionLongitude},
            content : markerImg,
            title : marker.userPositionNickname,
            zIndex : 992
          };
          // Création du marker et stockage de sa référence dans le tableau
          newUserPositionIDsArray[index] = new window.google.maps.marker.AdvancedMarkerElement(markerOptions);
          // Ajout du listener de click, selon que l'utilisateur est connecté ou pas
          if (token) {
            newUserPositionIDsArray[index].addListener("click", () => {
              OnMarkerClicked({marker : marker})
            })
          } else {
            newUserPositionIDsArray[index].addListener("click", () => {
              navigate("/login")
            })
          }
        })
        // Stockage des références des markers dans un state
        setUserPositionIDsArray(newUserPositionIDsArray)

        // Avancement de l'initialisation
        setMapInitialisationProgress(prevState => ({...prevState, userPositionsCreated : true}))
        console.log('MapFixedPage -> NOUVELLE ETAPE FRANCHIE : userPositions créés')
      }
    }
  }, [mapInitialisationProgress.mapCreated,mapInitialisationProgress.markerLibraryLoaded,userPositionsArray, myProfile.userPositionShare])

  // Fonction déclenchée quand la carte devient Idle (donc après un mouvement)
  // Stockage dans les variables locales du centre et du zoom quand l'utilisateur navigue à la main
  // On ne stocke qu'à la fin du mouvement pour ne pas faire un refresh à chaque mouvement élémentaire
  // On lance aussi la sélection du marker après un ZoomToSearch
  // On lance la recherche des posts des spots affichés
  function IdleMap() {
    // Enregistrement du center et du zoom de la carte
    let newCenter = map.getCenter()
    localStorage.setItem('NAUTICTALK_CENTER', JSON.stringify(newCenter));
    let newZoom = map.getZoom() 
    localStorage.setItem('NAUTICTALK_ZOOM', JSON.stringify(newZoom));
    // Déclenche le MarkerClicked si l'évènement Idle fait suite à un ZoomToSearch terminé
    if (zoomToSearchDone) {
      setClickedMarker(zoomToSearchDone)
      setZoomToSearchDone(null)
    }
    // Construction de la liste des spots visibles, si on est dans l'écran MapPage
    if ((displayMap) && (markersArray)){
      let spotsIDsString = ""
      let spotsIDsArray = []
      let bounds = map.getBounds()
      for (var i=0; i<markersArray.length; i++) {
        // On récupère le LatLng de la position du market et vérifie s'il est inclus dans le bounds
        if (bounds.contains(markerIDsArray[i].position) === true) {
          // Pour chaque spot dans le bounds, on l'ajoute dans une chaine de caractère séparés par une virgule
          if (spotsIDsString === "") {
            spotsIDsString = markersArray[i].pk.toString()
          } else {
            spotsIDsString = spotsIDsString + "," + markersArray[i].pk.toString()
          }
          spotsIDsArray.push(markersArray[i].pk)
        }
      }
      
      // On réinitialise le state et on lance la récupération des premiers posts de la liste de spots
      setVisibleSpotsState(prevState => ({...prevState, 
        spotsIDsString : spotsIDsString,
        spotsIDsArray : spotsIDsArray,
        postsArray : []
      }))
      if (spotsIDsString !== "") {
        FetchNextVisibleSpotsPosts(spotsIDsString,"Page 1")
      } else {
        setVisibleSpotsState(prevState => ({...prevState,
          postsArray : [],
          previousPageLink : null,
          nextPageLink : null
        })) 
      }
    }
  }

  const visibleSpotsStateDisplayFeedRef = useRef(visibleSpotsState.displayFeed);// Ref pour le drag du visibleFeed

  useEffect(() => {
    visibleSpotsStateDisplayFeedRef.current = visibleSpotsState.displayFeed;
  }, [visibleSpotsState.displayFeed]);

  ////////////////////////////////////////////////////////////
  // CAS D'AFFICHAGE POUR ZOOMTOSEARCH OU POUR MULTIPLEPOST //
  ////////////////////////////////////////////////////////////

  // Hook qui lance le zoom si un spot est mis dans le state du useLocation (quand on vient depûis le champs de recherche ou l'écran SubscribedSpots)
  useEffect(() => {
    if ((map) && (zoomToSearch)) {
      ZoomToSearch()
    }
  }, [map,zoomToSearch])
  
  // Hook qui lance une création de multiplePost si le paramètre a été trouvé dans le state du useLocation (quand on vient depûis le newsFeed)
  useEffect(() => {
    if ((map) && (newsFeedMultiplePost === true)) {
      newsFeedMultiplePost = false
      StartSpotsSelection()
    }
  }, [map,newsFeedMultiplePost])
  
  
  /////////////////////////////////
  // GESTION DU CLICK SUR MARKER //
  /////////////////////////////////

  // Hook lancé quand on a cliqué sur un marqueur
  useEffect(() => {
    if (clickedMarker) {
      // On ferme le visibleSpotsFeed
      CloseVisibleSpotsFeed()

      // Choix de l'action en fonction du type de marker : spot, reportMark ou userPosition
      if (clickedMarker.hasOwnProperty('spotType')) { // On a cliqué sur un spot
        // Action différente selon qu'on est dans l'écran de post collectif, de signalemment ou l'écran normal
        if (newContentState.isSelectingSpotsForMultipost) {
          OnMarkerClickedForNewMultiplePost()
        } else if (newContentState.isSettingNewReportMark) {
        } else {
          OnMarkerClickedForDisplay()
        }
      } else if (clickedMarker.hasOwnProperty('reportMarkType')) { // On a cliqué sur un reportMark
        if (newContentState.isSelectingSpotsForMultipost) {
        } else if (newContentState.isSettingNewReportMark) {
        } else {
          OnReportMarkClickedForDisplay()
        }
      } else if (clickedMarker.hasOwnProperty('userPositionUser')) { // On a cliqué sur un userPosition
        if (newContentState.isSelectingSpotsForMultipost) {
        } else if (newContentState.isSettingNewReportMark) {
        } else {
          OnUserPositionClickedForDisplay()
        }
      }
    }
  }, [clickedMarker])

  function RemoveAnimationAndPolygon(props) {
    if (props.hasOwnProperty('spotType')) {
      let markerIndex = markersArray.findIndex(marker => (marker.pk === props.pk))
      removeAnimation(markerIDsArray[markerIndex])
      polygonIDsArray[markerIndex].setOptions(polygonOptionsNormal)
    } else if (props.hasOwnProperty('reportMarkType')){
      let markerIndex = reportMarksArray.findIndex(marker => (marker.pk === props.pk))
      removeAnimation(reportMarkIDsArray[markerIndex])
    } else if (props.hasOwnProperty('userPositionUser')){
      let markerIndex = userPositionsArray.findIndex(marker => (marker.pk === props.pk))
      removeAnimation(userPositionIDsArray[markerIndex])
    }
  }

  function UnSelectMarker() {
    // On supprime l'animation et le polygon du marker
    RemoveAnimationAndPolygon(selectedMarker)
    setClickedMarker(null)
    setSelectedMarker(null)
    setPreviousSelectedMarker(null)
    // On ferme le markerDetail s'il était ouvert
    setMarkerDetailBoxState(prevState => ({
      ...prevState,
        startPoint : null,
        boxHeight : 0,
        boxWidth : window.innerWidth,
        startBoxHeight : 0,
        heightTransition : "0.3s ease-out",
        isDragging : false,
        size : "small"
    }))
  }

  function OnMarkerClicked(props) {
    // Fermeture de l'affichage du visible SpotsFeed
    CloseVisibleSpotsFeed()
    // Si on n'est pas en traind de creér un nouveau spot, on déclenche l'action suivente en positionnant le marqueur cliqué, ce qui déclenchera le hook qui suit
    if (newContentState.isSettingNewSpot === false) {
    setClickedMarker(props.marker)
    }
  }

  function OnMarkerClickedForDisplay() {
    // On suppprime les indicateurs d'avancement et messages issus d'un marker précédemment cliqué
    map.panTo({
      lat: clickedMarker.spotLatitude-1/Math.pow(2,(map.getZoom()-7)),
      lng: clickedMarker.spotLongitude
    })
    // On met à jour le state selectedMarker
    if (clickedMarker.spotType === "Port") {
      markerImgZoom = 15
    } else if (clickedMarker.spotType === "Mouillage") {
      markerImgZoom = 12
    }
    setSelectedMarker({...clickedMarker, markerImgZoom : markerImgZoom})
    // On met à jour le state selectedMarkerSubscribed
    if (token) {
      if (subscriptionsArray.findIndex(id => (clickedMarker.pk === id.subscriptionSpot)) !== -1) {
        setSelectedMarkerSubscribed(true)
      } else {
        setSelectedMarkerSubscribed(false)
      }
    } else {
      setSelectedMarkerSubscribed(false)
    }
    // On annule l'animation et le polygon du marker précédent éventuel
    if (previousSelectedMarker) {
      RemoveAnimationAndPolygon(previousSelectedMarker)
    }
    // On anime le marker et on rend son polygon foncé
    let markerIndex = markersArray.findIndex(marker => (marker.pk === clickedMarker.pk))
    addBounceAnimation(markerIDsArray[markerIndex])

    polygonIDsArray[markerIndex].setOptions(polygonOptionsSelected)

    // On stocke le nouveau marker qui sera, la prochaine fois, le "marker précédent"
    setPreviousSelectedMarker(clickedMarker)

    // On lance la récupération du SpotFeedPreview
    LoadFeedPreviewContent(clickedMarker.pk)

    // Mise à jour du marketTypeRef pour le drag du markerDetail
    // On met dans le markerTypeRef le type de marker
    markerTypeRef.current = 'spot'
    // On met à jour le state, à la taille small
    setMarkerDetailBoxState(prevState => ({...prevState,
      boxHeight : markerDetailBoxProps[markerTypeRef.current].small.height,
      boxWidth : markerDetailBoxProps[markerTypeRef.current].small.width,
      startBoxHeight : markerDetailBoxProps[markerTypeRef.current].small.height,
      size : "small"
    }))
  }

  function OnMarkerClickedForNewMultiplePost() {
    // On met à jour le tableau des markers sélectionnés
    let spotIndex = newContentState.selectedSpots.findIndex(item => (item.pk === clickedMarker.pk))
    let markerIndex = markersArray.findIndex(item => (item.pk === clickedMarker.pk))
    if (spotIndex === -1) {
      if (newContentState.selectedSpots.length < multipostMaxSpots) {
        let newSelectedSpots = newContentState.selectedSpots
        newSelectedSpots.push(clickedMarker)
        setNewContentState(prevState => ({...prevState, selectedSpots : newSelectedSpots}))
        addBounceAnimation(markerIDsArray[markerIndex])
        if (clickedMarker.spotType === "Mouillage") {
          markerIcon = {url : "/static/Mouillage-Multipost.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        } else {
          markerIcon = {url : "/static/Port-Multipost.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        }  
        changeMarkerImgSrc(markerIDsArray[markerIndex], markerIcon.url)
        polygonIDsArray[markerIndex].setOptions(polygonOptionsMultipost)
      }
    } else {
      let selectedSpots = newContentState.selectedSpots
      let newSelectedSpots = selectedSpots.filter(item => (item.pk !== clickedMarker.pk)) // On fait le filter, et on récupère la valeur de l'élement sorti (mais on ne s'en servira pas)
      setNewContentState(prevState => ({...prevState, selectedSpots : newSelectedSpots}))
      // Restauration de l'icone d'origine
      if (token) {
        if (subscriptionsArray.findIndex(id => (clickedMarker.pk === id.subscriptionSpot)) !== -1) {
          if (clickedMarker.spotType === "Mouillage") {
            markerIcon = {url : "/static/Mouillage-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
          } else {
            markerIcon = {url : "/static/Port-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
          }  
        } else {
          if (clickedMarker.spotType === "Mouillage") {
            markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
          } else {
            markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
          }  
        }
      } else {
        if (clickedMarker.spotType === "Mouillage") {
          markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        } else {
          markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        }  
      }
      changeMarkerImgSrc(markerIDsArray[markerIndex], markerIcon.url)
      removeAnimation(markerIDsArray[markerIndex])
      polygonIDsArray[markerIndex].setOptions(polygonOptionsNormal)
    }
    setClickedMarker(null)
  }

  function OnReportMarkClickedForDisplay() {
    // On suppprime les indicateurs d'avancement et messages issus d'un marker précédemment cliqué
    map.panTo({
      lat: clickedMarker.reportMarkLatitude-1/Math.pow(2,(map.getZoom()-7)),
      lng: clickedMarker.reportMarkLongitude
    })
    let markerTypeObject = reportTypesArray.find(item => (item.type === clickedMarker.reportMarkType))
    let markerIcon = markerTypeObject.icon
    let markerLabel = markerTypeObject.label
    // On met à jour le state selectedMarker
    setSelectedMarker({...clickedMarker, icon : markerIcon, label : markerLabel})
    // On annule l'animation et le polygon du marker précédent éventuel
    if (previousSelectedMarker) {
      RemoveAnimationAndPolygon(previousSelectedMarker)
    }
    // On anime le marker
    let reportMarkIndex = reportMarksArray.findIndex(marker => (marker.pk === clickedMarker.pk))
    addBounceAnimation(reportMarkIDsArray[reportMarkIndex])
    // On stocke le nouveau marker qui sera, la prochaione fois, le "marker précédent"
    setPreviousSelectedMarker(clickedMarker)
    // Mise à jour du marketTypeRef pour le drag du markerDetail
    // On met dans le markerTypeRef le type de marker
    markerTypeRef.current = 'reportMark'
    // On met à jour le state, à la taille small
    setMarkerDetailBoxState(prevState => ({...prevState,
      boxHeight : markerDetailBoxProps[markerTypeRef.current].small.height,
      boxWidth : markerDetailBoxProps[markerTypeRef.current].small.width,
      startBoxHeight : markerDetailBoxProps[markerTypeRef.current].small.height,
      size : "small"
    }))
  }

  function OnUserPositionClickedForDisplay() {
    // On suppprime les indicateurs d'avancement et messages issus d'un marker précédemment cliqué
    map.panTo({
      lat: clickedMarker.userPositionLatitude-1/Math.pow(2,(map.getZoom()-7)),
      lng: clickedMarker.userPositionLongitude
    })
    setSelectedMarker(clickedMarker)
    // On annule l'animation et le polygon du marker précédent éventuel
    if (previousSelectedMarker) {
      RemoveAnimationAndPolygon(previousSelectedMarker)
    }
    // On anime le marker
    let userPositionIndex = userPositionsArray.findIndex(marker => (marker.pk === clickedMarker.pk))
    addBounceAnimation(userPositionIDsArray[userPositionIndex])
    // On stocke le nouveau marker qui sera, la prochaione fois, le "marker précédent"
    setPreviousSelectedMarker(clickedMarker)
    // Mise à jour du marketTypeRef pour le drag du markerDetail
    // On met dans le markerTypeRef le type de marker
    markerTypeRef.current = 'userPosition'
    // On met à jour le state, à la taille small
    setMarkerDetailBoxState(prevState => ({...prevState,
      boxHeight : markerDetailBoxProps[markerTypeRef.current].small.height,
      boxWidth : markerDetailBoxProps[markerTypeRef.current].small.width,
      startBoxHeight : markerDetailBoxProps[markerTypeRef.current].small.height,
      size : "small"
    }))
  }


  ////////////////////////////////
  // DRAGGABLE VISIBLESPOTSFEED //
  ////////////////////////////////

  // States utilisés pour le drag
  const [visibleSpotsFeedBoxState, setVisibleSpotsFeedBoxState] = useState({
    startPoint : null,
    boxHeight : visibleSpotsFeedBoxProps.closedNormalHeight,
    startBoxHeight : visibleSpotsFeedBoxProps.closedNormalHeight,
    heightTransition : "0.3s ease-out",
    isDragging : false
  })

  // Références utilisées pour passer la valeur des states aux listeners (qui font une closure des variables donc ne voient pas les mises à jour des variables et des states)
  const visibleSpotsFeedStartPointRef = useRef();
  const visibleSpotsFeedBoxHeightRef = useRef();
  const visibleSpotsFeedStartBoxHeightRef = useRef();

  // Hooks qui mettent automatiquement à jour les refs quand les valeurs du state changent
  useEffect(() => {
    visibleSpotsFeedStartPointRef.current = visibleSpotsFeedBoxState.startPoint;
  }, [visibleSpotsFeedBoxState.startPoint]);

  useEffect(() => {
    visibleSpotsFeedStartBoxHeightRef.current = visibleSpotsFeedBoxState.startBoxHeight;
  }, [visibleSpotsFeedBoxState.startBoxHeight]);

  useEffect(() => {
    visibleSpotsFeedBoxHeightRef.current = visibleSpotsFeedBoxState.boxHeight;
  }, [visibleSpotsFeedBoxState.boxHeight]);

  // Fonction pour gérer le pullStart
  const visibleSpotsFeedPullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setVisibleSpotsFeedBoxState(prevState => ({
      ...prevState,
      startPoint : screenY,
      startBoxHeight : visibleSpotsFeedBoxHeightRef.current,
      heightTransition : "0.01s linear",
      isDragging : true
    }))
  }

  // Fonction pour gérer le pullMove dans la zone de titre
  const visibleSpotsFeedHeaderPullMove = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = visibleSpotsFeedStartPointRef.current - screenY;

    setVisibleSpotsFeedBoxState(prevState => ({
      ...prevState,
      boxHeight : Math.min(visibleSpotsFeedBoxProps.openNormalHeight + 0.3*Math.max(0,(visibleSpotsFeedStartBoxHeightRef.current+pullLength)-visibleSpotsFeedBoxProps.openNormalHeight), Math.max(visibleSpotsFeedBoxProps.minimumHeight,visibleSpotsFeedStartBoxHeightRef.current + pullLength)),
    }))
  };
  
  // Fonction pour gérer le pullMove dans la zone de liste
  const visibleSpotsListPullMove = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = visibleSpotsFeedStartPointRef.current - screenY;

    // Uniquement si la box est ouverte
    if (visibleSpotsStateDisplayFeedRef.current === true) {
      // Si on est au début ou à la fin du scroll, on déplace la Box
      const visibleSpotsList = visibleSpotsListRef.current;
      const scrollTop = visibleSpotsList.scrollTop;

      if (scrollTop === 0 && pullLength < 0) {
        setVisibleSpotsFeedBoxState(prevState => ({
          ...prevState,
          boxHeight : Math.min(visibleSpotsFeedBoxProps.openNormalHeight + 0.3*Math.max(0,(visibleSpotsFeedStartBoxHeightRef.current+pullLength)-visibleSpotsFeedBoxProps.openNormalHeight), Math.max(visibleSpotsFeedBoxProps.minimumHeight,visibleSpotsFeedStartBoxHeightRef.current + pullLength)),
        }))
        // On empêche le scroll par défaut
        e.preventDefault();
      }
    } else {
      setVisibleSpotsFeedBoxState(prevState => ({
        ...prevState,
        boxHeight : Math.min(visibleSpotsFeedBoxProps.openNormalHeight + 0.3*Math.max(0,(visibleSpotsFeedStartBoxHeightRef.current+pullLength)-visibleSpotsFeedBoxProps.openNormalHeight), Math.max(visibleSpotsFeedBoxProps.minimumHeight,visibleSpotsFeedStartBoxHeightRef.current + pullLength)),
      }))
    }
  };

  // Fonction pour gérer le pullEnd
  const visibleSpotsFeedPullEnd = (e) => {
    setVisibleSpotsFeedBoxState(prevState => ({
      ...prevState,
      heightTransition : "0.3s ease-out",
      isDragging : false
    }))
    if (visibleSpotsStateDisplayFeedRef.current === true) {
      if (visibleSpotsFeedBoxHeightRef.current < visibleSpotsFeedBoxProps.openNormalHeight - 10) {
        CloseVisibleSpotsFeed()
      } else {
        OpenVisibleSpotsFeed()
      }
    } else {
      if (visibleSpotsFeedBoxHeightRef.current > visibleSpotsFeedBoxProps.closedNormalHeight + 10) {
        OpenVisibleSpotsFeed()
      } else {
        CloseVisibleSpotsFeed()
      }
    }
  };

  function CloseVisibleSpotsFeed() {
    setVisibleSpotsFeedBoxState(prevState => ({
      ...prevState,
      boxHeight : visibleSpotsFeedBoxProps.closedNormalHeight
    }))
    setVisibleSpotsState(prevState => ({...prevState, displayFeed : false}))
  }

  function OpenVisibleSpotsFeed() {
    setVisibleSpotsFeedBoxState(prevState => ({
      ...prevState,
      boxHeight : visibleSpotsFeedBoxProps.openNormalHeight
    }))
    setVisibleSpotsState(prevState => ({...prevState, displayFeed : true}))
  }

  // Mise en place des event listeners

  const visibleSpotsRef = useRef(null); // Pour gérer le drag
  const visibleSpotsHeaderRef = useRef(null); // Pour gérer le drag
  const visibleSpotsListRef = useRef(null); // POur gérer le scroll

  useEffect(() => {
    if (visibleSpotsRef.current) {
      // On supprime d'éventuels listeners avant d'en rajouter
      // (l'utilisation du Callback pour faire la suppression ne fonctionne pas quand on change d'écrans)
      visibleSpotsRef.current.removeEventListener("touchstart", visibleSpotsFeedPullStart, {passive : true});
      visibleSpotsRef.current.removeEventListener("touchmove", visibleSpotsListPullMove, {passive : false}); // Il faut mettre passive à false pour permettre le prevent Default
      visibleSpotsRef.current.removeEventListener("touchend", visibleSpotsFeedPullEnd, {passive : true});

      visibleSpotsRef.current.addEventListener("touchstart", visibleSpotsFeedPullStart, {passive : true});
      visibleSpotsRef.current.addEventListener("touchmove", visibleSpotsListPullMove, {passive : false});
      visibleSpotsRef.current.addEventListener("touchend", visibleSpotsFeedPullEnd, {passive : true});
    }
  },[visibleSpotsRef.current]);
    
  useEffect(() => {
    if (visibleSpotsHeaderRef.current) {
      // On supprime d'éventuels listeners avant d'en rajouter
      // (l'utilisation du Callback pour faire la suppression ne fonctionne pas quand on change d'écrans)
      visibleSpotsHeaderRef.current.removeEventListener("touchstart", visibleSpotsFeedPullStart, {passive : true});
      visibleSpotsHeaderRef.current.removeEventListener("touchmove", visibleSpotsFeedHeaderPullMove, {passive : true}); // Il faut mettre passive à false pour permettre le prevent Default
      visibleSpotsHeaderRef.current.removeEventListener("touchend", visibleSpotsFeedPullEnd, {passive : true});

      visibleSpotsHeaderRef.current.addEventListener("touchstart", visibleSpotsFeedPullStart, {passive : true});
      visibleSpotsHeaderRef.current.addEventListener("touchmove", visibleSpotsFeedHeaderPullMove, {passive : true});
      visibleSpotsHeaderRef.current.addEventListener("touchend", visibleSpotsFeedPullEnd, {passive : true});
    }
  },[visibleSpotsHeaderRef.current]);
    
  ////////////////////////////
  // DRAGGABLE MARKERDETAIL //
  ////////////////////////////

  // States utilisés pour le drag
  const [markerDetailBoxState, setMarkerDetailBoxState] = useState({
    startPoint : null,
    boxHeight : 0,
    startBoxHeight : 0,
    boxWidth : window.innerWidth*0.9,
    heightTransition : "0.3s ease-out",
    isDragging : false,
    size : "small"
  })

  // Références utilisées pour passer la valeur des states aux listeners (qui font une closure des variables donc ne voient pas les mises à jour des variables et des states)
  const markerDetailStartPointRef = useRef();
  const markerDetailBoxHeightRef = useRef();
  const markerDetailStartBoxHeightRef = useRef();
  const markerDetailSizeRef = useRef();
  // On a besoin de connaitre le type de marker
  const markerTypeRef = useRef();

  // Hooks qui mettent automatiquement à jour les refs quand les valeurs du state changent
  useEffect(() => {
    markerDetailStartPointRef.current = markerDetailBoxState.startPoint;
    markerDetailStartBoxHeightRef.current = markerDetailBoxState.startBoxHeight;
    markerDetailBoxHeightRef.current = markerDetailBoxState.boxHeight;
    markerDetailSizeRef.current = markerDetailBoxState.size;
  }, [markerDetailBoxState]);

  // Fonction pour gérer le pullStart
  const markerDetailPullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setMarkerDetailBoxState(prevState => ({
      ...prevState,
      startPoint : screenY,
      startBoxHeight : markerDetailBoxHeightRef.current,
      heightTransition : "0.01s linear",
      isDragging : true
    }))
  }

  // Fonction pour gérer le pullMove dans la zone de titre
  const markerDetailHeaderPullMove = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = markerDetailStartPointRef.current - screenY;

    setMarkerDetailBoxState(prevState => ({
      ...prevState,
      // On détermine la hauteur lax en fonction du type de marker
      boxHeight : Math.min(markerDetailBoxProps[markerTypeRef.current].big.height + 0.3*Math.max(0,(markerDetailStartBoxHeightRef.current+pullLength)-markerDetailBoxProps[markerTypeRef.current].big.height), Math.max(markerDetailBoxProps.minimumHeight,markerDetailStartBoxHeightRef.current + pullLength)),
    }))
  };

  // Fonction pour gérer le pullMove dans la zone de liste
  const markerDetailListPullMove = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = markerDetailStartPointRef.current - screenY;

    // Si on est au début ou à la fin du scroll, on déplace la Box
    const markerDetailList = markerDetailListRef.current;
    const scrollTop = markerDetailList.scrollTop;

    if (scrollTop === 0 && pullLength < 0) {
      setMarkerDetailBoxState(prevState => ({
        ...prevState,
        boxHeight : Math.min(markerDetailBoxProps[markerTypeRef.current].big.height + 0.3*Math.max(0,(markerDetailStartBoxHeightRef.current+pullLength)-markerDetailBoxProps[markerTypeRef.current].big.height), Math.max(markerDetailBoxProps.minimumHeight,markerDetailStartBoxHeightRef.current + pullLength)),
      }))
      // On empêche le scroll par défaut
      e.preventDefault();
    }
  };

  // Fonction pour gérer le pullEnd
  const markerDetailPullEnd = (e) => {
    setMarkerDetailBoxState(prevState => ({
      ...prevState,
      heightTransition : "0.3s ease-out",
      startBoxHeight : 0,
      isDragging : false,
    }))
    if (markerDetailSizeRef.current === "big") {
      if (markerDetailBoxHeightRef.current < markerDetailBoxProps[markerTypeRef.current].big.height - 10) {
        ReduceMarkerDetail()
      } else {
        ExtendMarkerDetail()
      }
    } else if (markerDetailSizeRef.current === "small") {
        if (markerDetailBoxHeightRef.current > markerDetailBoxProps[markerTypeRef.current].small.height + 10) {
        ExtendMarkerDetail()
      } else {
        ReduceMarkerDetail()
      }
    }
 };

 function ReduceMarkerDetail() {
  setMarkerDetailBoxState(prevState => ({
    ...prevState,
    boxHeight : markerDetailBoxProps[markerTypeRef.current].small.height,
    boxWidth : markerDetailBoxProps[markerTypeRef.current].small.width,
    size : "small"
  }))
 }

 function ExtendMarkerDetail() {
    setMarkerDetailBoxState(prevState => ({
      ...prevState,
      boxHeight : markerDetailBoxProps[markerTypeRef.current].big.height,
      boxWidth : markerDetailBoxProps[markerTypeRef.current].big.width,
      size : "big"
    }))
 }

 // Mise en place et suppression des event listeners pour gérer le pull-to-refresh

  const markerDetailRef = useRef(null);
  const markerDetailHeaderRef = useRef(null); // Pour gérer le drag
  const markerDetailListRef = useRef(null); // POur gérer le scroll

  useEffect(() => {
    if (markerDetailRef.current) {
      // On supprime d'éventuels listeners avant d'en rajouter
      // (l'utilisation du Callback pour faire la suppression ne fonctionne pas quand on change d'écrans)
      markerDetailRef.current.removeEventListener("touchstart", markerDetailPullStart, {passive : true});
      markerDetailRef.current.removeEventListener("touchmove", markerDetailListPullMove, {passive : false});
      markerDetailRef.current.removeEventListener("touchend", markerDetailPullEnd, {passive : true});

      markerDetailRef.current.addEventListener("touchstart", markerDetailPullStart, {passive : true});
      markerDetailRef.current.addEventListener("touchmove", markerDetailListPullMove, {passive : false});
      markerDetailRef.current.addEventListener("touchend", markerDetailPullEnd, {passive : true});
    }
  },[markerDetailRef.current]);

  useEffect(() => {
    if (markerDetailHeaderRef.current) {
      // On supprime d'éventuels listeners avant d'en rajouter
      // (l'utilisation du Callback pour faire la suppression ne fonctionne pas quand on change d'écrans)
      markerDetailHeaderRef.current.removeEventListener("touchstart", markerDetailPullStart, {passive : true});
      markerDetailHeaderRef.current.removeEventListener("touchmove", markerDetailHeaderPullMove, {passive : true}); // Il faut mettre passive à false pour permettre le prevent Default
      markerDetailHeaderRef.current.removeEventListener("touchend", markerDetailPullEnd, {passive : true});

      markerDetailHeaderRef.current.addEventListener("touchstart", markerDetailPullStart, {passive : true});
      markerDetailHeaderRef.current.addEventListener("touchmove", markerDetailHeaderPullMove, {passive : true});
      markerDetailHeaderRef.current.addEventListener("touchend", markerDetailPullEnd, {passive : true});
    }
  },[markerDetailHeaderRef.current]);


  // Fonction de chargement du preview du feed
  function LoadFeedPreviewContent(spotId) {
    console.log('MapFixedPage.js -> Chargement API spotFeedPreviewContent')
    setSpotFeedPreview(prevState => ({...prevState, isFetching : true})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
    GetFunction({fetchTarget : 'spotFeedPreviewContent', fetchArgument:spotId})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('MapFixedPage.js -> Fin chargement API spotFeedPreviewContent')
        console.log('MapFixedPage.js -> Mise à jour du statut spotFeedPreview')
        setSpotFeedPreview(prevState => ({...prevState, feedPreviewContent : response.data, isFetching : false})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
      } else {
        console.log('MapFixedPage.js -> Impossible de charger le contenu du feed')
        setSpotFeedPreview(isFetching => false) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
        let errorMessage = "Impossible de trouver les conversation de ce lieu, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }
  
  /////////////////////////////
  // SUBSCRIBE / UNSUBSCRIBE //
  /////////////////////////////

  // Fonction qui met à jour le newsFeed quand on subscribre/unsubscribe  
  function LoadNewsFeed() {
    GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('MapFixedPage.js -> Chargement nouveau newsFeedContent dans le state Redux')
        dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
      } else {
        console.log('MapFixedPage.js -> Erreur dans le fetch newsFeedContent')
      }
    })
  }

  function Subscribe() {
    let fetchArgument = {
      'subscriptionUser' : myProfile.pk, //Utilisé pour que le champs soit présent et avec un bon format, mais cet id sera écrasé par le userid dans l'API
      'subscriptionSpot' : selectedMarker.pk
    }
    setIsSubscribing('subscribing')
    // Lancement animation des points gagnés
    console.log('MapFixedPage -> Déclenchement animation points gagnés subscribe')
    dispatch({ type : "TOGGLE_SCOREDPOINTS_ANIMATION", payload : 50})


    PostFunction({fetchTarget : 'postSubscription', fetchArgument : fetchArgument, token : token})
    .then(response => {
      setIsSubscribing(null)
      if(response.fetchStatus === 'Ok') {
        console.log('MapFixedPage.js -> Fin chargement API toggleSubscription')
        console.log('MapFixedPage.js -> Mise à jour icone')
        setSelectedMarkerSubscribed(true)
        console.log('MapFixedPage.js -> Fetch du nouvel état de subscriptionArray')
        GetFunction({fetchTarget : 'subscriptionsArray',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapFixedPage.js -> Chargement nouveau subscriptionsArray dans le state Redux')
            dispatch({ type : "LOAD_SUBSCRIPTIONS_ARRAY", payload:response.data})
          } else {
            console.log('MapFixedPage.js -> Impossible de charger le nouveau subscriptionsArray')
            let errorMessage = "Impossible d'afficher votre nouvel abonnement, vérifiez votre connexion"
            dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          }
          // Récupération du nouveau newsFeed
          GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
          .then((response) => {
            if (response.fetchStatus === 'Ok') {
              console.log('MapFixedPage.js -> Chargement newsFeedContent dans le state Redux')
              dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
            } else {
              console.log('MapFixedPage.js -> Erreur dans le fetch newsFeedContent')
            }
          })
        })
        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapFixedPage.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('MapFixedPage.js -> Réception du profil à jour en échec')
          }
        })

      } else {
        console.log('MapFixedPage.js -> Chargement API toggleSubscription en erreur')
        let errorMessage = "Impossible de prendre en compte votre abonnement, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
    .then(LoadNewsFeed)
  }

  function Unsubscribe() {
    console.log('MapFixedPage.js -> unsubscribe')
    // On va chercher dans la liste des subscriptions la clé primaire de la subscription à supprimer
    let markerPk = subscriptionsArray[subscriptionsArray.findIndex(id => (selectedMarker.pk === id.subscriptionSpot))].pk 
    let fetchArgument = {
      'pk' : markerPk
      }
    setIsSubscribing('unsubscribing')
    DeleteFunction({fetchTarget : 'deleteSubscription', fetchArgument : fetchArgument, token : token})
    .then(response => {
      setIsSubscribing(null)
      if(response.fetchStatus === 'Ok') {
        console.log('MapFixedPage.js -> Fin chargement API toggleSubscription')
        console.log('MapFixedPage.js -> Mise à jour icone')
        setSelectedMarkerSubscribed(false)
        console.log('MapFixedPage.js -> Fetch du nouvel état de subscriptionArray')
        GetFunction({fetchTarget : 'subscriptionsArray', fetchArgument : null, token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapFixedPage.js -> Chargement nouveau subscriptionsArray dans le state Redux')
            dispatch({ type : "LOAD_SUBSCRIPTIONS_ARRAY", payload:response.data})
          } else {
            console.log('MapFixedPage.js -> Impossible de charger le nouveau subscriptionsArray')
            let errorMessage = "Impossible d'afficher votre désabonnement, vérifiez votre connexion"
            dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          }
        })
        // Récupération du nouveau newsFeed
        GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapFixedPage.js -> Chargement newsFeedContent dans le state Redux')
            dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
          } else {
            console.log('MapFixedPage.js -> Erreur dans le fetch newsFeedContent')
          }
        })
        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapFixedPage.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('MapFixedPage.js -> Réception du profil à jour en échec')
          }
        })
      } else {
        console.log('MapFixedPage.js -> Chargement API toggleSubscription en erreur')
        let errorMessage = "Impossible de prendre en compte votre désabonnement, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
    .then(LoadNewsFeed)
  }

  // Fonction qu'il a fallu sortir du OnClick(), sinon il y avait une erreur
  function goToLogin() {
    navigate("/login")
  }

  // On construit le feed dans un state pour éviter qu'il soit généré par une function qui fase de nombreux render avec l'animation
  useEffect(()=> {
    console.log('MapFixedPage -> Construction du spotFeedPreview')
    if (spotFeedPreview.feedPreviewContent) {
      console.log('MapFixedPage -> feedPreviewContent existant à construire')
      setSpotFeedPreview(prevState => ({
        ...prevState, feed : 
          <SpotPostPreview
            onClickFunction={() => {navigate("/Feed/" + selectedMarker.pk)}}
            postlist={spotFeedPreview.feedPreviewContent.results}
          />
      }))
    } else {
      console.log('MapFixedPage -> spotFeedPreview vide')
      setSpotFeedPreview(prevState => ({
        ...prevState, feed : 
          <Typography align="center" variant="body1" color="primary">
            Il n'y a aucune publication dans ce lieu.
          </Typography>
      }))
    }
},[spotFeedPreview.feedPreviewContent])




  function handleVisibleSpotsScroll(e) {
    const bottom = ((e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight + 10);
    if (bottom && !visibleSpotsState.isFetchingNextFeed && visibleSpotsState.nextPageLink) {
      FetchNextVisibleSpotsPosts(visibleSpotsState.spotsIDsString,visibleSpotsState.nextPageLink)
    }
  }

  // Ref pour stocker la position du visibleSpotsFeed avant fetch
  const scrollPositionRef = useRef(0); // Pour enregistrer la position de défilement

  // useEffect pour déclencher le scroll jusqu'à la position initiale

  useEffect(() => {
    if (visibleSpotsState.displayFeed) {
      if (!visibleSpotsState.isFetchingNextFeed && scrollPositionRef.current !== 0) {
        visibleSpotsListRef.current.scrollTop = scrollPositionRef.current
      }
    }
  }, [visibleSpotsState.postsArray]);

  // Fonction lancé pour aller chercher la prochaine page de posts
  function FetchNextVisibleSpotsPosts(spotsIDsString,nextPageUrl) {
    // On sauvegarde la position dans la liste
    if (visibleSpotsState.displayFeed) {
      scrollPositionRef.current = visibleSpotsListRef.current.scrollTop
    }

    // On lance la fonction GetFunction, avec des arguments spécifiques : la liste des spots visibles, et l'url de la prochaine page à envoyer au back-end
    console.log('MapFixedPage.js -> Lancement FetchNextVisibleSpotsPosts')
    setVisibleSpotsState(prevState => ({...prevState,
      isFetchingFeed : nextPageUrl === "Page 1" ? true : false,
      isFetchingNextFeed : nextPageUrl === "Page 1" ? false : true,
    }))
    GetFunction({fetchTarget : 'visibleSpotsFeed',fetchArgument : {visibleSpotsIDs : spotsIDsString, nextPageUrl : ((nextPageUrl === "Page 1") ? "" : nextPageUrl)}})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('MapFixedPage.js -> Chargement postsArray et nextPageLink dans le state')
        setVisibleSpotsState(prevState => ({...prevState,
          postsArray : Array.from(prevState.postsArray).concat(response.data.results), // Comme c'est paginé, il faut récupérer l'objet result
          previousPageLink : response.data.previous,
          nextPageLink : response.data.next
        })) 
        } else {
        console.log('MapFixedPage.js -> Erreur dans FetchNextVisibleSpotsPosts')
      }
      setVisibleSpotsState(prevState => ({...prevState,
        isFetchingFeed : false,
        isFetchingNextFeed : false
      }))
    })
  }

  ////////////////////////////////////////
  // CONSTRUCTION DU VISIBLE SPOTS FEED //
  ////////////////////////////////////////

  // On construit le feed dans un state pour éviter qu'il soit généré par une function qui fase de nombreux render avec l'animation
  useEffect(()=> {
    console.log('MapFixedPage -> Construction du visibleSpotsState')
    if (visibleSpotsState.postsArray.length > 0) {
      console.log('MapFixedPage -> visibleSpotsState existant à construire')
      setVisibleSpotsState(prevState => ({
        ...prevState, feed : 
          <VisiblePostPreview
            postlist={visibleSpotsState.postsArray}
            isFetchingNextFeed = {visibleSpotsState.isFetchingNextFeed}
            nextPageLink = {visibleSpotsState.nextPageLink}
          />
      }))
    } else {
      console.log('MapFixedPage -> visibleSpotsState vide')
      setVisibleSpotsState(prevState => ({
        ...prevState, feed : 
          <Typography align="center" variant="body1" color="primary">
            Il n'y a aucune publication dans cette zone.
          </Typography>
      }))
    }
  },[visibleSpotsState.postsArray])

  // Fonction appelée quand un choix est fait dans la barre de recherche  
  function ZoomToSearch() {
    map.setZoom(12)
    if (!zoomToSearch.hasOwnProperty('reportMarkType')) {
      map.panTo({lat:zoomToSearch.spotLatitude-1/Math.pow(2,(12-7)),lng:zoomToSearch.spotLongitude})
    } else {
      map.panTo({lat:zoomToSearch.reportMarkLatitude-1/Math.pow(2,(12-7)),lng:zoomToSearch.reportMarkLongitude})
    }
    // Si on appelait markerClicked tout de suite, il bloquerait le setCenter : pour attendre
    // que le setCenter soit fait, on passe le marker dans une variable qui sera lue dans
    // la fonction OnIdle()
    setZoomToSearchDone(zoomToSearch)
  }

  function HandleMapClick(event) {
  // Fermeture de l'affichage d'un marker
    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXX MAP CLICKED')
    if (selectedMarker) {
      UnSelectMarker()
    }
    // Fermeture de l'affichage du visible SpotsFeed
    CloseVisibleSpotsFeed()

    // Si on est en train de créer un spot, on positionne le nouveau marker.
    if (newContentState.isSettingNewSpot === true) {
      if (newSpotMarkID) {
        newSpotMarkID.setMap(null)
        setNewSpotMarkID(null)
      } 
      let markerPosition = {lat:event.latLng.lat(), lng:event.latLng.lng()}
      map.panTo({
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      })
      // Nouvelle syntaxe pour mettre une image en tant que glyphe dans un marker
      let markerImg = document.createElement('img');
      markerImg.src = "/static/Mark.svg"
      markerImg.style.width = '60px';
      markerImg.style.height = '60px';
      let markerOptions = {
        map: map,
        position: markerPosition,
        content : markerImg,
        zIndex : 994
      };
      let newMarkerElement = new window.google.maps.marker.AdvancedMarkerElement(markerOptions)
      addDropAnimation(newMarkerElement)
      setNewSpotMarkID(newMarkerElement)
      setNewContentState(prevState => ({...prevState, newSpotMarker : markerPosition,isSettingNewSpot:"CreatePolygon"}))
    }

    // Si on est en train de faire un polygone : on ajoute un marker et on ajoute le listener click du premier
    if (newContentState.isSettingNewSpot === "CreatePolygon") {
      if (!newPolygonIsClosed) {
        // On ajoute un nouveau marker
        var newMarker = new window.google.maps.marker.AdvancedMarkerElement({ map: map, position: event.latLng});
        newPolygonMarkersArray.push(newMarker)
        // On ajoute au premier marker un listener pour qu'il termine le tracé quand il est cliqué
        if (currentPolyline.getPath().length === 0) {
          newMarker.addListener("click", () => {
            if (!newPolygonIsClosed) {
              // Si on a cliqué sur le marqueur du début : on enregistre le tracé du polyline dans un polygone, on efface la polyline et on marque le tracé comme terminré
              setNewContentState(prevState => ({...prevState,
                newSpotPolygonPath : currentPolyline.getPath(),
                newSpotPolygonJSON : JSON.stringify(currentPolygonArray),
                isSettingNewSpot : false
              }))
              // On efface la ligne, on la marque comme fermée, et on la réinitialise
              currentPolygon = new window.google.maps.Polygon({ map: map, path: currentPolyline.getPath(), strokeColor: "#FF0000", strokeOpacity: 0.8, strokeWeight: 2, fillColor: "#FF0000", fillOpacity: 0.35 });
              currentPolyline.setMap(null);
              newPolygonIsClosed = true;
              newPolygonMarkersArray.forEach(marker => {
                marker.setMap(null)
              })
              newPolygonMarkersArray = []
              newSpotMarkID.setMap(null)
              newPolygonIsClosed = false;
              currentPolygon.setMap(null)
              navigate("/MapNewMarker" , {state : {reportMarker : newContentState.newSpotMarker, spotPolygon : JSON.stringify(currentPolygonArray)}})
                      }
          })
        }
        // On ajoute le point à la polyline et, en parallèle, à l'objet qui sera transformé en JSON puis stocké dans la base de données
        currentPolyline.getPath().push(event.latLng);
        currentPolygonArray.push({lat:event.latLng.lat(), lng:event.latLng.lng()})
      }
    }
  }


  function HandleMapDrag() {
    // Fermeture de l'affichage d'un marker
    if (selectedMarker) {
      UnSelectMarker()
    }
  }
  
  // Modification du partage de position
  function UpdateMyProfile(option) {
    console.log('MapFixedPage.js -> Chargement API sendMyProfile')

    const newProfileFormData = new FormData();
    newProfileFormData.append("userPositionShare", option)
    PatchFunction({fetchTarget:'sendMyProfileForm', fetchObjectId:myProfile.pk, fetchArgument: newProfileFormData, token:token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('MapFixedPage.js -> Fin chargement API sendMyProfile')
        // Affichage snakbar
        setSnackBar(prevState => ({...prevState,
          open : true,
          message : (option === true ?
            "Vous partagez votre position. Pas de panique : votre position ne sera pas dévoilée si elle est hors des zones de navigation. Vous pouvez désormais voir les autres utilisateurs sur la carte"
          :
            "Vous ne partagez plus votre position. Vous ne pouvez plus voir les autres utilisateurs sur la carte"
          ),
          type : (option === true ?
            "success"
          :
            "warning"
          )
        }))        
        console.log('MapFixedPage.js -> Fetch du nouveau myProfile')
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapFixedPage.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('MapFixedPage.js -> Réception du profil à jour en échec')
          }
        })
      } else {
        console.log('MapFixedPage.js -> Envoi sendMyProfile en échec')
        let errorMessage = "Impossible d'activer le partage de position. Vérifier votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  
  //////////////////////////////////////////////////////////
  // AFFICHAGE OU MASQUAGE DES FABS ET DU VISIBLESPOTFEED //
  //////////////////////////////////////////////////////////

  const [shouldHideFABs, setShouldHideFABs] = useState(false);
  const [shouldHideVisibleSpotsFeed, setShouldHideVisibleSpotsFeed] = useState(false);

  useEffect(() => {
    if (newContentState.isSelectingSpotsForMultipost || newContentState.isSettingNewReportMark || newContentState.isSettingNewSpot || selectedMarker || visibleSpotsState.displayFeed || visibleSpotsFeedBoxState.isDragging || !displayFABs) {
      setShouldHideFABs(true);
    } else {
      setShouldHideFABs(false);
    }
    if (newContentState.isSelectingSpotsForMultipost || newContentState.isSettingNewReportMark || newContentState.isSettingNewSpot || selectedMarker || !displayFABs) {
      setShouldHideVisibleSpotsFeed(true);
    } else {
      setShouldHideVisibleSpotsFeed(false);
    }
  }, [newContentState, selectedMarker, visibleSpotsState,visibleSpotsFeedBoxState.isDragging, displayFABs]); // Dépendances à surveiller




  function ZoomPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log('MapFixedPage.js -> Nouvelle position acquise')
        if (map) {
          map.setZoom(12)
          map.panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
          if (userPositionID) {
            // On supprime le markers éventuellement existant de la carte
            userPositionID.setMap(null)
          }
          // Nouvelle syntaxe pour mettre une image en tant que glyphe dans un marker
          let markerImg = document.createElement('img');
          markerImg.src = "/static/My-Location-Pin-Me.svg"
          markerImg.style.width = '60px';
          markerImg.style.height = '60px';
          let markerOptions = {
            map: map,
            position: {lat:position.coords.latitude, lng:position.coords.longitude},
            content : markerImg,
            zIndex : 993
          };
          let newUserPositionID = new window.google.maps.marker.AdvancedMarkerElement(markerOptions);
          addDropAnimation(newUserPositionID)
          setUserPositionID(newUserPositionID)
        }
      }, (error) => {
        // Géolocalisation impossible ou désactivée
        // Affichage d'un message d'erreur
        let errorMessage
        if (error === 1) {
          errorMessage = "Impossible d'afficher votre position. Votre navigateur bloque l'accès à votre position. Autorisez votre navigateur à partager votre position."
        } else if (error === 2) {
          errorMessage = "Impossible d'afficher votre position. Vous êtes hors de portée du GPS. Réessayez depuis un autre lieu."
        } else {
          errorMessage = "Impossible d'afficher votre position."
        }
      dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      })
    } else {
      // Browser incompatible
      // Affichage d'un message d'erreur
      let errorMessage = "Impossible d'afficher votre position. La version de votre navigateur ne permet pas la géolocalisation. "
      dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
    }
  }

  ////////////////////////////
  // CREATION MULTIPLE POST //
  ////////////////////////////
  
  // Fonction lancée au click du bouton : lancement de l'écran de sélection des spots
  function StartSpotsSelection() {
    // Fermeture du dernier marker
    if (selectedMarker) {
      UnSelectMarker()
    }
    map.setZoom(map.getZoom()-1)
    let newSelectedSpots = []
    // Fermeture du visibleSpotsFeeed
    CloseVisibleSpotsFeed()
    // Sélection des spots visibles
    for (var i=0; i<Math.min(visibleSpotsState.spotsIDsArray.length,multipostMaxSpots); i++) {
      let markerIndex = markersArray.findIndex(item => (item.pk === visibleSpotsState.spotsIDsArray[i]))
      let marker = markersArray[markerIndex]
      let markerID = markerIDsArray[markerIndex]
      newSelectedSpots.push(marker)
      addBounceAnimation(markerID)
      if (marker.spotType === "Mouillage") {
        markerIcon = {url : "/static/Mouillage-Multipost.svg", scaledSize : {width:pinWidth, height:pinHeight}}
      } else {
        markerIcon = {url : "/static/Port-Multipost.svg", scaledSize : {width:pinWidth, height:pinHeight}}
      }  
      changeMarkerImgSrc(markerID, markerIcon.url)
      polygonIDsArray[markerIndex].setOptions(polygonOptionsMultipost)
    }

    // Passage à l'étape de sélection des spots
    setNewContentState(prevState => ({...prevState,isSelectingSpotsForMultipost:true, selectedSpots:newSelectedSpots}))
  }

  /// Fonction qui réafecte un icône à chaque marker quand on quitte la sélection
  function ResetMarkerIcon(markerArgument) {
    if (token) {
      if (subscriptionsArray.findIndex(id => (markerArgument.pk === id.subscriptionSpot)) !== -1) {
        if (markerArgument.spotType === "Mouillage") {
          markerIcon = {url : "/static/Mouillage-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        } else {
          markerIcon = {url : "/static/Port-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        }  
      } else {
        if (markerArgument.spotType === "Mouillage") {
          markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        } else {
          markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        }  
      }
    } else {
      if (markerArgument.spotType === "Mouillage") {
        markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
      } else {
        markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
      }  
    }

    let markerIndex = markersArray.findIndex(marker => (marker.pk === markerArgument.pk))
    changeMarkerImgSrc(markerIDsArray[markerIndex], markerIcon.url)
    removeAnimation(markerIDsArray[markerIndex])
    polygonIDsArray[markerIndex].setOptions(polygonOptionsNormal)
  }


  function GoToNewPostScreen() {
    // Passage à la rédaction du post, si on a sélectionné au moins un spot
    setNewContentState(prevState => ({...prevState,isSelectingSpotsForMultipost:false}))
    newContentState.selectedSpots.forEach(item => {
      ResetMarkerIcon(item)
    })
    navigate("/MapNewPost" , {state : {spots : newContentState.selectedSpots}})
  }

  function EndSpotSelection() {
    // Remise à zéro et sortie du process
    newContentState.selectedSpots.forEach(item => {
      ResetMarkerIcon(item)
    })
    setNewContentState(prevState => ({...prevState,isSelectingSpotsForMultipost:false, selectedSpots:[]}))
  }

  /////////////////////////
  // CREATION REPORTMARK //
  /////////////////////////
    
  function StartNewReportMarkPositioning() {
    if (selectedMarker) {
      UnSelectMarker()
    }
    // Fermeture du visibleSpotsFeeed
    CloseVisibleSpotsFeed()
    // Passage à l'étape de positionnement du marker
    setNewContentState(prevState => ({...prevState,isSettingNewReportMark: true}))
  }

  function NewReportMarkSet() {
    if (newReportMarkID) {
      newReportMarkID.setMap(null)
      setNewReportMarkID(null)
    } 
    
    // Acquisition des coordonnées du centre de la carte
    console.log('MapFixedPage -> Acquisition des coordonnées du centre de la carte')
    let mapCenter = map.getCenter()
    let mapCenterPosition = {lat:mapCenter.lat(), lng:mapCenter.lng()}

    // Nouvelle syntaxe pour mettre une image en tant que glyphe dans un marker
    let markerImg = document.createElement('img');
    markerImg.src = "/static/Mark.svg"
    markerImg.style.width = '60px';
    markerImg.style.height = '60px';
    let markerOptions = {
      map: map,
      position: mapCenterPosition,
      content : markerImg,
      zIndex : 995
    };
    setNewReportMarkID(new window.google.maps.marker.AdvancedMarkerElement(markerOptions))
    setNewContentState(prevState => ({...prevState, newReportMark : mapCenterPosition,isSettingNewReportMark:"ReportTypeSelection"}))

    map.panTo({
      lat: mapCenter.lat()-1/Math.pow(2,(map.getZoom()-7)),
      lng: mapCenter.lng()
    })
  }

  function GoToNewReportScreen(item) {
    // Passage à l'étape de la rédaction du signalement
    setNewContentState(prevState => ({...prevState,isSettingNewReportMark:false}))
    newReportMarkID.setMap(null)
    navigate("/MapNewMark" , {state : {reportType : item, reportMark : newContentState.newReportMark}})
  }

  function EndNewReportMarkSelection() {
    // Sortie de la fonction de signalement
    if (newReportMarkID) {
      newReportMarkID.setMap(null)
    }
    setNewContentState(prevState => ({...prevState,isSettingNewReportMark:false, newReportMark : null}))
  }

  function ReportTypeSelectionScreen() {
    if (newContentState.isSettingNewReportMark === "ReportTypeSelection") {
      return(
        <Paper sx={{position : "fixed", display : "flex", flexDirection : "row", alignItems : "start", flexWrap: "wrap", backgroundColor : "white", bottom:{ xs:150, sm:160}, left : "50%", transform: 'translate(-50%, 0%)', width : { xs:"90%", sm:400}, borderRadius : 10}} elevation={24} >
          {reportTypesArray.map((item, index) => {
            return(
              <Box key={index} sx={{display : "flex", flexDirection : "column", justifyContent : "center",  padding : 2}}>
                <Box
                  component="img"
                  sx={{
                   height : 50,
                   width : 50
                  }}
                  src={reportTypesArray[index].icon}
                  onClick={() => {GoToNewReportScreen(item)}}
                />
                <Typography variant="body2">
                  {item.label}
                </Typography>
              </Box>
            )
          })}
        </Paper>
      )
    } else return null
  }

  ///////////////////////////
  // CREATION NOUVEAU SPOT //
  ///////////////////////////

  function StartNewSpot() {
    // Désactivation des polygones
    // ATTENTION : elle est définitive, dont les polygones restent désactivés si on annule la création,
    // ils ne se réactivent que si on crée réellement le spot, ce qui déclenche la réinitialisation des polygons
    polygonIDsArray.forEach(polygonID => polygonID.setOptions(polygonOptionsDesactivated))
    currentPolyline = new window.google.maps.Polyline({ map: map, path: [], strokeColor: "#FF0000", strokeOpacity: 1.0, strokeWeight: 2 });
    currentPolygonArray = []
    if (selectedMarker) {
      UnSelectMarker()
    }
    // Passage à l'étape de positionnement du marker
    setNewContentState(prevState => ({...prevState,isSettingNewSpot: true}))
  }

  function EndNewSpotCreation() {
    // Sortie de la fonction de signalement
    if (newSpotMarkID) {
      newSpotMarkID.setMap(null)
    }
    newPolygonIsClosed = false;
    if (currentPolygon) {
      currentPolygon.setMap(null)
    }
    if (newPolygonMarkersArray) {
      newPolygonMarkersArray.forEach(marker => {
        marker.setMap(null)
      })
    }
    if (currentPolyline) {
      currentPolyline.setMap(null)
    }
    setNewContentState(prevState => ({...prevState,isSettingNewSpot:false, newSpotMarker : null}))
  }

  //////////////////////////////////////////
  // FONCTIONS UTILISEES DANS LE CODE JSX //
  //////////////////////////////////////////

  // Fonction utilisée pour calculer l'expiration des reportMarks à afficher
  function DateFromNow(props) {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    return(dayjs(props,"YYYY-MM-DD HH:mm:ss").fromNow())
  }
  
  function ChatWithUser() {
    let userIndexInSubscription = chatHeadersArray.findIndex(item => (item.chatroomsubscriptions[0].subscriptionUser === selectedMarker.userPositionUser))
    if (userIndexInSubscription === -1) { // On vérifie si un chat avec l'utilisateur existe déjà car sinon il faut le créer avant d'aller vers l'écran de chat
      console.log('MapFixedPage.js -> Pas de chat commencé avec cet utilisateur : on crée un nouveau header')
      let headerToCreate = {
        "chatroomsubscriptions": [
          {
              "subscriptionUser": myProfile.pk,
              "subscriptionUnreadMessages": 0
          },
          {
              "subscriptionUnreadMessages": 0,
              "subscriptionUser": selectedMarker.userPositionUser
          }
        ]
      }
      PostFunction({fetchTarget : 'createChatHeader', fetchArgument : headerToCreate, token : token})
      .then(response => {
        let newChatRoom = response.data.chatroomsubscriptions[0].subscriptionChatroom
        if (response.fetchStatus === 'Ok')  {
          console.log('MapFixedPage.js -> Chargement du chatHeadersArray à jour')
          GetFunction({fetchTarget : 'chatHeadersArray', fetchArgument : null, token : token})
          .then((response) => {
            if (response.fetchStatus === 'Ok') {
              console.log('MapFixedPage.js -> Chargement du chatHeadersArray dans le state Redux')
              dispatch({ type : "LOAD_CHAT_HEADERS_ARRAY", payload:response.data})
            } else {
              console.log('MapFixedPage.js -> Impossible de charger la liste des conversations à jour')
            }
          })
          let nextPage = "/Chat/" + newChatRoom + "/" + selectedMarker.userPositionUser
          navigate (nextPage)
        } else {
          console.log('MapFixedPage.js -> Impossible de créer une conversation avec cet utilisateur')
        }
      })
    } else {
      console.log('MapFixedPage.js -> Il y a déjà un chat commencé avec cet utilisateur : on y va')
      let existingChatroom = chatHeadersArray[userIndexInSubscription].chatroomsubscriptions[0].subscriptionChatroom
      let nextPage = "/Chat/" + existingChatroom + "/" + selectedMarker.userPositionUser
      navigate (nextPage)
    }
  }

  ////////////////////
  //  AFFICHAGE JSX //
  ////////////////////

  // On montre la carte, ou la cache pour laisser apparaitre les autres écrans quand on sort du menu MapPage
  // Permet de ne pas supprimer/recréer la carte à chaque navigation dans les page (coûte des crédit d'API GoogleMaps)

  // Dans le return principal, on met tous les boutons et menus pour pouvoir faire des animations.
  // Si on les appelle dans des fonctions séparées, même en respectant la synthaxe JSX,, les animations ne fonctionnent pas.

  return (
    <Box sx={{width : "100%", height : displayMap ? "100%" : "0%"}}>
      <div style={{ width: "100%", height: "100%" }} id="map"/> 
      {displayMap ?
        <Fragment>
          <ReportTypeSelectionScreen/>

          {/* INFO CHIPS AND SNACKBAR ZONE */}
          <Box
            sx={{
              display : "flex",
              alignItems : "center",
              justifyContent : "center",
              flexDirection : "column",
              position : "fixed",
              top : { xs:65, sm:75}, right : 10, left : 10,
              pointerEvents : "none"
          }}>
            {/* MultiPostSpotsSelectionChip */}
            {newContentState.isSelectingSpotsForMultipost === true ?
              <Chip
                label={
                  <Box
                    sx={{
                      textAlign: 'center',     // Centrer le texte horizontalement
                      whiteSpace: 'normal',    // Pour permettre d'afficher le texte sur plusieurs lignes
                      display: 'block',        // Assure que le texte prend tout l'espace
                    }}
                  >
                    Où voulez-vous publier ?            
                    <br/>
                    {newContentState.selectedSpots.length + (newContentState.selectedSpots.length >= multipostMaxSpots ? "/" + multipostMaxSpots + " max" : null) + " spots sélectionnés"}
                  </Box>
                } 
                sx={{
                  color: 'white', // Couleur du texte et de l'icône
                  backgroundColor: (newContentState.selectedSpots.length === 0 || newContentState.selectedSpots.length >= multipostMaxSpots) ? "error.main" : "success.main",
                  animation: `${buttonEffect} 0.5s ease infinite`,
                  padding : 1,
                  marginBottom : 1,
                  // Pour que la chip puisse être sur plusieurs lignes
                  height: 'auto',
                  padding : 1,
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  }
                }}
              />
            :
            null
            }

            {/* NewReportMarkChip */}
            {newContentState.isSettingNewReportMark === true ?
              <Chip
                label="Placez le signalement"
                color="primary"
                sx={{
                  animation: `${buttonEffect} 0.5s ease infinite`,
                  marginBottom : 1,
                  padding : 1
                }}
              />
            :
            null
            } 

            {/* SNACK BAR */}

            <Snackbar 
              open={snackBar.open}
              autoHideDuration={5000}
              onClose={() => {setSnackBar(prevState => ({...prevState, open : false}))}}
              sx={{position : "fixed", top : 100}}
            >
                <Alert
                  severity={snackBar.type ? snackBar.type : "success"}
                  variant="filled"
                  onClose={() => {}} // Affiche la croix par défaut pour fermer
                  sx={{ width: '100%',
                    '.MuiAlert-message' :{
                      color : "white"
                    },
                    '.MuiAlert-icon' :{
                      color : "white"
                    }
                   }}
                >
                  {snackBar.message}
                </Alert>
              </Snackbar>

          </Box>

          {/* MARKER DETAIL CARD */}
          <Box ref={markerDetailRef} sx={{display : "flex", flexDirection : "column", alignItems : "center", width : "100%"}}>
            <Paper elevation={24}  
              sx={{
                display : "flex",
                flexDirection : "column", 
                position : "fixed",
                justifyItems : "center",
                bottom: 0, 
                left : { xs:"0%", sm:"10%"}, 
                right : { xs:"0%", sm:"10%"}, 
                borderTopLeftRadius : 10, 
                borderTopRightRadius : 10,
                height : (!selectedMarker ? 0 : markerDetailBoxState.boxHeight),
                marginLeft : 1,
                marginRight : 1,
                paddingLeft : 1,
                paddingRight : 1,
                transition : "height " + markerDetailBoxState.heightTransition + ", width 0.3s ease-out, opacity 0.5s", // Pour des transitions fluides
                backgroundColor : "white",
                zIndex : 1001
              }}
            >
              {/* Croix pour fermer la fenêtre */}
              {selectedMarker ?
                <Box sx={{position : "absolute", zIndex : 1002, top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => {UnSelectMarker()}}>
                  <IconButton color="secondary">
                    <HighlightOffIcon fontSize = "large"/>
                  </IconButton>
                </Box>
              :
                null
              } 

              {selectedMarker ?

              // CAS DU SPOT
                (selectedMarker.hasOwnProperty('spotType') ?
                  <Fragment>
                    <Box
                      sx={{display : "flex", flexDirection : "column", position : "relative", backgroundColor : "white"}}
                      ref={markerDetailHeaderRef}
                    >
                      {/* Photo circulaire du spot */}
                      <Avatar
                        src={"https://maps.googleapis.com/maps/api/staticmap?center=" + (selectedMarker.spotLatitude-1.2/Math.pow(2,(selectedMarker.markerImgZoom-7))).toString() + "," + selectedMarker.spotLongitude + "&zoom=" + selectedMarker.markerImgZoom + "&size=400x400&maptype=satellite&key=AIzaSyATHwwDt6IBo65JOtA0bxsJ7G8r7lk4Fdk"}  
                        sx={{ position : "absolute", width: 300, height: 300, top : -100, right : "50%", transform : "translate(50%,0)", zIndex : 1000, border: '15px solid #f3722C'}}
                        onClick={() => {navigate("/Feed/" + selectedMarker.pk)}}
                      />
                      <Box sx={{display : "flex", flexDirection : "column", alignItems : "center", justifyContent : "center", height : 10, backgroundColor : "white", zIndex : 1001}}>
                        <Divider flexItem = {true} sx={{backgroundColor : 'secondary.main', opacity : 0.6, mt : 2, mb : 1, ml : "30%", mr : "30%"}}/>
                      </Box>
                      <Box sx={{display : "flex", flexDirection : "row", paddingBottom : 2, backgroundColor : "white", zIndex : 1001}}>
                        <Box sx={{display : "flex", flex : 8, flexDirection : "column", alignItems : "center"}} onClick={() => {navigate("/Feed/" + selectedMarker.pk)}}>
                          <Typography variant="h6" color="secondary" fontWeight = "bold" sx={{textAlign : "center"}}>
                            {selectedMarker.spotName}
                          </Typography>
                          <Typography variant="body3" color="primary">
                            Abonnés : {selectedMarker.nbSubscriptions}
                          </Typography>
                        </Box>
                        <Box flex={1}>
                          <IconButton
                            sx={{mr : 3}}
                            onClick={(token ? (selectedMarkerSubscribed ? Unsubscribe : Subscribe) : goToLogin)}
                          >
                            {
                            (token ?
                              (isSubscribing ?
                                (isSubscribing === "subscribing" ?
                                  <FavoriteIcon color="secondary" sx={{ fontSize: 40 }}/>
                                  :
                                  <FavoriteBorderIcon color="primary" sx={{ fontSize: 40 }}/>
                                )
                                :
                                (selectedMarkerSubscribed ?
                                  <FavoriteIcon color="secondary" sx={{ fontSize: 40 }}/>
                                  :
                                  <FavoriteBorderIcon color="primary" sx={{ fontSize: 40 }}/>
                                )
                              )
                              :
                              <FavoriteBorderIcon color="primary" sx={{ fontSize: 40 }}/>
                            )
                            }
                          </IconButton>
                        </Box>
                      </Box>
                      <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", paddingBottom : 1, backgroundColor : "white",zIndex : 1001}} >
                        <Typography variant="h7" fontWeight="bold" sx={{color : "#f3722C"}} onClick={() => {navigate("/Feed/" + selectedMarker.pk)}}>
                          Dernières discussions :
                        </Typography>
                        <Box sx={{flex : 1}}/>
                        {(selectedMarker.spotharbors ?
                          <Fragment>
                            <Typography variant="h7" fontWeight="bold" sx={{color : "#f3722C"}}>
                              Capitainerie :
                            </Typography>
                            <IconButton
                              onClick={(token ? () => {navigate("/OtherUserProfile/" + selectedMarker.spotharbors.harborUser)} : goToLogin)}
                            >
                              <FaceIcon color="secondary" sx={{ fontSize: 40 }}/>
                            </IconButton>
                          </Fragment>
                          :
                          null
                        )}
                      </Box>
                    </Box>
                    {(markerDetailBoxState.size === "big" ?
                      <Box
                        ref={markerDetailListRef} // ref servant pour la gestion du scroll avec le drag
                        sx={{display : "flex", flexDirection : "column", height : "100%", backgroundColor : "white", zIndex : 1001}} overflow="auto"
                      >
                        {(spotFeedPreview.isFetching ?
                          <Box sx={{display : "flex", flex : 1, flexDirection : "row", justifyContent : "center", alignItems : "center"}}>
                            <CircularProgress />
                          </Box>
                        :
                          spotFeedPreview.feed
                        )}
                      </Box>
                    :
                      <Box ref={markerDetailListRef} sx={{display : "flex", flex : 1, backgroundColor : "white", zIndex : 1001}}/>
                    )}
                  </Fragment>     
                :

                // CAS DU REPORT MARK
                  (selectedMarker.hasOwnProperty('reportMarkType') ?
                    <Box sx={{display : "flex", flexDirection : "column", position : "relative", backgroundColor : "white", zIndex : 1001}}>
                      <Card sx={{ width: "100%"}}  ref={markerDetailHeaderRef}>
                        <CardHeader
                          avatar={
                            <Avatar src={selectedMarker.icon} sx={{ width: 100, height: 100}} />
                          }
                          title={selectedMarker.label}
                          subheader={DateFromNow(selectedMarker.reportMarkTimestamp)}
                        />
                        <CardActionArea onClick={() => {navigate("/Comments/" + selectedMarker.reportMarkPost.toString())}}>
                          <CardContent>
                            <Typography gutterBottom variant="h5">
                            {selectedMarker.label}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <Divider variant="middle" />
                        <CardActions>
                          <Button size="small" onClick={() => {navigate("/Comments/" + selectedMarker.reportMarkPost.toString())}}>
                            <ThumbUpIcon  color="primary" sx={{marginRight : 1}}/>
                            <Typography variant = "body3">
                              {selectedMarker.nbLikes}
                            </Typography>
                          </Button>
                          <Button size="small" onClick={() => {navigate("/Comments/" + selectedMarker.reportMarkPost.toString())}}>
                            <Typography variant = "body3">
                              {selectedMarker.nbComments} commentaires
                            </Typography>
                          </Button>
                        </CardActions>
                      </Card>
                      {/* A remplir ultérieuremment avec un preview */}
                      <Box ref={markerDetailListRef}>
                      </Box>
                    </Box>
                  :

                // CAS DU USER POSITION
                    (selectedMarker.hasOwnProperty('userPositionUser') ?      
                      <Box sx={{display : "flex", flexDirection : "column", position : "relative", backgroundColor : "white", zIndex : 1001}}>
                        <Card sx={{ width: "100%"}}  ref={markerDetailHeaderRef}>
                          <CardActionArea onClick={() => {navigate("/OtherUserProfile/" + selectedMarker.userPositionUser)}}>
                            <CardContent>
                              <Box sx={{ display : "flex", flexDirection : "row", alignItems : "center", marginBottom : 1}}
                              >
                                {selectedMarker.userPositionAvatarurl ? 
                                  <Avatar src={selectedMarker.userPositionAvatarurl} sx={{ width: 60, height: 60, marginRight : 1, bgcolor:"primary"}} />
                                  :
                                  null
                                }
                                <Box sx={{ display : "flex", flexDirection : "column", marginRight : 1}}
                                >
                                  <Box sx={{ display : "flex", flexDirection : "row", alignItems : "center"}}
                                  >
                                    {selectedMarker.userPositionNickname}
                                    {selectedMarker.userPositionGlobalBadge ?
                                      <Avatar src={selectedMarker.userPositionGlobalBadge} sx={{ width: 20, height: 20, marginRight : 1, bgcolor:"primary"}} />
                                      :
                                      null
                                    }
                                  </Box>
                                  <Typography
                                    variant="caption"
                                    color="grey"
                                  >
                                    {DateFromNow(selectedMarker.userPositionTimestamp)}
                                  </Typography>
                                </Box>
                              </Box>
                                {selectedMarker.userPositionBoatType ?
                                <Fragment>
                                  <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                                    Mon type de bateau
                                  </Typography>
                                  <Typography variant="body2" color={"primary"} textAlign={'left'}>
                                    {boatTypes[selectedMarker.userPositionBoatType-1]}
                                  </Typography>
                                  <p/>
                                </Fragment>
                                :
                                null
                              }
                              {selectedMarker.userPositionBoatName ?
                                <Fragment>
                                  <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                                    Le nom de mon bateau
                                  </Typography>
                                  <Typography variant="body2" color={"primary"} textAlign={'left'}>
                                    {selectedMarker.userPositionBoatName}
                                  </Typography>
                                  <p/>
                                </Fragment>
                                  :
                                null
                              }
                              {selectedMarker.userPositionBoatModel ?
                                <Fragment>
                                  <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                                    Le modèle de mon bateau
                                  </Typography>
                                  <Typography variant="body2" color={"primary"} textAlign={'left'}>
                                    {selectedMarker.userPositionBoatModel}
                                  </Typography>
                                  <p/>
                                </Fragment>
                                  :
                                null
                              }
                              {selectedMarker.userPositionBoatHarbor ?
                                <Fragment>
                                  <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                                    Mon port d'attache
                                  </Typography>
                                  <Typography variant="body2" color={"primary"} textAlign={'left'}>
                                    {selectedMarker.userPositionBoatHarbor}
                                  </Typography>
                                  <p/>
                                </Fragment>
                                  :
                                null
                              }

                            </CardContent>
                          </CardActionArea>
                          <Divider variant="middle" />
                          <CardActions>
                            <Button size="small" onClick={() => {navigate("/OtherUserProfile/" + selectedMarker.userPositionUser)}}>
                              <Typography variant="body2" >
                                Voir son profil
                              </Typography>
                            </Button>
                            <Box sx={{flex : 1}}/>
                            {(token) ?
                              (selectedMarker.userPositionUser !== myProfile.pk) ?
                                <Button size="small" onClick={ChatWithUser}>
                                  <Typography variant="body2" >
                                    Lui écrire
                                  </Typography>
                                </Button>
                                :
                                null
                              
                              :
                              null
                            }
                          </CardActions>
                        </Card>
                        {/* A remplir ultérieuremment avec un preview */}
                        <Box ref={markerDetailListRef}>
                        </Box>
                      </Box>
                    :
                      null
                    )
                  )
                )
              :
                null
              }
            </Paper>
          </Box>

          {/* NEW REPORTMARK TARGET */}
          {newContentState.isSettingNewReportMark === true ?
            <Box
              component="img"
              sx={{
                position : "fixed",
                right : "50%",
                top : "50%",
                transform: "translate(50%, -100%)",
                height: 60,
                width: 60,
                animation: `${buttonEffect} 0.5s ease infinite`,
              }}
              src={"/static/Mark.svg"}
            />
          :
          null
          } 

          {/* TOP LEFT FABS */}
          <Box sx={{
            position : "absolute",
            display : "flex",
            flexDirection : "column",
            top : 80,
            left : 10,
            pointerEvents : "none"
          }}>
            {/* ZoomPositionFAB */}
            <Fab color="primary" onClick={ZoomPosition} 
              sx={{
                transform : shouldHideFABs ? "scale(0)" : "scale(1)",
                transition: 'transform 0.5s',
                marginTop : 0.5, marginBottom : 0.5,
                pointerEvents : "auto"
              }}
            >
              <GpsFixedIcon sx={{color : "white"}}/>
            </Fab>
            {/* SharePositionFAB */}
            {token ?
              <Box sx={{display : "flex", flexDirection : "column", pointerEvents : "none"}}>
                <Fab color="success" onClick={() => {UpdateMyProfile(false)}} 
                  sx={{
                    position : "absolute",
                    color: 'white', // Couleur du texte et de l'icône
                    backgroundColor: "success.main",
                    transform : (shouldHideFABs || !myProfile.userPositionShare) ? "scale(0)" : "scale(1)",
                    transition: 'transform 0.5s',
                    marginTop : 0.5,
                    marginBottom : 0.5 ,
                    pointerEvents : "auto"

                  }}
                >
                  <NearMeIcon sx={{color : "white"}}/>
                </Fab>
                <Fab color="error" onClick={() => {UpdateMyProfile(true)}} 
                  sx={{
                    position : "absolute",
                    transform : (shouldHideFABs || myProfile.userPositionShare) ? "scale(0)" : "scale(1)",
                    transition: 'transform 0.5s',
                    marginTop : 0.5,
                    marginBottom : 0.5,
                    pointerEvents : "auto"
 
                  }}
                >
                    <NearMeDisabledIcon sx={{color : "white"}}/>
                </Fab>
              </Box>
            :
              <Fab color="error" onClick={() => {navigate('/login')}} 
                sx={{
                  transform : shouldHideFABs ? "scale(0)" : "scale(1)",
                  transition: 'transform 0.5s',
                  marginTop : 0.5, marginBottom : 0.5,
                  pointerEvents : "auto"
 
                }}
              >
                <NearMeDisabledIcon sx={{color : "white"}}/>
              </Fab>
            }
          </Box>

          {/* BOTTOM RIGHT FABS */}
          <Box sx={{
            position : "absolute",
            display : "flex",
            flexDirection : "column",
            alignItems : "center",
            bottom : 60,
            right : 10,
            pointerEvents : "none"
          }}>
            {/* SPEED DIAL */}
            <SpeedDial
              ariaLabel="Créer"
              icon={<SpeedDialIcon/>}
              sx={{
                transform : shouldHideFABs ? "scale(0)" : "scale(1)",
                transition: 'transform 0.5s',
                marginTop : 0.5, marginBottom : 0.5,
                '.MuiSpeedDial-fab': {
                  backgroundColor: 'primary.main',   // Couleur de fond personnalisée
                  color: 'white',            // Couleur de l'icône
                }           // Hauteur personnalisée
              }}
            >
              <SpeedDialAction
                icon={<AddLocationIcon/>}
                onClick={StartNewReportMarkPositioning}
                sx={{
                  backgroundColor: 'secondary.main',
                  color: 'white',
                  width: 56,
                  height: 56,     
                }}
              />
              <SpeedDialAction
                icon={<SendIcon/>}
                onClick={StartSpotsSelection}
                sx={{
                  backgroundColor: 'secondary.main',
                  color: 'white',
                  width: 56,
                  height: 56,     
                }}
              />
            </SpeedDial>
            {/* NewSpotFAB */}
            {myProfile.userIsStaff ?
              <Fab color="error" size="large" onClick={StartNewSpot} 
                sx={{
                  transform : shouldHideFABs ? "scale(0)" : "scale(1)",
                  transition: 'transform 0.5s',
                  marginTop : 0.5, marginBottom : 0.5,
                  pointerEvents : "auto"

                }}
              >
                <AddLocationIcon sx={{color : "white"}}/>
              </Fab>
            :
            null
            }
            {/* MultiPostSpotsSelectionButtons */}
            {newContentState.isSelectingSpotsForMultipost ?
              <Fab
                disabled={(newContentState.selectedSpots.length ===0)}
                onClick={GoToNewPostScreen}
                sx={{
                  color: 'white', // Couleur du texte et de l'icône
                  backgroundColor: "success.main",
                  marginTop : 0.5,
                  marginBottom : 0.5, 
                  animation: `${buttonEffect} 0.5s ease infinite`,
                  pointerEvents : "auto"

                }}>
                <CreateIcon sx={{color : "white"}}/>
              </Fab>
              :
              null
            }
            {newContentState.isSelectingSpotsForMultipost ?
              <Fab
                onClick={EndSpotSelection}
                sx={{
                  color: 'white', // Couleur du texte et de l'icône
                  backgroundColor: "error.main",
                  marginTop : 0.5,
                  marginBottom : 0.5, 
                  animation: `${buttonEffect} 0.5s ease infinite`,
                  pointerEvents : "auto"

                }}>
                <CancelIcon sx={{color : "white"}}/>
              </Fab>
              :
              null
            }
            {/* NewReportMarkFloatingActionButtons */}
            {newContentState.isSettingNewReportMark !== false ?
              <Fab
                onClick={NewReportMarkSet}
                sx={{
                  color: 'white', // Couleur du texte et de l'icône
                  backgroundColor: "success.main",
                  marginTop : 0.5,
                  marginBottom : 0.5, 
                  animation: `${buttonEffect} 0.5s ease infinite`,
                  pointerEvents : "auto"

                }}>
                <AddLocationIcon sx={{color : "white"}}/>
              </Fab>
            :
            null
            }
            {newContentState.isSettingNewReportMark !== false ?
              <Fab
                onClick={EndNewReportMarkSelection}
                sx={{
                  color: 'white', // Couleur du texte et de l'icône
                  backgroundColor: "error.main",
                  marginTop : 0.5,
                  marginBottom : 0.5, 
                  animation: `${buttonEffect} 0.5s ease infinite`,
                  pointerEvents : "auto"

                }}>
                <CancelIcon sx={{color : "white"}}/>
              </Fab>
            :
            null
            }
            {/* NewSpotFloatingActionButtons */}
            {newContentState.isSettingNewSpot !== false ?
              <Fab
                onClick={EndNewSpotCreation}
                sx={{
                  color: 'white', // Couleur du texte et de l'icône
                  backgroundColor: "error.main",
                  marginTop : 0.5,
                  marginBottom : 0.5, 
                  animation: `${buttonEffect} 0.5s ease infinite`,
                  pointerEvents : "auto"

                }}>
                <CancelIcon sx={{color : "white"}}/>
              </Fab>
            :
            null
          } 
          </Box>

          {/* BOTTOM CENTER FABS */}
          <Box sx={{
            position : "absolute",
            display : "flex",
            flexDirection : "column",
            justifyContent: "center",
            bottom : 10,
            left : "50%",
            transform : "translateX(-50%)",
            pointerEvents : "none"
          }}>
            {/* DisplayVisibleSpotsFeedFAB */}
              <Fab color="secondary"
                sx={{
                  transform : shouldHideFABs ? "scale(0)" : "scale(1)",
                  transition: 'transform 0.5s',
                  height : 80, width : 80,
                  pointerEvents : "auto"

                }}
                onClick={() => {
                  if (visibleSpotsState.isFetchingFeed === false) {OpenVisibleSpotsFeed()}
                }} 
              >
              {visibleSpotsState.isFetchingFeed ?
                <CircularProgress sx={{color : "white"}} />
                :
                <ForumIcon sx={{color : "white", mb : 5}}/>
              }
              </Fab>
          </Box>

          {/* VISIBLE SPOTS FEED */}
          <Paper
            elevation = {24}
            ref={visibleSpotsRef}
            sx={{
              display : "flex", 
              flexDirection : "column", 
              position:"fixed", 
              justifyItems : "center",
              bottom: 0, 
              left : { xs:"0%", sm:"10%"}, 
              right : { xs:"0%", sm:"10%"}, 
              borderTopLeftRadius : 10, 
              borderTopRightRadius : 10,
              height : shouldHideVisibleSpotsFeed ? 0 : visibleSpotsFeedBoxState.boxHeight,
              transition : "height " + visibleSpotsFeedBoxState.heightTransition + ", opacity 0.5s", // Pour des transitions fluides
              marginLeft : 1,
              marginRight : 1,
              backgroundColor : "white",
            }}
          >
            {/* Croix pour fermer la fenêtre */}
            {visibleSpotsState.displayFeed ?
              <Box sx={{position : "absolute", zIndex : 1001, top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {CloseVisibleSpotsFeed}>
                <IconButton color="secondary">
                  <HighlightOffIcon fontSize = "large"/>
                </IconButton>
              </Box>
              :
              null
            } 
            <Box 
              ref={visibleSpotsHeaderRef} // Pour gérer le drag sur la zone de titre
              sx={{display : "flex", flexDirection : "column", alignItems : "center", marginBottom : 2}}
            >
              <Divider flexItem = {true} sx={{backgroundColor : 'secondary.main', opacity : 0.6, mt : 1, mb : 1, ml : "30%", mr : "30%"}}/>
              <Typography variant="body2"  fontWeight = "bold" color="#f3722C">
                {visibleSpotsState.isFetchingFeed ?
                "Recherche des discussions..."
                  :
                "Discussions dans cette zone"
                } 
              </Typography>
            </Box>
            {(visibleSpotsState.displayFeed ? 
              <Box
              ref={visibleSpotsListRef} // ref servant pour la gestion du scroll avec le drag
              onScroll={handleVisibleSpotsScroll} // Pour lancer le fetch quand on arrive en bas de la liste
              sx={{display : "flex", flexDirection : "column"}} overflow="auto">
              {(visibleSpotsState.isFetchingFeed ?
                <Box sx={{display : "flex", flex : 1, flexDirection : "row", justifyContent : "center", alignItems : "center"}}>
                  <CircularProgress />
                </Box>
              :
                visibleSpotsState.feed
              )}
            </Box>        
            :
            null
            )}
          </Paper> 

        </Fragment>   
        :
        null
      }   
    </Box>
  )
}
