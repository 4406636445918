// DECLARATIONS POUR REACT-ROUTER
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Navigate } from 'react-router-dom'

import MapPage from './components/Map/MapPage'
import AppBar from './components/Navigation/AppBar'
import ErrorPage from './components/Navigation/ErrorPage'
import Profile from './components/Profile/Profile'
import Account from "./components/Profile/Account"
import EditProfile from './components/Profile/EditProfile'
import EditAccount from "./components/Profile/EditAccount"
import Login from './components/Profile/Login'
import Register from "./components/Profile/Register"
import RegisterPassword from "./components/Profile/RegisterPassword"
import RegisterUserName from "./components/Profile/RegisterUserName"
import RegisterUserDescription from "./components/Profile/RegisterUserDescription"
import RegisterUserBoat from "./components/Profile/RegisterUserBoat"
import RegisterLocation from "./components/Profile/RegisterLocation"
import RegisterEnd from "./components/Profile/RegisterEnd"
import NewsFeed from "./components/Feed/NewsFeed"
import SpotFeed from "./components/Feed/SpotFeed"
import FeedMembers from "./components/Feed/FeedMembers"
import FeedNewPost from "./components/Feed/FeedNewPost"
import MapNewPost from "./components/Feed/MapNewPost"
import MapNewMark from "./components/Mark/MapNewMark"
import MapNewMarker from "./components/Map/MapNewMarker"
import PostWithComments from "./components/Feed/PostWithComments"
import Report from "./components/Feed/Report"
import OtherUserProfile from "./components/Profile/OtherUserProfile"
import Chat from "./components/Chat/Chat"
import ChatList from "./components/Chat/ChatList"
import NotificationList from "./components/Notifications/NotificationList"
import Contact from "./components/Information/Contact"
import AboutUs from "./components/Information/AboutUs"
import TutorialStart from "./components/Tutorial/TutorialStart"
import Tutorial1 from "./components/Tutorial/Tutorial1"
import Tutorial2 from "./components/Tutorial/Tutorial2"
import Tutorial3 from "./components/Tutorial/Tutorial3"
import Tutorial4 from "./components/Tutorial/Tutorial4"
import Tutorial5 from "./components/Tutorial/Tutorial5"
import Tutorial6 from "./components/Tutorial/Tutorial6"
import Tutorial7 from "./components/Tutorial/Tutorial7"
import Tutorial8 from "./components/Tutorial/Tutorial8"
import Tutorial9 from "./components/Tutorial/Tutorial9"
import TutorialEnd from "./components/Tutorial/TutorialEnd"

import GetFunction from "./components/API/getFunction"
import PostFunction from './components/API/postFunction'
import PatchFunction from './components/API/patchFunction'
import { RemoveConsole } from "./RemoveConsole"
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import LinearProgress from '@mui/material/LinearProgress';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import "./App.css" // Utilisé uniquement pour supprimer le comportement natif de pull-to-refresh

// DECLARATIONS POUR INITIALISATION DONNEES
import { useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'


export default function App() {
  // Suppression des console.log en production

  /**
   * @REMOVE_CONSOLES
   * // remove the working of console logs
   * // remove any accidental use of console logs
   */
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      RemoveConsole(false)
    }
  }, []);

  // CONNEXION AVEC LE STORE
  // Création du Selector, qui va aller chercher dans le store uniquement le token et va s'y abonner
  // A noter : ce sélector est une fonction classique, elle pourrait filtrer les données renvoyées (avec méthode .filter),
  // ou calculer et renvoyer une valeur dérivée du state (nombre d'objets...)
  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectShowErrorMessageScreen = state => state.showErrorMessageScreen
  const showErrorMessageScreen = useSelector(selectShowErrorMessageScreen)

  // On ne va chercher dans le Store que les deux champs du myProfile qui sont utiles pour la gestion de la Position
  // Si on essaie de connecter tout le myProfile, ça plante (quand on va dans l'écran Profile.js, qui commence par mettre à jour
  // le myProfile dans le Store, ça relance le component App en boucle)
  const selectProfileUserPositionShare = state => state.myProfile.userPositionShare
  const selectProfilePk = state => state.myProfile.pk
  const myProfile = {
    pk : useSelector(selectProfilePk),
    userPositionShare: useSelector(selectProfileUserPositionShare)
  }

  const dispatch = useDispatch()

  const [fetchIntervalID, setFetchIntervalID] = useState(0);
  const [sendPositionIntervalID, setSendPositionIntervalID] = useState(0);

  // NAVIGATION REACT-ROUTER

  // Définition de l'objet qui va filtrer l'affichage des routes protégées
  const ProtectedRoute = ({ children }) => {
    if (!token) {
      // On récupère la route demandée, pour rediriger après le login
      // On envoie vers le login, avec option replace pour que ça n'apparaisse pas dans l'historique
      return <Navigate to="/Login" replace />;
    }
    return children;
  };

  function StartingElement() {
    const doNotShowTutorial = JSON.parse(localStorage.getItem('NAUTICTALK_DO_NOT_SHOW_TUTORIAL'));
    if (doNotShowTutorial) {
      console.log('App.js -> DoNotShowTutorial retrouvé dans le localStorage :')
      return (<MapPage/>)
    } else {
      return (<TutorialStart/>)
    }
  }

  const router = createBrowserRouter([ 
    {
      path: "/",
      element: <AppBar/>,
      errorElement: <ErrorPage/>,
      children :[                     // Children : les composants affichés avec la barre de navigation (dans l'Outlet)
        {                                   // Index : composant affiché par défaut 
          index: true, element: StartingElement()   // (un composant d'accueil spécifique,  
        },                                  // ou un des composants de la navigation)
        {
          path: "/MapPage",
          element: <MapPage/>
        }, 
        {
          path: "/NewsFeed",
          element: <ProtectedRoute><NewsFeed/></ProtectedRoute>
        },       
        {
          path: "/Profile",
          element: <ProtectedRoute><Profile/></ProtectedRoute>
        },       
        {
          path: "/Account",
          element: <ProtectedRoute><Account/></ProtectedRoute>
        },       
        { 
          path: "/ChatList",
          element: <ProtectedRoute><ChatList/></ProtectedRoute>
        },       
        { 
          path: "/NotificationList",
          element: <ProtectedRoute><NotificationList/></ProtectedRoute>
        },       
        { 
          path: "/Contact",
          element: <Contact/>
        },       
        { 
          path: "/AboutUs",
          element: <AboutUs/>
        },       
        { 
          path: "/TutorialStart",
          element: <TutorialStart/>
        },       
        { 
          path: "/Tutorial1",
          element: <Tutorial1/>
        },       
        { 
          path: "/Tutorial2",
          element: <Tutorial2/>
        },       
        { 
          path: "/Tutorial3",
          element: <Tutorial3/>
        },       
        { 
          path: "/Tutorial4",
          element: <Tutorial4/>
        },       
        { 
          path: "/Tutorial5",
          element: <Tutorial5/>
        },       
        { 
          path: "/Tutorial6",
          element: <Tutorial6/>
        },       
        { 
          path: "/Tutorial7",
          element: <Tutorial7/>
        },       
        { 
          path: "/Tutorial8",
          element: <Tutorial8/>
        },       
        { 
          path: "/Tutorial9",
          element: <Tutorial9/>
        },       
        { 
          path: "/TutorialEnd",
          element: <TutorialEnd/>
        },       
        {
          path: "/Feed/:spotId",
          element: <ProtectedRoute><SpotFeed/></ProtectedRoute>
        },       
        {
          path: "/FeedMembers",
          element: <ProtectedRoute><FeedMembers/></ProtectedRoute>
        },       
        {
          path: "/Comments/:postId",
          element: <ProtectedRoute><PostWithComments/></ProtectedRoute>
        },       
        {
          path: "/Report/:postId/:commentId",
          element: <ProtectedRoute><Report/></ProtectedRoute>
        },       
        {
          path: "/EditProfile",
          element: <ProtectedRoute><EditProfile/></ProtectedRoute>
        },       
        {
          path: "/EditAccount",
          element: <ProtectedRoute><EditAccount/></ProtectedRoute>
        },       
        { 
          path: "/FeedNewPost",
          element: <ProtectedRoute><FeedNewPost/></ProtectedRoute>
        },
        { 
          path: "/MapNewPost",
          element: <ProtectedRoute><MapNewPost/></ProtectedRoute>
        },
        { 
          path: "/MapNewMark",
          element: <ProtectedRoute><MapNewMark/></ProtectedRoute>
        },
        { 
          path: "/MapNewMarker",
          element: <ProtectedRoute><MapNewMarker/></ProtectedRoute>
        },
        { 
          path: "/OtherUserProfile/:userId",
          element: <ProtectedRoute><OtherUserProfile/></ProtectedRoute>
        },       
        { 
          path: "/Chat/:chatroomId/:userId",
          element: <ProtectedRoute><Chat/></ProtectedRoute>
        },       
        { 
          path: "/Login",               
          element: <Login/>,            
        },                                
        { 
          path: "/Register",               
          element: <Register/>,            
        },      
        { 
          path: "/RegisterPassword",               
          element: <RegisterPassword/>,            
        },      
        { 
          path: "/RegisterUserName",               
          element: <RegisterUserName/>,            
        },      
        { 
          path: "/RegisterUserDescription",               
          element: <RegisterUserDescription/>,            
        },      
        { 
          path: "/RegisterUserBoat",               
          element: <RegisterUserBoat/>,            
        },      
        { 
          path: "/RegisterLocation",               
          element: <RegisterLocation/>,            
        },      
        { 
          path: "/RegisterEnd",               
          element: <RegisterEnd/>,            
        },      
      ]
    },  // Ensuite : composants affichés sans la barre de navigation
  ]);

  // INITIALISATION DES DONNEES

  let url=null
  let headers=null
  const urlBase = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:8000'
    : 'https://api.nautictalk.com'

  // Création d'un state pour gérer le déroulement des fetchs
  const [fetchState, setFetchState] = useState({
    fetchInitiated:false,
    fetchGetUserProfile:false,
    fetchMarkersArray:false,
    fetchUserPositionsArray:false,
    fetchReportMarksArray:false,
    fetchSubscriptionsArray:false,
    fetchChatHeadersArray:false,
    fetchNewsFeedContent:false,
    fetchNotificationArray:false,
    fetchError:false,
    fetchProgress:0,
    fetchNumber:8
  });

  // Au montage de l'app, récupération du token du stockage local et chargement dans le store
  useEffect(() => {
    console.log('App.js -> Render initial de App : recherche du token')
    TokenRecovery()
      // La ligne suivante supprime un warning normal avec dispatch
      //eslint-disable-next-line
      }, []  // Syntaxe pour que le hook ne soit exécuté qu'une fois, au montage du composant
  );   

  // A chaque render lié à un changement de token, on relance l'identification
  useEffect(() => {
    console.log('App.js -> Nouveau render de App pour cause de changement de token')
    TokenChangeManagement()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    }, [token]  // Hook exécuté uniquement si le token a été modifié
  );
  
  function TokenRecovery() {
    console.log('App.js -> Recherche du token dans le localStorage')
    const localToken = JSON.parse(localStorage.getItem('NAUTICTALK_TOKEN'));
    if (localToken) {
      console.log('App.js -> Token retrouvée dans le localStorage :')
      // Vérification de la validité du token et résupération du profil
      // Le fetch est fait sans passer par GetFunction car il est particulier :
      console.log('App.jsx -> Lancement fetch de vérification du token')
      url = urlBase + '/userprofile/'
      headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localToken
      }
      setFetchState(prevState => ({...prevState, fetchGetUserProfile : true}))
      fetch(url, {
        method: 'GET',
        headers: headers
      })
      .then((response) => {
        return response.json()
      })
      .then((response) => {
        if (response.detail) { // Cas d'un token invalide
          console.log('App.js -> Le token retrouvé est invalide')
          setFetchState(prevState => ({...prevState, fetchGetUserProfile : false}))
          localStorage.removeItem('NAUTICTALK_TOKEN')
          dispatch({ type : "RESET_TOKEN"})
        } else {
          console.log('App.js -> Le token retrouvé est bon')
          setFetchState(prevState => ({...prevState, fetchGetUserProfile : false}))
          console.log('App.js -> Chargement du token dans le store')
          dispatch({ type : "LOAD_TOKEN", payload : localToken})
        }
      })
      .catch((error) => {
        console.log('GlobalAppContent.js -> Erreur pendant le fetch de vérification')
        setFetchState(prevState => ({...prevState, fetchGetUserProfile : false}))
        localStorage.removeItem('NAUTICTALK_TOKEN')
        dispatch({ type : "RESET_TOKEN"})
    })
    } else {
      console.log('App.js -> Pas de token dans le localStorage')
    }
  }

  function TokenChangeManagement() {
    if (token) {
      console.log('App.js -> Token qui apparait dans le store : on lance les fetch')
      FetchWithToken()
      let fetchIntervalID = setInterval(() => IntervalFetchWithToken(), 60000)
      setFetchIntervalID(fetchIntervalID)
    } else {
      console.log('App.js -> Token qui disparait du store : on supprime toutes les données du profil')
      localStorage.removeItem('NAUTICTALK_TOKEN')
      dispatch({ type : "RESET_SUBSCRIPTIONS_ARRAY"})
      dispatch({ type : "RESET_CHAT_HEADERS_ARRAY"})
      dispatch({ type : "RESET_NEWS_FEED_CONTENT"})
      dispatch({ type : "RESET_NOTIFICATION_ARRAY"})
      dispatch({ type : "RESET_MY_PROFILE"})
      if (fetchIntervalID) {
        clearInterval(fetchIntervalID);
        setFetchIntervalID(0);
      }
      FetchWithoutToken()
    }
  }

  function CalculateFetchProgress(props) {
    return(
      (props.fetchNumber - (props.fetchGetUserProfile ? 1:0) - (props.fetchGetUserProfile ? 1:0) - (props.fetchGetUserProfile ? 1:0) - (props.fetchGetUserProfile ? 1:0) - (props.fetchGetUserProfile ? 1:0) - (props.fetchGetUserProfile ? 1:0) - (props.fetchGetUserProfile ? 1:0))
    )
  }

  function FetchWithToken() {   // On lance les fetch simultanément, et chacun lance une fonction selon son return (OK ou Erreur) pour mettre à jour le state local, et charge les données dans le state global Redux
    console.log('App.js -> Lancement de FetchWithToken')
    setFetchState(prevState => ({...prevState,
      fetchInitiated:true,
      fetchGetUserProfile:true,
      fetchMarkersArray:true,
      fetchUserPositionsArray:true,
      fetchSubscriptionsArray:true,
      fetchChatHeadersArray:true,
      fetchNewsFeedContent:true,
      fetchNotificationArray:true,
      fetchError:false,
      fetchProgress : 0,
      fetchNumber : 8
    }))
    GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement userProfile dans le state Redux')
        dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
        setFetchState(prevState => ({...prevState,
          fetchGetUserProfile:false,
          fetchProgress : CalculateFetchProgress({...prevState,
            fetchGetUserProfile:false})
        }))
        } else {
        console.log('App.js -> Erreur dans le fetch userProfile')
        setFetchState(prevState => ({...prevState,
          fetchError:true,
        }))
      }
    })
    GetFunction({fetchTarget : 'markersArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement markersArray dans le state Redux')
        dispatch({ type : "LOAD_MARKERS_ARRAY", payload:response.data})
        setFetchState(prevState => ({...prevState,
          fetchMarkersArray:false,
          fetchProgress : CalculateFetchProgress({...prevState,
            fetchMarkersArray:false})
        }))
        } else {
        console.log('App.js -> Erreur dans le fetch markersArray')
        setFetchState(prevState => ({...prevState,
          fetchError:true,
        }))
      }
    })
    GetFunction({fetchTarget : 'userPositionsArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement userPositionsArray dans le state Redux')
        dispatch({ type : "LOAD_USERPOSITIONS_ARRAY", payload:response.data})
        setFetchState(prevState => ({...prevState,
          fetchUserPositionsArray:false,
          fetchProgress : CalculateFetchProgress({...prevState,
            fetchUserPositionsArray:false})
        }))
        } else {
        console.log('App.js -> Erreur dans le fetch userPositionsArray')
        setFetchState(prevState => ({...prevState,
          fetchError:true,
        }))
      }
    })
    GetFunction({fetchTarget : 'subscriptionsArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement subscriptionsArray dans le state Redux')
        dispatch({ type : "LOAD_SUBSCRIPTIONS_ARRAY", payload:response.data})
        setFetchState(prevState => ({...prevState,
          fetchSubscriptionsArray:false,
          fetchProgress : CalculateFetchProgress({...prevState,
            fetchSubscriptionsArray:false})
        }))
        } else {
        console.log('App.js -> Erreur dans le fetch subscriptionsArray')
        setFetchState(prevState => ({...prevState,
          fetchError:true,
        }))
      }
    })
    GetFunction({fetchTarget : 'reportMarksArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement reportMarksArray dans le state Redux')
        dispatch({ type : "LOAD_REPORTMARKS_ARRAY", payload:response.data})
        setFetchState(prevState => ({...prevState,
          fetchReportMarksArray:false,
          fetchProgress : CalculateFetchProgress({...prevState,
            fetchReportMarksArray:false})
        }))
        } else {
        console.log('App.js -> Erreur dans le fetch reportMarksArray')
        setFetchState(prevState => ({...prevState,
          fetchError:true,
        }))
      }
    })
    GetFunction({fetchTarget : 'chatHeadersArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement chatHeadersArray dans le state Redux')
        dispatch({ type : "LOAD_CHAT_HEADERS_ARRAY", payload:response.data})
        setFetchState(prevState => ({...prevState,
          fetchChatHeadersArray:false,
          fetchProgress : CalculateFetchProgress({...prevState,
            fetchChatHeadersArray:false})
        }))
        } else {
        console.log('App.js -> Erreur dans le fetch chatHeadersArray')
        setFetchState(prevState => ({...prevState,
          fetchError:true,
        }))
      }
    })
    GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement newsFeedContent dans le state Redux')
        dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
        setFetchState(prevState => ({...prevState,
          fetchNewsFeedContent:false,
          fetchProgress : CalculateFetchProgress({...prevState,
            fetchNewsFeedContent:false})
        }))
        } else {
        console.log('App.js -> Erreur dans le fetch newsFeedContent')
        setFetchState(prevState => ({...prevState,
          fetchError:true,
        }))
      }
    })
    GetFunction({fetchTarget : 'notificationArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement notificationArray dans le state Redux')
        dispatch({ type : "LOAD_NOTIFICATION_ARRAY", payload:response.data})
        setFetchState(prevState => ({...prevState,
          fetchNotificationArray:false,
          fetchProgress : CalculateFetchProgress({...prevState,
            fetchNotificationArray:false})
        }))
        } else {
        console.log('App.js -> Erreur dans le fetch notificationArray')
        setFetchState(prevState => ({...prevState,
          fetchError:true,
        }))
        }
    })
  }

  function IntervalFetchWithToken() {
    console.log('App.js -> IntervalFetchWithToken')
    GetFunction({fetchTarget : 'userPositionsArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement userPositionsArray dans le state Redux')
        dispatch({ type : "LOAD_USERPOSITIONS_ARRAY", payload:response.data})
      } else {
        console.log('App.js -> Erreur dans le fetch userPositionsArray')
      }
    })
    GetFunction({fetchTarget : 'reportMarksArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement reportMarksArray dans le state Redux')
        dispatch({ type : "LOAD_REPORTMARKS_ARRAY", payload:response.data})
      } else {
        console.log('App.js -> Erreur dans le fetch reportMarksArray')
      }
    })
    GetFunction({fetchTarget : 'chatHeadersArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement chatHeadersArray dans le state Redux')
        dispatch({ type : "LOAD_CHAT_HEADERS_ARRAY", payload:response.data})
      } else {
        console.log('App.js -> Erreur dans le fetch chatHeadersArray')
      }
    })
    GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement newsFeedContent dans le state Redux')
        dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
      } else {
        console.log('App.js -> Erreur dans le fetch newsFeedContent')
      }
    })
    GetFunction({fetchTarget : 'notificationArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement notificationArray dans le state Redux')
        dispatch({ type : "LOAD_NOTIFICATION_ARRAY", payload:response.data})
      } else {
        console.log('App.js -> Erreur dans le fetch notificationArray')
        }
    })
  }

  function FetchWithoutToken() {   // On lance les fetch simultanément, et chacun lance une fonction selon son return (OK ou Erreur) pour mettre à jour le state local, et charge les données dans le state global Redux
    console.log('App.js -> Lancement de FetchWithoutToken')
    setFetchState(prevState => ({...prevState,
      fetchInitiated:true,
      fetchGetUserProfile:false,
      fetchMarkersArray:true,
      fetchUserPositionsArray:true,
      fetchReportMarksArray:true,
      fetchSubscriptionsArray:false,
      fetchChatHeadersArray:false,
      fetchNewsFeedContent:false,
      fetchNotificationArray:false,
      fetchError:false,
      fetchProgress : 0,
      fetchNumber : 2
    }))
    GetFunction({fetchTarget : 'markersArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement markersArray dans le state Redux')
        dispatch({ type : "LOAD_MARKERS_ARRAY", payload:response.data})
        setFetchState(prevState => ({...prevState,
          fetchMarkersArray:false,
          fetchProgress : CalculateFetchProgress({...prevState,
            fetchMarkersArray:false})
        }))
        } else {
        console.log('App.js -> Erreur dans le fetch markersArray')
        setFetchState(prevState => ({...prevState,
          fetchError:true,
        }))
      }
    })
    GetFunction({fetchTarget : 'userPositionsArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement userPositionsArray dans le state Redux')
        dispatch({ type : "LOAD_USERPOSITIONS_ARRAY", payload:response.data})
        setFetchState(prevState => ({...prevState,
          fetchUserPositionsArray:false,
          fetchProgress : CalculateFetchProgress({...prevState,
            fetchUserPositionsArray:false})
        }))
        } else {
        console.log('App.js -> Erreur dans le fetch userPositionsArray')
        setFetchState(prevState => ({...prevState,
          fetchError:true,
        }))
      }
    })
    GetFunction({fetchTarget : 'reportMarksArray',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('App.js -> Chargement reportMarksArray dans le state Redux')
        dispatch({ type : "LOAD_REPORTMARKS_ARRAY", payload:response.data})
        setFetchState(prevState => ({...prevState,
          fetchReportMarksArray:false,
          fetchProgress : CalculateFetchProgress({...prevState,
            fetchReportMarksArray:false})
        }))
        } else {
        console.log('App.js -> Erreur dans le fetch reportMarksArray')
        setFetchState(prevState => ({...prevState,
          fetchError:true,
        }))
      }
    })
  }

  //
  // ENVOI PERIODIQUE DE LA POSITION
  //

  useEffect(() => {
    if (token) {
      if (myProfile.userPositionShare) {
        console.log('App.js -> Partage position demandé : lancement du partage de position et calcul première position')
        SendPosition()
        let newSendPositionIntervalID = setInterval(() => SendPosition(), 60000)
        setSendPositionIntervalID(newSendPositionIntervalID)
      } else {
        console.log('App.js -> Partage position refusé : arrêt du partage de position')
        if (sendPositionIntervalID) {
          clearInterval(sendPositionIntervalID);
          setSendPositionIntervalID(null);
        }
      }
    } else {
      console.log('App.js -> Logout : arrêt du partage de position')
      if (sendPositionIntervalID) {
        clearInterval(sendPositionIntervalID);
        setSendPositionIntervalID(null);
      }
    }
  }, [myProfile.userPositionShare])  // Hook exécuté quand  myProfile est modifié
 

  function DesactivatePositionSharing() {
    console.log("App.js -> Suppression de l'option de partage de position")
    console.log('App.js -> Chargement API sendMyProfile')
    const newProfileFormData = new FormData();
    newProfileFormData.append("userPositionShare", false)
    PatchFunction({fetchTarget:'sendMyProfileForm', fetchObjectId:myProfile.pk, fetchArgument: newProfileFormData, token:token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('EditProfile.js -> Fin chargement API sendMyProfile')
        console.log('EditProfile.js -> Fetch du nouveau myProfile')
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('EditProfile.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('EditProfile.js -> Réception du profil à jour en échec')
          }
        })
      } else {
        console.log('EditProfile.js -> Envoi sendMyProfile en échec')
      }
    })
  }

  function SendPosition() {
    let errorMessage
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log('App.js -> Nouvelle position acquise')
        const newPositionData = {
          userPositionUser : myProfile.pk,
          userPositionLongitude : position.coords.longitude,
          userPositionLatitude : position.coords.latitude,
          userPositionTimestamp : "2000-01-01T00:00:00"
        }
        console.log('App.js -> Envoi nouvelle position')
        PostFunction({fetchTarget:'userPosition', fetchObjectId:myProfile.pk, fetchArgument: newPositionData, token:token})
        .then(response => {
          if(response.fetchStatus === 'Ok') {
            console.log('App.js -> Fin chargement API userPosition')
          } else {
            console.log('App.js -> Chargement userPosition en échec')
          }
        })
      }, (error) => {
        // Géolocalisation impossible ou désactivée
        console.log("App.js -> Géolocalisation impossible ou désactivée")
        if (error === 1) {
          // Blocage navigateur : on coupe la fonction
          errorMessage = "Impossible d'afficher votre position. Votre navigateur bloque l'accès à votre position. Autorisez votre navigateur à partager votre position."
          dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          DesactivatePositionSharing()
        } else if (error === 2) {
          // GPS inaccessible : on ne fait rien
        } else {
          // Erreur inconnue : on coupe la fonction
          errorMessage = "Impossible d'afficher votre position."
          dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          DesactivatePositionSharing()
        }
      })
    } else {
      // Browser incompatible : on coupe la fonction
      console.log("App.js -> Navigateur ne permettant pas d'acquérir la position")
      errorMessage = "Impossible d'afficher votre position. La version de votre navigateur ne permet pas la géolocalisation."
      dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      DesactivatePositionSharing()
    }
  }
  
  //
  // CONTENU DE L'AFFICHAGE
  //

  function handleErrorMessageClose() {
    dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:false})
  };

  function ErrorMessageScreen(){
    return(
      <Dialog
        open={showErrorMessageScreen !== false}
        onClose={handleErrorMessageClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="error">
          {"Attention !"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="primary">
            {showErrorMessageScreen}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorMessageClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function GlobalContent() {
    if (fetchState.fetchError) {
      // Si erreur d'un des fetch : on arrête tout
      return(
        <Dialog
          open={true}
        >
          <DialogTitle id="alert-dialog-title" color="error">
            {"Problème de réseau"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" color="primary">
              Vos données n'ont pas pu être chargées.
              Vérifiez votre connexion au réseau et rechargez la page.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )
    } else if (fetchState.fetchProgress < fetchState.fetchNumber) {
      // Si un des fetch est en cours : on affiche l'indicateur de progression 
      return(
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              marginBottom: 8,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography sx={{marginBottom : 5}} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
              Nous chargeons vos données...
            </Typography>
            <LinearProgress color="success" variant="determinate" value={100*fetchState.fetchProgress/fetchState.fetchNumber} />
            <Typography sx={{marginTop : 5}} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
              {100*fetchState.fetchProgress/fetchState.fetchNumber} %
            </Typography>
          </Box>
        </Container>
      )
    } else if (fetchState.fetchInitiated) {
      return(
      <Container>
        <RouterProvider router={router} />
        {ErrorMessageScreen()}
      </Container>
      )
    }
  }

  //
  // RETURN
  //
  return (GlobalContent())
}