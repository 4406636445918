import { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';
import { useDispatch  } from 'react-redux'
import { useTheme } from "@emotion/react";
import { keyframes } from '@emotion/react'

import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import Paper from '@mui/material/Paper';
import ForumIcon from '@mui/icons-material/Forum';

import Zoom from '@mui/material/Zoom';


export default function Tutorial6(props) {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()

  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('NotificationList.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "DISPLAY_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('NotificationList.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])

  //
  // Animations standard
  //

  let tutorialButtonEffect = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }`

    //
  // DRAGGABLE VISIBLESPOTSFEED
  //

  const visibleSpotsFeedBoxProps = {
    openNormalHeight : window.innerHeight/2,
    closedNormalHeight : 50,
    minimumHeight : 10
  }

  // States utilisés pour le drag
  const [visibleSpotsFeedBoxState, setVisibleSpotsFeedBoxState] = useState({
    startPoint : null,
    boxHeight : visibleSpotsFeedBoxProps.closedNormalHeight,
    startBoxHeight : visibleSpotsFeedBoxProps.closedNormalHeight,
    heightTransition : "0.3s ease-out",
    isDragging : false
  })

  // Références utilisées pour passer la valeur des states aux listeners (qui font une closure des variables donc ne voient pas les mises à jour des variables et des states)
  const visibleSpotsFeedStartPointRef = useRef();
  const visibleSpotsFeedBoxHeightRef = useRef();
  const visibleSpotsFeedStartBoxHeightRef = useRef();

  // Hooks qui mettent automatiquement à jour les refs quand les valeurs du state changent
  useEffect(() => {
    visibleSpotsFeedStartPointRef.current = visibleSpotsFeedBoxState.startPoint;
  }, [visibleSpotsFeedBoxState.startPoint]);

  useEffect(() => {
    visibleSpotsFeedStartBoxHeightRef.current = visibleSpotsFeedBoxState.startBoxHeight;
  }, [visibleSpotsFeedBoxState.startBoxHeight]);

  useEffect(() => {
    visibleSpotsFeedBoxHeightRef.current = visibleSpotsFeedBoxState.boxHeight;
  }, [visibleSpotsFeedBoxState.boxHeight]);


  // Fonction pour gérer le pullStart
  const visibleSpotsFeedPullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setVisibleSpotsFeedBoxState(prevState => ({
      ...prevState,
      startPoint : screenY,
      startBoxHeight : visibleSpotsFeedBoxHeightRef.current,
      heightTransition : "0.01s linear",
      isDragging : true
    }))
  }

  // Fonction pour gérer le pullMove
  const visibleSpotsFeedPullMove = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = visibleSpotsFeedStartPointRef.current - screenY;

    setVisibleSpotsFeedBoxState(prevState => ({
      ...prevState,
      boxHeight : Math.min(visibleSpotsFeedBoxProps.openNormalHeight + 0.3*Math.max(0,(visibleSpotsFeedStartBoxHeightRef.current+pullLength)-visibleSpotsFeedBoxProps.openNormalHeight), Math.max(visibleSpotsFeedBoxProps.minimumHeight,visibleSpotsFeedStartBoxHeightRef.current + pullLength)),
    }))
  };
  
  // Fonction pour gérer le pullEnd
  const visibleSpotsFeedPullEnd = (e) => {
    navigate('/Tutorial7', {replace : true})
 };


 // Mise en place et suppression des event listeners pour gérer le pull-to-refresh

  const visibleSpotsRef = useRef(null); // Pour gérer le drag

  useEffect(() => {
    if (visibleSpotsRef.current) {
      // On supprime d'éventuels listeners avant d'en rajouter
      // (l'utilisation du Callback pour faire la suppression ne fonctionne pas quand on change d'écrans)
      visibleSpotsRef.current.removeEventListener("touchstart", visibleSpotsFeedPullStart, {passive : true});
      visibleSpotsRef.current.removeEventListener("touchmove", visibleSpotsFeedPullMove, {passive : true}); // Il faut mettre passive à false pour permettre le prevent Default
      visibleSpotsRef.current.removeEventListener("touchend", visibleSpotsFeedPullEnd, {passive : true});

      visibleSpotsRef.current.addEventListener("touchstart", visibleSpotsFeedPullStart, {passive : true});
      visibleSpotsRef.current.addEventListener("touchmove", visibleSpotsFeedPullMove, {passive : true});
      visibleSpotsRef.current.addEventListener("touchend", visibleSpotsFeedPullEnd, {passive : true});
    }
  },[visibleSpotsRef.current]);

  function PreviousNextButtons() {
    return (
      <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", position : "fixed", top : 0, right : 1, left : 0, backgroundColor : "white", padding : 1, zIndex : theme.zIndex.modal-1}}>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/Tutorial5", {replace : true})
          }}
          color="primary"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <SkipPreviousIcon/>
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/MapPage", {replace : true})
          }}
          color="error"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <HighlightOffIcon />
        </Fab>
        <Fab
          size="small"
          onClick={() => {
            navigate ("/Tutorial7", {replace : true})
          }}
          color="primary"
          sx={{
            ml: 2, mr: 2,
            zIndex : theme.zIndex.modal
          }}
        >
          <SkipNextIcon/>
        </Fab>
      </Box>
    );
  }


  return (
    <Fragment>
      {/* COUCHE OPAQUE D'INTERCEPTION DES CLICS */}
      <Box sx={{
        display : "flex",
        position : "fixed",
        top : 0,
        bottom : 0,
        right : 0,
        left : 0,
        backgroundColor : "black",
        opacity : 0.4,
        zIndex : theme.zIndex.modal-1, // On se met juste au dessous du niveau des modal (Alert etc...)
      }}/>

      {PreviousNextButtons()}

      {/* BULLES DE TEXTE DU TUTO - FLECHE EN BAS*/}
      <Zoom in={true}>

      <Box 
        sx={{
          position: 'fixed',
          bottom : "20%",
          left : "10%",
          right : "10%",
          display: 'inline-block',
          padding: '16px',
          backgroundColor: 'white',
          borderRadius: '15px',
          boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.7)',
          zIndex : theme.zIndex.modal,
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-10px', // Placement du triangle en bas de la bulle
            left: '50%', // Ajuster pour le décaler par rapport à la bulle
            width: '0',
            height: '0',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: '10px solid white',
          },
        }}
      >
        <Typography variant = "h6" color={"primary"} paragraph={true}>
          En bas de l'écran, c'est l'explorateur.
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Il te montre toutes les conversations des spots visibles sur ta carte.
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Pratique : l'explorateur se met à jour dès que tu déplace la carte ou quand tu changes le zoom.
        </Typography>
      </Box>
    </Zoom>

    {/* BOTTOM CENTER FABS */}
    <Box sx={{
      position : "absolute",
      display : "flex",
      flexDirection : "column",
      justifyContent: "center",
      bottom : 10,
      left : "50%",
      transform : "translateX(-50%)",
      zIndex : theme.zIndex.modal
    }}>
      {/* DisplayVisibleSpotsFeedFAB */}
        <Fab color="secondary"
          sx={{
            height : 80, width : 80,
            animation: `${tutorialButtonEffect} 0.5s ease infinite`,
          }}
          onClick={() => {navigate ("/Tutorial7", {replace : true})}}
                    >
          <ForumIcon sx={{color : "white", mb : 5}}/>
        </Fab>
    </Box>

    {/* VISIBLE SPOTS FEED */}
    <Paper
      elevation = {24}
      sx={{
        display : "flex", 
        flexDirection : "column", 
        position:"fixed", 
        justifyItems : "center",
        bottom: -50, 
        left : { xs:"0%", sm:"10%"}, 
        right : { xs:"0%", sm:"10%"}, 
        borderTopLeftRadius : 10, 
        borderTopRightRadius : 10,
        height : visibleSpotsFeedBoxState.boxHeight + 50,
        marginLeft : 1,
        marginRight : 1,
        backgroundColor : "white",
        animation: `${tutorialButtonEffect} 0.5s ease infinite`,
        zIndex : theme.zIndex.modal
      }}
      onClick={() => {navigate ("/Tutorial7", {replace : true})}}
      ref={visibleSpotsRef}
    >
      <Box 
        sx={{display : "flex", flexDirection : "column", alignItems : "center", marginBottom : 2}}
      >
        <Typography variant="body2"  fontWeight = "bold" color="#f3722C">
          {"Discussions dans cette zone"}
        </Typography>
      </Box>
    </Paper>



    </Fragment>
  );
}