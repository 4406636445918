// Définition des statuts et des points de transition pour calcul des upgrades
// A mettre à jour également dans le serializer Django

export function StatusNames() {
    return(
        [
            "Matelot novice",
            "Louvoyeur habile",
            "Navigateur éclairé",
            "Capitaine des tempêtes",
            "Loup de mer légendaire"
        ]
    )
}

export function StatusUpgradePoints() {
    return(
        [
            "0",
            "1000",
            "3000",
            "7000",
            "15000"
        ]
    )
}